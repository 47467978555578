import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';
import { get } from '@ember/object';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import Session from 'mewe/shared/session';
import { Theme } from 'mewe/constants';
import { isAndroid } from 'mewe/utils/mobile-utils';
import { iosAppUrl, androidAppUrl } from 'mewe/constants';
import { isMobile } from 'mewe/shared/utils';

export default class MwPublicJoin extends Component {
  @service router;

  @tracked isLoggedIn; // keep undefined as default
  @tracked promisesToWaitFor = [];

  isMobile = isMobile();

  constructor() {
    super(...arguments);

    const imagePromise = new Promise((resolve) => (this.imageResolve = resolve));
    this.promisesToWaitFor.push(imagePromise);

    Session.isAuthenticated().then(({ isAuthenticated }) => {
      // redirection to community is postponed until community is loaded
      // because based on the community type and other data there are different redirections
      this.isLoggedIn = isAuthenticated;

      if (this.isCommunityLoaded) {
        this.afterCommunityLoaded();
      } else {
        // observer set directly on `isCommunityLoaded` doesn't work and I couldn't figure it out why
        if (this.args.theme === Theme.GROUP) {
          addObserver(this, 'args.model.group.isFetching', this.afterCommunityLoaded);
        } else {
          addObserver(this, 'args.model.eventState.isFetching', this.afterCommunityLoaded);
        }
        this.observerSet = true;
      }
    });

    // this page is not part of the app and it's for logged out users,
    // there are some problems with applying dark theme and that's quick hack
    document.body.style.backgroundColor = '#131313';
    document.body.style.transition = 'background-color 0.1s ease-in-out';
  }

  willDestroy() {
    document.body.style.backgroundColor = '#131313';

    if (this.observerSet) {
      if (this.args.theme === Theme.GROUP) {
        removeObserver(this, 'args.model.group.isFetching', this.afterCommunityLoaded);
      } else {
        removeObserver(this, 'args.model.eventState.isFetching', this.afterCommunityLoaded);
      }
    }
  }

  get downloadAppLink() {
    if (isAndroid()) {
      return androidAppUrl;
    }
    return iosAppUrl;
  }

  get community() {
    switch (this.args.theme) {
      case Theme.GROUP:
        return get(this, 'args.model.group') || {};
      case Theme.EVENT:
        return get(this, 'args.model.eventState.eventData') || {};
    }
  }

  get isCommunityLoaded() {
    switch (this.args.theme) {
      case Theme.GROUP:
        return get(this, 'args.model.group.isFetching') === false;
      case Theme.EVENT:
        return get(this, 'args.model.eventState.isFetching') === false;
    }
  }

  get avatarUrl() {
    let avatarUrl = '';

    switch (this.args.theme) {
      case Theme.GROUP:
        avatarUrl = this.community.avatarLink || '';
        break;

      case Theme.EVENT:
        const eventLinks = this.community._links;

        if (eventLinks) {
          avatarUrl =
            EnvironmentUtils.getImgHost(true) +
            (eventLinks?.publicImageLinkTemplate?.href || // default field with image url
              eventLinks?.imageLinkTemplate?.href || // fallback to some default image in case the original one was deleted
              '');
        }
    }

    return avatarUrl.replace('{imageSize}', '800x800');
  }

  // content is revealed when image is loaded but no sooner than after 1s
  revealContent() {
    // preload background photo to make rendering smoother
    if (this.avatarUrl) {
      let img = new Image();
      img.src = this.avatarUrl;
      img.onload = () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.imageResolve();
      };
    } else {
      this.imageResolve();
    }
  }

  afterCommunityLoaded() {
    // logged in user is redirected to community, it's posponed until community
    // is loaded in order to get the community ID for redirection (public link is not enough)
    if (this.isLoggedIn) {
      return this.goToCommunity();
    }

    this.setPageMeta();
    this.revealContent();
  }

  setPageMeta() {
    // setting page meta when data fetched from server - for link scrapping machines
    let titleText;

    if (this.args.theme === Theme.EVENT) {
      if (this.community.name) {
        titleText = __('You are invited to join {eventName}', {
          eventName: this.community.name,
        });
      }
    }

    if (this.args.theme === Theme.GROUP) {
      if (this.community.name) {
        if (this.community.isPublicApply) {
          titleText = __('Ask to join {groupName}', {
            groupName: this.community.name,
          });
        } else {
          titleText = __('You are invited to join {groupName}', {
            groupName: this.community.name,
          });
        }
      }
    }

    if (titleText) {
      titleText += ' | MeWe';
      document.title = titleText;
    }
  }

  get canViewGroupContent() {
    // group member or invited - user can open group and preview its content before joining
    // other - can't open group so group preview modal is opened instead where user can join or apply to group
    return this.args.theme === Theme.GROUP && this.community.isMemberOrInvited;
  }

  getQueryParams() {
    switch (this.args.theme) {
      case Theme.GROUP:
        const gpId = this.canViewGroupContent ? `id-${this.community.id}` : this.args.model.groupPublicId;
        return {
          gpId: gpId,
          inId: this.args.model.invitationId, // comes from email invitation flow
          email: this.args.model.email, // comes from email invitation flow, to be prefilled in registration form
        };

      case Theme.EVENT:
        return {
          epId: `id-${this.community.id}`,
        };
    }
  }

  @action
  goToCommunity() {
    // when redirecting to app we need to set window.location in order to load page from scratch
    // when redirecting to pages outiside of app we use router.transitionTo
    switch (this.args.theme) {
      case Theme.EVENT:
        window.location.href = `/event/${this.community.id}`;
        break;

      case Theme.GROUP:
        if (this.canViewGroupContent) {
          window.location.href = `/group/${this.community.id}`;
        } else {
          window.location.href = `/myworld?previewGroupId=${this.community.id}`;
        }
        break;
    }
  }

  @action
  goToSignup(withQueryParams) {
    this.router.transitionTo('register', { queryParams: withQueryParams ? this.getQueryParams() : {} });
  }

  @action
  goToLogin(withQueryParams) {
    this.router.transitionTo('login', { queryParams: withQueryParams ? this.getQueryParams() : {} });
  }
}

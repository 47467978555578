import { A } from '@ember/array';
import { each } from 'lodash';
import Component from '@glimmer/component';
import { getOwner } from '@ember/application';
import { later, next, cancel } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import EmberObject, { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { formatDateTime, timeago } from 'mewe/utils/datetime-utils';

const TimeAgoRefresher = EmberObject.extend({
  account: service(),

  init() {
    this.set('timeAgos', A());
  },

  addTimeAgo(timeAgo) {
    this.get('timeAgos').pushObject(timeAgo);
    if (!this.get('scheduleId')) this.startRefreshing();
  },

  removeTimeAgo(timeAgo) {
    var timeAgos = this.get('timeAgos');
    timeAgos.removeObject(timeAgo);
    if (timeAgos.length === 0) this.stopRefreshing();
  },

  startRefreshing() {
    const self = this;
    this.set(
      'scheduleId',
      later(
        this,
        function () {
          if (this.isDestroying || this.isDestroyed) return;
          each(self.get('timeAgos'), (timeAgo) => timeAgo.refreshTimeFromNow());
          self.startRefreshing();
        },
        60000
      )
    );
  },

  stopRefreshing() {
    var id = this.get('scheduleId');
    if (id) {
      cancel(id);
      this.set('scheduleId', null);
    }
  },
}).create();

export default class MwTimeago extends Component {
  @tracked time;

  constructor() {
    super(...arguments);

    this.globals = getOwner(this).lookup('globals:main');

    addObserver(this, 'createdAt', this, this.refreshTimeFromNow);

    this.refreshTimeFromNow();

    next(() => {
      TimeAgoRefresher.addTimeAgo(this);
    });
  }

  willDestroy() {
    removeObserver(this, 'createdAt', this, this.refreshTimeFromNow);
    TimeAgoRefresher.removeTimeAgo(this);
  }

  refreshTimeFromNow() {
    this.time = timeago(
      this.createdAtD.getTime(),
      Date.now(),
      this.account?.activeUser?.timezone,
      this.account?.activeUser?.locale,
      this.future
    );
  }

  @computed('args.{date,createdAt}')
  get createdAt() {
    return this.args.date ? this.args.date : this.args.createdAt;
  }

  // backend can return timestamp in milis or in sec, so we check the length of timestamp and choose correct param
  createDate(str) {
    let num = parseInt(str, 10);

    if (num && num < 9999999999) num = num * 1000;

    return new Date(num);
  }

  @computed('createdAt')
  get createdAtD() {
    return this.createDate(this.createdAt);
  }

  @computed('createdAtD')
  get datetime() {
    return this.createdAtD.toISOString();
  }

  @computed('createdAtD', 'account.activeUser.timezone')
  get fullTimePopup() {
    return formatDateTime(
      this.createdAtD.getTime(),
      this.account?.activeUser?.timezone,
      this.account?.activeUser?.locale
    );
  }

  @computed('args.editedAt', 'account.activeUser.timezone')
  get editedFullTimePopup() {
    return formatDateTime(
      this.createDate(this.args.editedAt).getTime(),
      this.account?.activeUser?.timezone,
      this.account?.activeUser?.locale
    );
  }
}

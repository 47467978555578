import Quill from 'quill/core';
import { getHashMap, getEmojiUrlCdn } from 'mewe/utils/emoji-utils';
import { escape } from 'lodash';
import Verbose from 'mewe/utils/verbose';

const log = Verbose({ prefix: '[text-editor-quill-utils]', color: 'red', enabled: false }).log;

const Delta = Quill.import('delta');

const getPosition = (el) => {
  let pos = el.getBoundingClientRect();
  let top = pos.top + document.documentElement.scrollTop;
  return { top, left: pos.left };
};

export const getQuillBoundsPosition = (quill, index) => {
  let pos = quill.getBounds(index);

  let quillPos = getPosition(quill.container);

  pos.top += quillPos.top - 9;
  pos.left += quillPos.left;

  return pos;
};

export const getDelta = ({ value, term, quill, atIndex, alreadyTyped }) => {
  const termLength = term ? term.length : 1;

  if (alreadyTyped) alreadyTyped--;

  const delta = new Delta().retain(atIndex - alreadyTyped - termLength).delete(termLength + alreadyTyped);

  atIndex = atIndex - termLength;

  return { delta, value, quill, atIndex };
};

export const insertEmoji = ({ delta, value, quill, atIndex }) => {
  const emoji = getHashMap().shortnameDisplay[value];

  let path = getEmojiUrlCdn() + emoji.png.default;

  delta.insert({ emoji: true }, { src: path, 'data-name': emoji.shortname.replace(/:/g, '') });

  let newIndex = atIndex + 2;

  insert(delta, newIndex, quill);
};

export const insertHashTag = ({ delta, value, quill, atIndex }) => {
  const { name } = value;

  delta.insert({ hashtag: { text: '#' + name } });

  let newIndex = atIndex + name.length + 2;
  insert(delta, newIndex, quill);
};

export const insertMention = ({ delta, value, quill, atIndex }) => {
  log('insertMention', { delta, value, quill, atIndex });

  const { user, everyone } = value;
  const userName = everyone ? __('everyone') : escape(user.name);

  delta.insert({ mention: { text: '@' + userName, id: user.id } });

  let newIndex = atIndex + userName.length + 2;

  insert(delta, newIndex, quill);
};

/* 
    Adding more explanation as I end up frequently debugging this function (MW)
    Empty space needs to be inserted after text, and it needs to be done as separate "insertText" 
    because in case of inserting mention or hashtag space will end this block and prevent delta delete 
    from removing correct characters
    eg.
    [
      {
        "insert": {
          "mention": {
            "text": "@Maciej Wasilewski",
            "id": "52c569d1e4b0681047e1a8d4"
          }
        }
      },
      {
        "insert": {
          "text": " "
        }
      }
      {
          "delete": 1
      }
    ]
    here delete is meant to remove already typed characters eg. "@", but space makes mention block to end, and delete 1 happens after that block,
    resulting in @ left untouched - connected to SG-30222
  */
export const insert = (delta, newIndex, quill) => {
  log('insert:', delta, newIndex);

  quill.updateContents(delta, Quill.sources.API);

  quill.setSelection(newIndex, 0, Quill.sources.API);

  quill.insertText(quill.getSelection()?.index, ' ');

  quill.focus();
};

export const insertEmojiFromPicker = (emoji, quill) => {
  const path = getEmojiUrlCdn() + emoji.png.default;
  quill.focus();
  const atIndex = quill.getSelection().index;
  const delta = new Delta()
    .retain(atIndex)
    .insert({ emoji: true }, { src: path, 'data-name': emoji.shortname.replace(/:/g, '') });

  let newIndex = atIndex + 2;

  insert(delta, newIndex, quill);
};

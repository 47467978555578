import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import EventUtils from 'mewe/utils/event-utils';
import {
  isDifferentDay,
  daysDiff,
  utcOffsetMillis,
  shiftToUtc,
  utcMidnightMillis,
  utcTimeOfDayMillis,
  todayTomorrowOrDate,
} from 'mewe/utils/datetime-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import GroupStore from 'mewe/stores/group-store';
import toDisplay from 'mewe/stores/text-parsers/to-display';
import { parsersDefaultNoHashtags } from 'mewe/stores/text-parsers/to-display';
import { modelOwner } from 'mewe/stores/models/mixins/model-owner';

let model = Model.extend(modelOwner, {
  _links: attr('object'),
  id: attr('string'),
  name: attr('string'),
  description: attr('string', { defaultValue: '' }),
  location: attr('string'),
  createdAt: attr('number'),
  startDate: attr('number'),
  nextOccurrenceDate: attr('number'),
  endDate: attr('number'),
  imageId: attr('string'),
  eventType: attr('string'), // 'open' / 'private' / empty for group events
  groupId: attr('string'),
  allDay: attr('boolean'),
  chatMode: attr('string'),
  moderated: attr('boolean'), // posts in event needs approval
  createPost: attr('boolean'), // post about event was created
  inviteAllGroupMembers: attr('boolean'), // send notification to all members
  everyoneCanInvite: attr('boolean'),
  participationType: attr('string'), // 'Owner' / 'Invited'(not rsvp yet) / 'Attending' / 'NotAttending' / 'Interested' / 'Undecided' / undefined (group events without sending invitations)
  unreadPostsCount: attr('number'),
  publicUrl: attr('string'),
  userId: attr('string'), // userId/ownerId - depends on context both cases can happend
  ownerId: attr('string'),
  recurrence: attr('object'),
  reminder: attr('string', { defaultValue: 'none' }),

  init() {
    this.set('currentUser', this.get('currentUser') || CurrentUserStore.getState());
    this.set('groupsState', GroupStore.getState()); // needs to be observed when groups will be loaded to set group permissions for group event, otherwise race condition can happen
    this._super();
  },

  // this comes from BE if event has set some timezone
  timeZone: attr('string'),

  timezone: computed('currentUser.timezone', function () {
    return this.get('currentUser.timezone');
  }),

  canPost: computed('groupId', 'group.canPost', function () {
    const groupId = this.groupId;
    return !groupId || (groupId && this.get('group.canPost'));
  }),

  canInvite: computed('participationType', 'groupId', 'everyoneCanInvite', function () {
    return this.get('participationType') === 'Owner' || this.get('groupId.length') || this.get('everyoneCanInvite');
  }),

  isOwner: computed('participationType', function () {
    return this.get('participationType') === 'Owner';
  }),

  locationDisplay: computed('location', function () {
    return toDisplay(this.get('location'), parsersDefaultNoHashtags, { noStickers: true });
  }),

  descriptionDisplay: computed('description', function () {
    return toDisplay(this.description, parsersDefaultNoHashtags, { noStickers: true });
  }),

  group: computed('groupId', 'groupsState.confirmedGroups.length', function () {
    const groupId = this.groupId;
    return this.get('groupsState.confirmedGroups').find((group) => {
      return group.id === groupId;
    });
  }),

  // /api/v2/event2/participation sends the FIRST recurrence time as startDate, not the next!
  startDateDisplay: computed('startDate', 'timezone', 'timeZone', 'nextOccurrenceDate', function () {
    if (this.nextOccurrenceDate && this.nextOccurrenceDate !== this.startDate) {
      return this.shiftToFirstOccurrenceUtcOffset(this.startDate, this.nextOccurrenceDate);
    } else return this.startDate;
  }),

  endDateDisplay: computed('endDate', 'timezone', 'timeZone', 'nextOccurrenceDate', function () {
    if (this.nextOccurrenceDate && this.nextOccurrenceDate !== this.startDate) {
      return (
        this.shiftToFirstOccurrenceUtcOffset(this.startDate, this.nextOccurrenceDate) + (this.endDate - this.startDate)
      );
    } else return this.endDate;
  }),

  dateDay: computed('startDateDisplay', function () {
    if (this.startDateDisplay) {
      return new Date(this.startDate).toLocaleDateString(CurrentUserStore.getState().get('jsLocale'), {
        timeZone: this.timezone, // TODO: remove
        day: 'numeric',
      });
    }
  }),

  dateMonthShort: computed('startDateDisplay', function () {
    if (this.startDate) {
      return new Date(this.startDateDisplay).toLocaleDateString(CurrentUserStore.getState().get('jsLocale'), {
        timeZone: this.timezone, // TODO: remove
        month: 'short',
      });
    }
  }),

  // month name or month and year if year is not the same as current
  dateMonth: computed('startDateDisplay', 'month', function () {
    const locale = CurrentUserStore.getState().get('jsLocale');

    if (this.month) {
      // birthdays have month
      const d = new Date(new Date(this.getNowMillis()).getUTCFullYear(), this.month, 1, 0, 0, 0);
      return d.toLocaleDateString(locale, {
        timeZone: 'UTC',
        month: 'long',
      });
    }

    const startDate = new Date(this.startDateDisplay),
      format = {
        timeZone: this.timezone, // TODO: remove
        month: 'long',
      };

    if (new Date(this.getNowMillis()).getUTCFullYear() !== startDate.getUTCFullYear()) {
      format.year = 'numeric';
    }

    return startDate.toLocaleDateString(locale, format);
  }),

  fullDateText: computed('startDateDisplay', 'endDateDisplay', 'isSameDayEvent', function () {
    if (!this.startDateDisplay) return '';
    const locale = CurrentUserStore.getState().get('jsLocale'),
      format = {
        timeZone: this.timezone, // TODO: remove
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      startDateText = new Date(this.startDateDisplay).toLocaleDateString(locale, format);

    if (this.isSameDayEvent) {
      return startDateText;
    } else {
      const endDate = new Date(this.endDateDisplay);
      return startDateText + ' - ' + endDate.toLocaleDateString(locale, format);
    }
  }),

  fullTimeText: computed('startDateDisplay', 'endDateDisplay', 'allDay', function () {
    if (!this.startDateDisplay) return '';
    if (this.allDay) return __('All Day');

    const locale = CurrentUserStore.getState().get('jsLocale'),
      format = {
        timeZone: this.timezone, // TODO: remove
        hour: 'numeric',
        minute: 'numeric',
      },
      startDateText = new Date(this.startDateDisplay).toLocaleTimeString(locale, format),
      endDateText = new Date(this.endDateDisplay).toLocaleTimeString(locale, format);

    return startDateText + ' - ' + endDateText;
  }),

  fullNextOccurrenceDateText: computed(
    'endDateDisplay',
    'startDateDisplay',
    'nextOccurrenceDate',
    'isSameDayEvent',
    function () {
      if (!this.nextOccurrenceDate) return '';

      const locale = CurrentUserStore.getState().get('jsLocale');
      const format = {
        timeZone: this.timezone, // TODO: remove
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      const nextOccurrenceText = new Date(
        this.shiftToFirstOccurrenceUtcOffset(this.startDate, this.nextOccurrenceDate)
      ).toLocaleDateString(locale, format);

      if (this.isSameDayEvent) {
        return nextOccurrenceText;
      } else {
        let end = this.nextOccurrenceDate + daysDiff(this.endDateDisplay, this.startDateDisplay) * 24 * 60 * 60 * 1000;
        end = new Date(end);
        return nextOccurrenceText + ' - ' + end.toLocaleDateString(locale, format);
      }
    }
  ),

  originalRecurrenceDateText: computed('startDate', 'endDate', function () {
    if (!this.startDate) return '';

    const locale = CurrentUserStore.getState().get('jsLocale'),
      format = {
        timeZone: this.timezone, // TODO: remove
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      startDateText = new Date(this.startDate).toLocaleDateString(locale, format);

    if (this.isSameDayEvent) {
      return startDateText;
    } else {
      const endDate = new Date(this.endDate);
      return startDateText + ' - ' + endDate.toLocaleDateString(locale, format);
    }
  }),

  isSameDayEvent: computed('startDate', 'endDate', function () {
    if (this.startDate) {
      return !isDifferentDay(this.startDate, this.endDate);
    }
  }),

  fullDateTimeText: computed(
    'endDateDisplay',
    'startDateDisplay',
    'timezone',
    'isSameDayEvent',
    'month',
    'day',
    function () {
      if (!this.startDateDisplay && !this.isBday) return '';

      const nowMillis = this.getNowMillis(),
        now = new Date(nowMillis);

      const dateFormat = {
        // September 12th
        timeZone: this.timezone, // TODO: remove
        month: 'long',
        day: 'numeric',
      };

      const locale = CurrentUserStore.getState().get('jsLocale');

      if (this.isBday) {
        const bd = Date.UTC(now.getUTCFullYear(), this.month - 1, this.day, 0, 0, 0);

        return todayTomorrowOrDate(bd, nowMillis, locale, this.timezone);
      }

      let text,
        startDate = new Date(this.startDateDisplay),
        endDate = new Date(this.endDateDisplay);

      const timeFormat = {
        timeZone: this.timezone, // TODO: remove
        hour: 'numeric',
        minute: 'numeric',
      };

      if (this.isSameDayEvent) {
        if (this.allDay) {
          text = todayTomorrowOrDate(this.startDateDisplay, nowMillis, locale, this.timezone);
        } else {
          text = __('{startDate} at {startTime} until {endTime}', {
            startDate: todayTomorrowOrDate(this.startDateDisplay, nowMillis, locale, this.timezone),
            startTime: startDate.toLocaleTimeString(locale, timeFormat),
            endTime: endDate.toLocaleTimeString(locale, timeFormat),
          });
        }
      } else {
        if (this.allDay) {
          text = __('{startDate} until {endDate}', {
            startDate: startDate.toLocaleDateString(locale, dateFormat),
            endDate: endDate.toLocaleDateString(locale, dateFormat),
          });
        } else {
          text = __('{startDate} at {startTime} until {endDate} at {endTime}', {
            startDate: todayTomorrowOrDate(this.startDateDisplay, nowMillis, locale, this.timezone),
            startTime: startDate.toLocaleTimeString(locale, timeFormat),
            endDate: endDate.toLocaleDateString(locale, dateFormat),
            endTime: endDate.toLocaleTimeString(locale, timeFormat),
          });
        }
      }

      return text;
    }
  ),

  shortDateTimeText: computed('startDateDisplay', 'endDateDisplay', 'allDay', 'isSameDayEvent', function () {
    if (!this.startDateDisplay || !this.timezone) return '';

    let text;

    const nowMillis = this.getNowMillis(),
      locale = CurrentUserStore.getState().get('jsLocale');

    const dateFormat = {
        // Sep 12th
        timeZone: this.timezone, // TODO: remove
        month: 'short',
        day: 'numeric',
      },
      timeFormat = {
        timeZone: this.timezone, // TODO: remove
        hour: 'numeric',
        minute: 'numeric',
      };

    if (this.isSameDayEvent) {
      if (this.allDay) {
        text = todayTomorrowOrDate(this.startDateDisplay, nowMillis, locale, this.timezone, 'short');
      } else {
        text = __('{startDate} at {startTime}', {
          startDate: todayTomorrowOrDate(this.startDateDisplay, nowMillis, locale, this.timezone, 'short'),
          startTime: new Date(this.startDateDisplay).toLocaleTimeString(locale, timeFormat),
        });
      }
    } else {
      text = __('{startDate} until {endDate}', {
        startDate: new Date(this.startDateDisplay).toLocaleDateString(locale, dateFormat),
        endDate: new Date(this.endDateDisplay).toLocaleDateString(locale, dateFormat),
      });
    }

    return text;
  }),

  isPastEvent: computed('endDate', 'startDate', 'nextOccurrenceDate', function () {
    if (this.get('nextOccurrenceDate')) {
      return this.get('nextOccurrenceDate') + (this.get('endDate') - this.get('startDate')) < Date.now();
    }
    return this.get('endDate') < Date.now();
  }),

  repeatText: computed('recurrence', 'startDateDisplay', 'endDateDisplay', function () {
    return EventUtils.getRecurrenceSummaryText(this, CurrentUserStore.getState().get('jsLocale'));
  }),

  eventInviteBtnText: computed('groupId', function () {
    if (this.get('groupId.length')) return __('Invite Group Members');
    else return __('Invite People');
  }),

  user: attr('object'), // birthdays
  day: attr('number'), // birthdays
  month: attr('number'), // birthdays
  isContact: attr('boolean'), // birthdays
  isBday: attr('boolean'),

  getNowMillis() {
    return Date.now();
  },

  //e.g. a recurring event was created for 5PM event.timeZone=America/Dawson,
  // we want to show 5PM despite changes in DST in that tz,
  // other tzs will see a changed time 4PM / 6PM whenever America/Dawson changes DST
  // if hours or mins are different between orig and next date without DST,
  // this will reset nextDate hours and mins to originalDate hours and mins,
  // keeping the nextDate utc date the same
  // (i.e. this assumes that backend sends correct date as nextOccurranceDate but can send wrong hours or mins)
  shiftToFirstOccurrenceUtcOffset(originalDate, nextDate) {
    const zone = this.timeZone || this.timezone,
      origWithoutTz = new Date(shiftToUtc(zone, originalDate)),
      nextWithoutTz = new Date(shiftToUtc(zone, nextDate));

    if (
      origWithoutTz.getUTCHours() !== nextWithoutTz.getUTCHours() ||
      origWithoutTz.getUTCMinutes() !== nextWithoutTz.getUTCMinutes()
    ) {
      const offDiff = utcOffsetMillis(zone, originalDate) - utcOffsetMillis(zone, nextDate);

      return utcMidnightMillis(nextDate) + utcTimeOfDayMillis(originalDate) + offDiff;
    } else return nextDate;
  },
});

export default model;

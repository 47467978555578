import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, computed, get } from '@ember/object';
import { reads } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

import Scrolling from 'mewe/utils/scrolling-utils';
import { fetchFeedGroup, fetchFeedPrivatePosts, getPrivatePostFeed } from 'mewe/fetchers/fetch-feed';
import { Theme } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class ChatInfoMedia extends Component {
  @reads('args.thread') thread;

  @service router;

  @tracked feed;

  scrolling = Scrolling();
  maxPosts = 10;
  feedInitiated = false;

  @action
  onDestroy() {
    if (this.feedInitiated) {
      removeObserver(this, 'feed.nextPage', this.bindFeedScrolling);
    }
  }

  initFeed() {
    this.feed = getPrivatePostFeed(this.thread.id);

    if (this.thread.isGroupChat) {
      fetchFeedGroup(this.thread.id, { maxResults: this.maxPosts });
    } else if (!this.thread.isEventChat) {
      fetchFeedPrivatePosts(this.thread.id, { maxResults: this.maxPosts, threadId: this.thread.id });
    }

    addObserver(this, 'feed.nextPage', this.bindFeedScrolling);
    this.feedInitiated = true;
  }

  bindFeedScrolling() {
    const scrollEl = document.querySelector('.chat-info-media_content.posts');
    if (!scrollEl) return;

    if (this.args.mediaOpened === 'posts' && this.args.chatInfoActive && this.feed?.nextPage) {
      this.scrolling.bindScrollDownElement(
        scrollEl,
        () => {
          this.loadMorePosts();
        },
        800
      );
    } else {
      this.scrolling.unbindScrollDown(scrollEl);
    }
  }

  @computed('thread.chatType')
  get postScope() {
    const chatType = this.thread.chatType;

    if (chatType === 'UserChat') return Theme.PRIVATEPOSTS;
    else if (chatType === 'GroupChat') return Theme.GROUP;
    else if (chatType === 'EventChat') return Theme.EVENT;
  }

  @computed('thread.isEventChat')
  get showPostsTab() {
    return false; // don't show posts tab for now because it's buggy
    return !this.thread.isEventChat;
  }

  @computed('thread.id', 'args.mediaOpened', 'args.chatInfoActive')
  get showPhotos() {
    return this.args.chatInfoActive && this.thread.id && this.args.mediaOpened === 'photos';
  }

  @computed('thread.id', 'args.mediaOpened')
  get showFiles() {
    return this.thread.id && this.args.mediaOpened === 'files';
  }

  @computed('thread.id', 'args.mediaOpened')
  get showPosts() {
    return this.thread.id && this.args.mediaOpened === 'posts';
  }

  @action
  openMediaTab(tab) {
    this.feed = null;

    this.args.openMediaTab(tab);

    if (tab === 'posts') {
      next(this, () => {
        this.initFeed();
      });
    }
  }

  @action
  loadMorePosts() {
    dispatcher.dispatch('feed', 'loadMore', this.feed);
  }

  @action
  openProfile(user) {
    const groupId = this.thread.chatType === 'GroupChat' ? this.thread.id : null;
    const eventId = this.thread.chatType === 'EventChat' ? this.thread.event.id : null;

    if (user) {
      const userId = get(user, 'id') || get(user, 'userId');

      if (eventId) {
        this.router.transitionTo('app.event.attendees.profile', eventId, userId);
      } else if (groupId && groupId != 'contacts') {
        this.router.transitionTo('app.group.members.profile', groupId, userId);
      } else {
        this.router.transitionTo('app.publicid', userId);
      }
    }
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action, computed, set, get } from '@ember/object';
import { A } from '@ember/array';
import { each, isNumber } from 'lodash';
import { scheduleOnce, later } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { includes } from 'lodash';

import EventsApi from 'mewe/api/events-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import LinkController from 'mewe/pods/components/others/mw-postbox/controllers/link';
import PS from 'mewe/utils/pubsub';
import FunctionalUtils from 'mewe/shared/functional-utils';
import ChatUtils from 'mewe/utils/chat-utils';
import Mentions from 'mewe/utils/mentions-utils';
import ChatStore from 'mewe/stores/chat-store';
import GiphyPopup from 'mewe/pods/components/popups/mw-giphy-popup';
import { factory as popup } from 'mewe/utils/popup-utils';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import { isSafari, isFirefox, isFirefox81, isChrome85 } from 'mewe/shared/utils';
import { getElHeight, animateScrollTo } from 'mewe/utils/elements-utils';
import isUndefined from 'mewe/utils/isUndefined';
import { chatMsgsPerPage, msgMaxLength } from 'mewe/constants';
import { openPostbox } from 'mewe/utils/dialogs-common';
import toServer from 'mewe/stores/text-parsers/to-server';
import dispatcher from 'mewe/dispatcher';
import { guidFor } from '@ember/object/internals';
import clientPermissions from 'mewe/utils/permissions-utils';

// chat base class, it's extended by mw-chat and mw-chat-window
export default class MwChatBase extends Component {
  @service pickers;
  @service router;
  @service settings;
  @service chat;
  @service account;
  @service dynamicDialogs;

  parseTextValueKey = 'thread.newMessage'; // used in link scrapping mixin to get text value by property name
  msgMaxLength = msgMaxLength;
  loadOlderMessagesOffset = 200; // how far from the top of container scroll should more messages be fetched
  loadNewerMessagesOffset = 200; // how far from the bottom of container scroll should more messages be fetched
  userScrollLockExpirationMS = 300000; // prevent autoscrolling to bottom for this time when user scrolls the chat feed
  previousOldestMessageId = null;
  scrollDownTimeoutMs = 1000;
  lastFetchTryTime = null;
  lastUserScrollTime = null;
  allMessagesLoaded = false;
  isScrollAnimating = false;
  elementId = guidFor(this);

  scrolling = Scrolling();
  isOlderFF = isFirefox() && !isFirefox81();
  fileUploadRequests = A();
  windowEvents = A();
  chatObservers = A();

  @tracked element;
  @tracked connectionError;
  @tracked chatInfoActive;
  @tracked blockReportDropdownOpened;
  @tracked chatInfoMediaActive;
  @tracked chatInfoMembersActive;
  @tracked scrollToUnread;
  @tracked mentionsStrategy;
  @tracked fetchingMore;
  @tracked fetchingNewerMessages;
  @tracked voiceRecordingVisible;
  @tracked isAudioRecording;
  @tracked audioUploaded;
  @tracked audioRecordingUrl;
  @tracked audioRecordingBlob;
  @tracked isAudioConverting;
  @tracked unreadAmountOnInit;
  @tracked firstMessagesRendered;
  @tracked lastReadMsgIsAboveCurrentScroll;
  @tracked hasScrolledToLastReadMessage;
  @tracked isFirstMissedMessageAbove;
  @tracked isFirstMissedMessageBelow;
  @tracked isSendingIntervalActive;
  @tracked filesInProgress = A();
  @tracked newWsMsgsCount = 0;
  @tracked isAudioVoice = false; // default value required for upload requests
  @tracked sendOptionsVisible = false;
  @tracked state;

  /**
   * Old scroll-observable Mixin description, keep it as it might help understanding how it was (is) working:
   * Notifies observers of 'scrollObservableDelta' whenever the element returned from getScrollElement is scrolled
   * more than 'scrollObservableThreshold' amount of pixels in the same direction.
   * E.g. scrollObservableThreshold is 50, window is scrolled 49px downwards.
   * The notification will fire if the element is then scrolled one more px downwards or 99px upwards.
   *
   * initScrollObservable needs to be called when the element returned by getScrollElement will be available
   */
  @tracked scrollObservableDelta = 0;
  @tracked scrollObservableScrollTop = 0;
  @tracked scrollObservableScrollBottom = 0;
  @tracked scrollObservableThreshold = 20;

  @alias('args.thread') thread;

  getScrollElement = () => this.element.querySelector('.chat-scroll-wrapper');
  getChatTextareaElement = () => this.element.querySelector('.chat_send-form_textarea .ql-editor');

  constructor() {
    super(...arguments);

    this.state = ChatStore.getState();

    this.unreadAmountOnInit = this.thread?.newestMessagesShown ? this.thread?.unread || 0 : 0;

    this.bindPubSub();

    this.linkController = new LinkController(this);
  }

  @action
  onInsert(element) {
    this.element = element;
    this.storeObserver(this, 'scrollObservableDelta', this.scrollChange);
  }

  @action
  onDestroy() {
    each(this.windowEvents, (e) => document.removeEventListener(e.name, e.handler));

    this.state.uploadsInProgress?.removeObject(this.elementId);

    if (this.chatPhotouploadFile) this.chatPhotouploadFile.destroy();
    if (this.chatPhotouploadAudio) this.chatPhotouploadAudio.destroy();
    if (this.chatPhotoupload) this.chatPhotoupload.destroy();

    this.tearDownScrollObservable();

    this.cleanupObservers();

    this.unbindPubSub();
  }

  scrollChange() {
    let scrolledUpwards = this.scrollObservableDelta < 0,
      scrollElem = this.getScrollElement();

    if (!scrollElem || !this.thread?.id) {
      this.fetchingMore = false;
      return;
    }

    let lastScrollHeight = this.lastScrollHeight,
      currentScrollHeight = scrollElem.scrollHeight,
      scrollCausedByThreadChange = !isUndefined(lastScrollHeight) && lastScrollHeight > currentScrollHeight;

    if (scrollCausedByThreadChange || this.thread?.scrollTo) {
      this.lastScrollHeight = currentScrollHeight;
      this.fetchingMore = false;
      set(this, 'thread.scrollTo', null);

      return;
    }

    // check if older messages should be loaded
    if (scrolledUpwards) {
      // safari has different scroll calculations in column-reverse containers
      const isNearTop =
        isSafari() || isChrome85() || isFirefox81()
          ? scrollElem.scrollHeight - getElHeight(scrollElem) + this.scrollObservableScrollTop <
            this.loadOlderMessagesOffset
          : this.scrollObservableScrollTop < this.loadOlderMessagesOffset;

      if (isNearTop && !this.isScrollAnimating) {
        let oldestMsgId = this.thread?.messages?.[this.thread.messages.length - 1]?.id,
          oldestMsgIdIsDifferent = oldestMsgId !== this.previousOldestMessageId,
          lastFetchTryTime = this.lastFetchTryTime,
          // e.g. connection issues might cause FE to have old offset so we allow another fetch after 2secs
          enoughTimePassedSinceLastFetchTry = !lastFetchTryTime || new Date() - lastFetchTryTime > 2000;

        if (
          this.thread?.messagesInitied &&
          !this.fetchingMore &&
          !this.thread?.scrollTo &&
          (oldestMsgIdIsDifferent || enoughTimePassedSinceLastFetchTry)
        ) {
          this.fetchEarlierMessages();
        }
      }
    }

    // check if newer messages should be loaded
    if (!scrolledUpwards) {
      const isNearBottom = this.isScrolledBottom(this.loadNewerMessagesOffset);

      // load messages on scroll down only when not latest msg is loaded
      if (isNearBottom && !this.isScrollAnimating && !this.thread?.newestMessagesShown) {
        let newestMsgId = this.thread?.messages?.[0]?.id,
          newestMsgIdIsDifferent = newestMsgId !== this.previousNewestMessageId,
          lastFetchTryTime = this.lastFetchTryTime,
          // e.g. connection issues might cause FE to have old offset so we allow another fetch after 2secs
          enoughTimePassedSinceLastFetchTry = !lastFetchTryTime || new Date() - lastFetchTryTime > 2000;

        if (
          this.thread?.messagesInitied &&
          !this.thread?.scrollTo &&
          !this.fetchingNewerMessages &&
          (newestMsgIdIsDifferent || enoughTimePassedSinceLastFetchTry)
        ) {
          this.fetchNewerMessages();
        }
      }
    }

    this.checkHasScrolledToLastReadMessage();
    this.checkFirstMissedWsMessagePosition(true);

    this.lastUserScrollTime = new Date();
    this.lastScrollHeight = currentScrollHeight;
  }

  @computed('thread.{isGroupChat,group,closed,isEventChat,deactivated,group.permissions.length}')
  get canChat() {
    if (this.thread?.deactivated || this.thread?.closed) {
      return false;
    }

    if (this.thread?.isGroupChat || (this.thread?.isEventChat && this.thread?.group)) {
      let group = this.thread?.group;

      if (group) {
        return includes(group.permissions, clientPermissions.COMMENT);
      } else {
        return true; // group is probably loading, prevent showing 'this thread is closed'
      }
    }

    return true;
  }

  @computed('thread.chatRequester')
  get isMyChatRequest() {
    return this.account.activeUser.id === this.thread?.chatRequester;
  }

  @computed('thread.{id,isUserChat,isMultiUsersChat,closed}')
  get sendPmAvailable() {
    // SG-30562 - Block the possibility of sending PP as the first message in a chat (SG-30822)
    const t = this.thread;
    return t && t.id && !t.closed && (t.isUserChat || t.isMultiUsersChat);
  }

  @computed('thread.{noMsgsInThread,isEventChat}')
  get showEventChatPlaceholder() {
    return this.thread?.noMsgsInThread && this.thread?.isEventChat;
  }

  @computed('thread.{noMsgsInThread,isGroupChat}')
  get showGroupChatPlaceholder() {
    return this.thread?.noMsgsInThread && this.thread?.isGroupChat;
  }

  @computed('thread.{noMsgsInThread,group,participants.length,selectedItems.length}')
  get showUsersChatPlaceholder() {
    return (
      this.thread?.noMsgsInThread &&
      !this.thread?.group &&
      (this.thread?.participants?.length > 1 || this.thread?.selectedItems?.length >= 1)
    );
  }

  @computed('showGroupChatPlaceholder', 'showEventChatPlaceholder', 'showUsersChatPlaceholder')
  get showSomePlaceholder() {
    return this.showGroupChatPlaceholder || this.showEventChatPlaceholder || this.showUsersChatPlaceholder;
  }

  @computed('thread.{isNewChat,failedToCreate}', 'isThreadCreatedFromSuggestion')
  get showNewChatHeader() {
    // failedToCreate - new chat with message that failed to be sent, display as normal chat with message to resend
    // isThreadCreatedFromSuggestion - chat from suggestion should have user name in header
    return this.thread?.isNewChat && !this.thread?.failedToCreate && !this.isThreadCreatedFromSuggestion;
  }

  @computed('thread.{closed,deactivated}', 'args.contact.deactivated')
  get chatClosedReason() {
    if (this.thread?.closed) return 'closed';
    else if (this.thread?.deactivated || this.args.contact?.deactivated) return 'deactivated';
    else return false;
  }

  @computed('thread.{id,isNewChat,notContactNotClosed}')
  get chatOptionsAvailable() {
    // settings not available in chat without id (new chat or expanded on someones profile page)
    return this.thread?.id && !this.thread?.notContactNotClosed;
  }

  @computed('thread.{scrollTo,messages}')
  get fetchingAroundMessages() {
    return this.thread?.scrollTo && !this.thread?.messages?.find((m) => m.id === this.thread?.scrollTo);
  }

  @computed('thread.{messagesInitied,messagesBeingFetched}', 'fetchingMore', 'fetchingNewest', 'fetchingAroundMessages')
  get isLoading() {
    return (
      !this.thread?.messagesInitied ||
      this.thread?.messagesBeingFetched ||
      this.fetchingMore ||
      this.fetchingNewest ||
      this.fetchingAroundMessages
    );
  }

  get isTypingShow() {
    // checking for element to know if component was already rendered, otherwise checking scroll is not possible
    return this.thread?.typingUsers?.length && this.element && this.isScrolledBottom(0, 0);
  }

  @computed('thread.getUserChatParticipant')
  get other1on1ParticipantName() {
    let chatParticipant = this.thread?.getUserChatParticipant;
    return chatParticipant && (chatParticipant.firstName || chatParticipant.name);
  }

  // --new messages-- border should be visible when user opens chat (chat box or chat window)
  // and if it has unread messages - it should stay in place until chat box is closed/minimized or thread is changed in chat window
  @computed('thread.{isNewChat,lastReadMessageId,messages.length,newestMessagesShown}', 'unreadAmountOnInit')
  get showLastReadMessageBorder() {
    return (
      this.unreadAmountOnInit &&
      this.thread?.newestMessagesShown &&
      this.thread?.messages?.length > 1 && // it looks weird if new msg border is shown if there's only 1 msg in thread
      this.thread?.lastReadMessageId &&
      this.thread?.lastReadMessageId !== this.thread?.messages?.[0]?.id &&
      !this.thread?.isNewChat
    );
  }

  // show "jump to latest" when thread was opened in the middle (with aroundId)
  // e.g. from notification about emojied msg, and newest msgs are not loaded yet
  @computed('scrollChatButtonsAvailable', 'thread.{messages.length,isNewChat,newestMessagesShown}')
  get showJumpToLatestButton() {
    return this.scrollChatButtonsAvailable && !this.thread?.newestMessagesShown;
  }

  // open chat with many unread messages where first unread message is above visible area
  // when user will click on button or manually scroll to last read msg then button disappears until next chat opening
  @computed(
    'scrollChatButtonsAvailable',
    'hasScrolledToLastReadMessage',
    'lastReadMsgIsAboveCurrentScroll',
    'thread.newestMessagesShown'
  )
  get showLastReadButtonAbove() {
    return (
      this.scrollChatButtonsAvailable &&
      this.lastReadMsgIsAboveCurrentScroll &&
      !this.hasScrolledToLastReadMessage &&
      this.thread?.newestMessagesShown
    );
  }

  // show "X new messages" button above when there are new WS messages above scroll position
  // => scroll to the bottom and then receive new msgs from WS until first of them is above chat window
  @computed(
    'scrollChatButtonsAvailable',
    'newWsMsgsCount',
    'isFirstMissedMessageAbove',
    'thread.newestMessagesShown',
    'showLastReadButtonAbove'
  )
  get showNewMessagesButtonAbove() {
    return (
      this.scrollChatButtonsAvailable &&
      this.newWsMsgsCount &&
      this.isFirstMissedMessageAbove &&
      !this.showLastReadButtonAbove && // 'Last read' button has priority over new msgs
      this.thread?.newestMessagesShown
    );
  }

  // show "X new messages" button below when there are new WS messages below scroll position
  // => scroll a bit to top and then receive new msgs from WS so first unread message is below chat window
  @computed(
    'scrollChatButtonsAvailable',
    'newWsMsgsCount',
    'isFirstMissedMessageBelow',
    'thread.newestMessagesShown',
    'showLastReadButtonAbove'
  )
  get showNewMessagesButtonBelow() {
    return (
      this.scrollChatButtonsAvailable &&
      this.newWsMsgsCount &&
      this.isFirstMissedMessageBelow &&
      !this.showLastReadButtonAbove && // 'Last read' button has priority over new msgs
      this.thread?.newestMessagesShown
    );
  }

  // flag for checking if basic conditions are matched for showing any buttons linking to last/new messages
  @computed(
    'firstMessagesRendered',
    'thread.{messagesInitied,isNewChat,messages.length}',
    'threadIsMinimizedOrMaximizing'
  )
  get scrollChatButtonsAvailable() {
    return (
      this.thread?.messagesInitied &&
      this.thread?.messages.length &&
      !this.thread?.isNewChat &&
      this.firstMessagesRendered &&
      !this.threadIsMinimizedOrMaximizing
    );
  }

  @computed(
    'thread.{isGroupChat,isEventChat,isMultiUsersChat,eventAttendeesCount,group.membersCount,participants.length,observableOthers.length}'
  )
  get chatParticipantsText() {
    if (this.thread?.isGroupChat) {
      const count = this.thread?.group?.membersCount;
      return __('{count} Group Member', { count: count });
    } else if (this.thread?.isEventChat) {
      const count_2 = this.thread?.eventAttendeesCount;
      return __('{count} Attendees', { count: count_2 });
    } else if (this.thread?.isMultiUsersChat) {
      const count_3 = this.thread?.observableOthers?.length + 1;
      return __('{count} Participants', { count: count_3 });
    }
  }

  get chatMediaText() {
    return __('Photos and Files');
    // posts tab is currently disabled because it is buggy
    // if (!this.thread?.isEventChat) {
    //   return __('Photos, Files and Posts');
    // } else {
    //   return __('Photos and Files');
    // }
  }

  @action
  toggleChatInfo(value) {
    if (!this.isChatWindow) {
      if (!this.thread?.expandChatSize && !this.chatInfoActive) {
        this.expand();
      }

      this.formattingDropdownOpened = false;
    }

    this.chatInfoActive = isDefined(value) ? value : !this.chatInfoActive;
    this.chatInfoMediaActive = false;
    this.chatInfoMembersActive = false;

    // idea is to load event attendees count when chat info panel is opened
    // but only once (if it was not loaded yet) and only for event chats
    if (this.chatInfoActive && this.thread?.isEventChat) {
      if (!this.thread?.eventAttendeesCount) {
        EventsApi.getOwnerAndAttendeesCount(this.thread?.event?.id).then((data) => {
          if (isDefined(data.attendeesCount)) {
            // +1 because event host is not counted as attendee
            set(this, 'thread.eventAttendeesCount', data.attendeesCount + 1);
          }
        });
      }
    }

    if (!this.chatInfoActive) {
      this.focusTextarea();
    }
  }

  @action
  toggleSendButtonOptions() {
    this.sendOptionsVisible = !this.sendOptionsVisible;
  }

  @action
  openChatInfoMembers() {
    this.chatInfoMembersActive = true;
  }

  @action
  openChatInfoMedia() {
    this.chatInfoMediaActive = true;
  }

  bindScroll() {
    this.initScrollObservable(true);
  }

  bindTextareaFocus() {
    const textareaEl = this.getChatTextareaElement();

    if (textareaEl) {
      this.focusHandlerBind = this.focusHandler.bind(this);
      this.blurHandlerBind = this.blurHandler.bind(this);
      textareaEl.addEventListener('focus', this.focusHandlerBind);
      textareaEl.addEventListener('blur', this.blurHandlerBind);
    }
  }

  focusHandler() {
    this.markAsRead();

    if (this.thread && !this.isMinimizedSmallOrExpandedChat) {
      ChatStore.send('focusChat', this.thread);
    }
  }

  blurHandler(e) {
    const wasFocusedOutside = !e.relatedTarget || !this.element.contains(e.relatedTarget);

    if (this.thread && wasFocusedOutside) {
      set(this, 'thread.chatFocused', false);
    }
  }

  fetchEarlierMessages(isFirstLoadBatch) {
    if (this.allMessagesLoaded) return;

    const oldestMessage = this.thread?.messages?.[this.thread.messages.length - 1];

    this.previousOldestMessageId = oldestMessage && oldestMessage.id;
    this.fetchingMore = !isFirstLoadBatch;
    this.lastFetchTryTime = isFirstLoadBatch ? null : new Date();

    let threadId = this.thread?.id,
      params = {
        threadId: threadId,
        doneCb: (data) => {
          if (!this.isDestroying && !this.isDestroyed && this.thread?.id === threadId) {
            if (!data.messages || data.messages.length === 0) {
              this.fetchingMore = false;
              this.hasScrolledToLastReadMessage = true;
              this.allMessagesLoaded = true;
            }
          }
        },
        failCb: () => {
          if (!this.isDestroying && !this.isDestroyed && this.thread?.id === threadId) {
            // TODO: show error msg
            this.fetchingMore = false;
          }
        },
      };

    if (this.scrollToUnread) {
      // + 10 to avoid immediately fetching more
      let distanceToUnread = this.unreadAmountOnInit + 10 - this.thread?.messages?.length;
      if (distanceToUnread > chatMsgsPerPage) {
        if (distanceToUnread > 100) {
          if (this.thread?.lastReadMessageId) {
            params.aroundId = this.thread?.lastReadMessageId;
          } else {
            params.limit = distanceToUnread > 200 ? 200 : distanceToUnread;
          }
        } else {
          params.limit = distanceToUnread;
        }
      }
    }

    if (!params.aroundId && oldestMessage) {
      params.beforeId = oldestMessage.id;
    }

    dispatcher.dispatch('chat', 'getChatThreadMessages', params);
  }

  fetchNewerMessages() {
    if (this.fetchingNewerMessages) return;

    const newestMessage = this.thread?.messages?.[0];

    if (!newestMessage) {
      return this.fetchEarlierMessages();
    }

    this.fetchingNewerMessages = true;
    this.lastFetchTryTime = new Date();
    this.previousNewestMessageId = newestMessage.get('id');

    let threadId = this.thread?.id,
      cb = () => {
        if (!this.isDestroying && !this.isDestroyed && this.thread?.id === threadId) {
          this.fetchingNewerMessages = false;
        }
      };

    dispatcher.dispatch('chat', 'getChatThreadMessages', {
      threadId: threadId,
      afterId: newestMessage.id,
      doneCb: cb,
      failCb: cb,
    });
  }

  didRenderAllMessages(msgObj, msgEl) {
    const isFirstLoad = !this.lastFetchTryTime && !msgObj.isWs; // force scroll down on first load
    const isNewestMessage = msgObj.id === this.thread?.messages?.[0]?.id;
    const scrollToMsgPositionIfNeeded = () => {
      let msgParentRelativeOffsetTop = msgEl.offsetTop,
        scrollElem = this.getScrollElement(),
        alreadyInCorrectScrollPos =
          msgParentRelativeOffsetTop && msgParentRelativeOffsetTop + getElHeight(msgEl) < getElHeight(scrollElem); // newer webkit browsers can already have correct scroll pos, small top offset seems to be an indicator of it

      if (!alreadyInCorrectScrollPos) {
        let scrollHeightDiff = scrollElem.scrollHeight - (this.lastScrollHeight || 0);
        this.scrollTo(scrollElem.scrollTop + scrollHeightDiff, true, true);
      }
    };

    if (this.scrollToUnread) {
      this.scrollToLastReadMessage(true);
      this.fetchingMore = false;
      scrollToMsgPositionIfNeeded();
    } else if (this.thread?.scrollTo) {
      if (this.thread?.scrollTo === msgObj.id) {
        this.scrollTo(this.getElScrollPosition(msgEl), true, true, true);

        this.fetchingMore = false;
        this.fetchingNewerMessages = false;
      }
    } else if (this.fetchingMore) {
      this.fetchingMore = false;
      scrollToMsgPositionIfNeeded();
    } else if (isFirstLoad) {
      // only firefox doesn't have native scroll to bottom in messages container
      if (isFirefox() && !isFirefox81()) this.scrollDown(true, true);

      // scroll may not be visible even after first batch of msgs was fetched
      if (
        this.thread?.messages?.length >= ChatStore.getChatMsgsPerFirstLoadAmount() &&
        this.chatMessagesScrollIsNotVisible()
      ) {
        this.fetchEarlierMessages(true);
        ChatStore.doubleChatMsgsPerFirstLoadAmount();
      } else {
        // don't show scroll-to-last-read-btn if there's no scroll bar
        if (
          this.thread?.messagesInitied &&
          this.unreadAmountOnInit &&
          !this.hasScrolledToLastReadMessage &&
          this.chatMessagesScrollIsNotVisible()
        ) {
          this.hasScrolledToLastReadMessage = true;
        }
      }

      // need to set some value to mark that first load/render was done
      // otherwise rendering new message sent by current user would still be treated as first load (SG-27323)
      this.lastFetchTryTime = 1;

      this.lastReadMsgIsAboveCurrentScroll = this.isLastReadMessageAboveView();
    } else if (msgObj.isMyMessage && (isNewestMessage || msgObj.isPre)) {
      // there is native scrolling if msgs list is scrolled to bottom and new msg is rendered, otherwise forceScrollDown
      // FF doesn't handle native auto scroll so for it always trigger scroll
      if ((isFirefox() && !isFirefox81()) || !this.isScrolledBottom(0, 0)) {
        this.forceScrollDown();
      }

      // adding own message also reset counter of new unseen WS messages
      this.newWsMsgsCount = 0;
    } else if (msgObj.isWs) {
      // FF doesn't handle native auto scroll to bottom
      if (isFirefox() && !isFirefox81() && this.isScrolledBottom(getElHeight(msgEl))) {
        this.scrollDown(true, true, true);

        // for FF need to wait for scroll to be finished
        later(() => {
          if (this.isDestroying || this.isDestroyed) return;
          this.checkFirstMissedWsMessagePosition();
        }, 1000);
      } else {
        this.checkFirstMissedWsMessagePosition();
      }
    }

    // if chat is open in dedicated & small chat, setting isWs to false needs to be delayed so other chat doesn't see it as isWs false too early
    if (msgObj.isWs) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroying || this.isDestroyed) return;
        msgObj.set('isWs', false);
      });
    }

    if (this.bindScrollAfterMsgsRendered) {
      this.bindScroll();
      this.bindScrollAfterMsgsRendered = false;
    }

    if (this.thread?.chatFocused) {
      this.markAsRead();
    }
  }

  isLastReadMessageAboveView() {
    if (!this.thread?.newestMessagesShown || !this.showLastReadMessageBorder) return;
    if (this.thread?.lastReadMessageId === this.thread?.messages?.[0]?.id) return;

    const lastReadEl = this.element.querySelector('.unread-msgs-border');

    if (lastReadEl)
      return lastReadEl.getBoundingClientRect().top - lastReadEl.parentNode.getBoundingClientRect().top < 0;
    // --new msg-- is not in the view so must be not loaded yet (showLastReadMessageBorder indicates that it should be displayed with some msg)
    else return true;
  }

  // get position to scroll to
  getElScrollPosition(el) {
    const scrollEl = this.getScrollElement();
    return el.offsetTop - getElHeight(scrollEl) / 3; // positioning in 1/3 of chat viewport from the top
  }

  scrollTo(pos, dontCheckForLastUserScrollTime, runNow, animate) {
    let scroll = () => {
      if (this.isDestroying || this.isDestroyed) return;

      let lastUserScrollTime = this.lastUserScrollTime;

      if (
        dontCheckForLastUserScrollTime ||
        !lastUserScrollTime ||
        new Date() - lastUserScrollTime > this.userScrollLockExpirationMS
      ) {
        let msgWrapperEl = this.getScrollElement();
        if (msgWrapperEl) {
          let scrollToPos = typeof pos === 'function' ? pos(msgWrapperEl) : pos;

          if (animate) {
            this.isScrollAnimating = true;

            animateScrollTo(msgWrapperEl, scrollToPos, 300, () => {
              if (!this.isDestroyed && !this.isDestroying) {
                this.isScrollAnimating = false;
              }
            });
          } else {
            msgWrapperEl.scrollTop = scrollToPos;
          }
        }
      }
    };

    if (runNow === true) {
      scroll();
    } else {
      let runAfterMs = isNumber(runNow) ? runNow : this.scrollDownTimeoutMs;

      later(scroll, runAfterMs);
    }
  }

  scrollDown(dontCheckForLastUserScrollTime, runNow, animate) {
    this.scrollTo(
      () => {
        // dontCheckForLastUserScrollTime is set to false when msg from WS - don't markAsRead in such case
        if (
          (this.thread?.chatFocused && dontCheckForLastUserScrollTime) ||
          (this.isChatWindow && document.activeElement && document.activeElement === this.getChatTextareaElement())
        ) {
          this.markAsRead();
        }
        return 0; // in "flex-direction: column-reverse" container scrolling to 0 means to bottom
      },
      dontCheckForLastUserScrollTime,
      runNow,
      animate
    );
  }

  forceScrollDown() {
    this.lastUserScrollTime = null;
    this.scrollDown(true, false, true);
  }

  // msgHeight is height of last message, we calculating if scrolled after this message is rendered
  isScrolledBottom(msgHeight, offset) {
    let isBottom = false,
      wrapperEl = this.getScrollElement();

    offset = isUndefined(offset) ? 30 : offset;

    if (wrapperEl) {
      // safari has different scroll calculations in column-reverse containers
      if (isSafari() || isChrome85() || isFirefox81()) {
        isBottom = wrapperEl.scrollTop + offset + msgHeight >= 0;
      } else {
        isBottom = wrapperEl.scrollHeight - msgHeight - offset <= wrapperEl.scrollTop + getElHeight(wrapperEl);
      }
    }

    return isBottom;
  }

  fadeOutScrollToLastReadButton() {
    let btn = this.element.querySelector('.scroll-to-last-read-btn');

    if (btn) {
      let threadId = this.thread?.id;

      if (threadId === this.thread?.id && !(this.isDestroyed || this.isDestroying)) {
        this.hasScrolledToLastReadMessage = true;
        setTimeout(() => btn.remove(), 500);
      }
    } else {
      this.hasScrolledToLastReadMessage = true;
    }
  }

  chatMessagesScrollIsNotVisible() {
    let msgsEl = this.getScrollElement();
    return msgsEl && msgsEl.scrollHeight <= getElHeight(msgsEl) + 2; // + 2 to account for borders
  }

  checkHasScrolledToLastReadMessage() {
    if (this.hasScrolledToLastReadMessage || !this.thread?.messagesInitied) return;

    if (this.unreadAmountOnInit && this.lastReadMsgIsAboveCurrentScroll) {
      if (!this.isLastReadMessageAboveView()) {
        this.hasScrolledToLastReadMessage = true;
      }
    } else {
      // if no unread messages on chat opening then it was opened on last message
      this.hasScrolledToLastReadMessage = true;
    }
  }

  checkFirstMissedWsMessagePosition(userScrolled) {
    if (!this.newWsMsgsCount) return;

    const msgEl = this.element.querySelectorAll('.chat-message')[this.newWsMsgsCount - 1];

    if (!msgEl) return;

    const msgPosition = msgEl.getBoundingClientRect();
    const scrollElPosition = this.getScrollElement().getBoundingClientRect();

    const isScrollButtonVisible = this.showNewMessagesButtonAbove || this.showNewMessagesButtonBelow;
    const isFirstWsMsgAbove = msgPosition.bottom - scrollElPosition.top < 0;
    const isFirstWsMsgBelow = scrollElPosition.bottom - msgPosition.top < 0;

    // button visible on top/bottom, if message is already in view then reset newWsMsgsCount and hide button
    if (isScrollButtonVisible) {
      if (!isFirstWsMsgAbove && !isFirstWsMsgBelow) {
        this.newWsMsgsCount = 0;
      }
    } else {
      // firstMissedWsMessage is in view (no scroll btn) and user did scroll chat => reset newWsMsgsCount to avoid
      // scroll button appearing when user will scroll chat to position when newWsMsg will be out of view
      if (userScrolled) {
        this.newWsMsgsCount = 0;
      }
    }

    this.isFirstMissedMessageAbove = isFirstWsMsgAbove;
    this.isFirstMissedMessageBelow = isFirstWsMsgBelow;
  }

  storeComposedMessage(newMsg, mentionedUsers) {
    const newMsgServer = toServer(newMsg, {
      parseNativeMarkdown: true,
    });

    if (!newMsgServer && newMsgServer !== '') return;
    // saving message in LS while typing
    // it will be cleaned in LS after sending and can be restored in case of page refresh or chat closing
    const threadId = this.thread?.id;
    const timer = new Date().getTime();
    const storingTimer = this.storingTimer;

    if (isDefined(threadId)) {
      // always allow saving empty message if user deleted text, not only after timeout
      if (newMsgServer.trim() === '' || !storingTimer || timer - storingTimer > 100) {
        this.storingTimer = new Date().getTime();
        ChatUtils.setComposedMsgs({
          threadId: threadId,
          text: newMsg,
          mentions: mentionedUsers, // mentionedUsers needed for proper mentions parsing
        });
      }
    }
  }

  chatTypingAction(newMsg) {
    let mentions = A();
    if (this.mentionsStrategy && this.mentionsStrategy.mentions) {
      mentions = this.mentionsStrategy.mentions;
      set(this, 'thread.mentions', mentions);
    }

    this.chat.pushIsTyping(this, newMsg);
    this.storeComposedMessage(newMsg, mentions);
  }

  sendFile(id) {
    dispatcher.dispatch(
      'chat',
      'sendMessage',
      this.thread,
      {
        attachmentIds: [id],
        replyTo: this.thread?.replyTo?.id,
        doneCallback: () => {
          // attachment is auto sent after upload, if there is no other text in creator then clear reply message
          if (this.thread?.replyTo && !toServer(this.thread?.newMessage, { parseNativeMarkdown: true }).length) {
            set(this, 'thread.replyTo', null);
          }
        },
      },
      this.isChatWindow
    ); // <---- BE expects an array of attachments ids
  }

  storeUploadRequest(xhr) {
    this.fileUploadRequests.pushObject(xhr);
  }

  storeEventListener(eventName, handlerFunction) {
    this.windowEvents.pushObject({ name: eventName, handler: handlerFunction });
  }

  storeObserver(ctx, attr, handlerFunction) {
    this.chatObservers.pushObject({ ctx: ctx, attr: attr, handlerFunction: handlerFunction });
    addObserver(ctx, attr, handlerFunction);
  }

  cleanupObservers() {
    this.chatObservers.forEach((o) => {
      removeObserver(o.ctx, o.attr, o.handlerFunction);
    });
  }

  cancelUpload(tempId) {
    this.filesInProgress.removeObject(this.filesInProgress.find((f) => f.tempId === tempId));

    let req = this.fileUploadRequests.find((f) => f.tempId === tempId);
    req.cancel();
  }

  cancelAllUploads() {
    let reqs = this.fileUploadRequests;
    reqs.forEach((req) => req.cancel());
    reqs.clear();

    this.filesInProgress.clear();

    this.closeAudioRecording();
  }

  focusAndMarkAsRead() {
    // textarea focus only if not in new chat creation mode, in that case focus should stay in user search input
    if (this.thread?.isNewChat) return;

    // focus not available in chat window
    if (typeof this.focus === 'function') {
      this.focus();
    }
    this.markAsRead();
  }

  markAsRead() {
    // do not unread chat if page is not focused
    if (typeof document.hasFocus === 'function' && !document.hasFocus()) return;
    // do not unread small chat if it's not focused
    if (!this.isChatWindow && !this.thread?.chatFocused) return;

    if (this.thread?.unread === 0 || !this.thread?.newestMessagesShown || this.isMinimizedSmallOrExpandedChat) return;

    if (this.markAsReadTimeoutId) return; // markAsRead already being called

    if (this.isDestroying || this.isDestroyed) return;

    this.markAsReadTimeoutId = setTimeout(() => {
      if (this.thread?.unread > 0 && !this.isMinimizedSmallOrExpandedChat) {
        dispatcher.dispatch('chat', 'markAsRead', this.thread);
      }
      if (!this.isDestroying && !this.isDestroyed) {
        this.markAsReadTimeoutId = null;
      }
    }, 200);
  }

  bindPubSub() {
    this.chatMsgSentHandlerBind = this.chatMsgSentHandler.bind(this);
    this.chatMinimizeBind = this.chatMinimize.bind(this);

    PS.Sub('chat.message.sent', this.chatMsgSentHandlerBind);
    PS.Sub('chat.minimize', this.chatMinimizeBind);

    this.connectionHandler = this.connectionHandler.bind(this);
    this.connectionLostHandler = this.connectionLostHandler.bind(this);

    PS.Sub('websocket.connection.success sse.connection.success', this.connectionHandler);
    PS.Sub('websocket.connection.error sse.connection.error', this.connectionLostHandler);

    if (!this.isChatWindow) {
      this.chatMsgSendHandlerBind = this.actions.sendMessage.bind(this);
      PS.Sub(`chat.message.send.${this.elementId}`, this.chatMsgSendHandlerBind);
    }
  }

  unbindPubSub() {
    PS.Unsub('chat.message.sent', this.chatMsgSentHandlerBind);
    PS.Unsub('chat.minimize', this.chatMinimizeBind);

    PS.Unsub('websocket.connection.success sse.connection.success', this.connectionHandler);
    PS.Unsub('websocket.connection.error sse.connection.error', this.connectionLostHandler);

    if (!this.isChatWindow) PS.Unsub(`chat.message.send.${this.elementId}`, this.chatMsgSendHandlerBind);
  }

  chatMsgSentHandler(threadId) {
    if (threadId === this.thread?.id) {
      if (this.newWsMsgsCount) this.newWsMsgsCount += 1;
    }
  }

  setMentionsStrategy() {
    let existingMentions = A();

    if (this.thread?.mentions?.length) {
      existingMentions = A(this.thread?.mentions);
    }

    let participants = this.thread?.participants;

    if (participants?.length) {
      existingMentions.pushObjects(participants.map((el) => ({ id: el.id, name: el.name })));
    }

    let strategy = Mentions.createTextCompleteStrategy(this.getMentionsScope());
    strategy.mentions = existingMentions;

    this.mentionsStrategy = strategy;
  }

  getMentionsScope() {
    let mentionsScope = {};

    if (this.thread?.isGroupChat) mentionsScope.groupId = this.thread?.id;
    else if (this.thread?.isEventChat) mentionsScope.eventId = this.thread?.event?.id;
    else if (this.thread?.pageId) mentionsScope.pageId = this.thread?.pageId;
    else mentionsScope.users = this.thread?.observableOthers;

    // https://sgrouples.atlassian.net/browse/SG-37441 (MW)
    const chatCreationParticipants = this.thread?.selectedItems;
    if (chatCreationParticipants?.length) {
      mentionsScope.users = chatCreationParticipants.map((el) => ({
        id: el.id,
        name: el.user.name,
        publicLinkId: el.user.publicLinkId,
      }));
    }

    return mentionsScope;
  }

  chatMinimize(threadId) {
    if (this.thread?.id === threadId && !this.thread?.minimized) {
      this.toggleUserchatSize();
    }
  }

  connectionHandler() {
    if (this.isDestroyed || this.isDestroying) return;
    this.connectionError = false;
  }

  connectionLostHandler() {
    if (this.isDestroyed || this.isDestroying) return;
    this.connectionError = true;
  }

  initScrollObservable(runNow) {
    const doBind = () => {
      if (this.isDestroying || this.isDestroyed) return;

      let lastScrollTop = this.scrollObservableScrollTop || 0,
        lastScrollHeight = 0,
        scrollElem = this.getScrollElement();

      if (!scrollElem || this.isDestroying || this.isDestroyed) return;

      const scrollFunc = () => {
        let newScrollTop = scrollElem.scrollTop,
          newScrollBottom = scrollElem.scrollHeight - (newScrollTop + scrollElem.offsetHeight),
          // height delta is calculated for size changes which also trigger scroll change
          heightDelta = lastScrollHeight - scrollElem.offsetHeight,
          delta = newScrollTop - lastScrollTop - heightDelta;
        lastScrollHeight = scrollElem.offsetHeight;

        if (delta >= this.scrollObservableThreshold || delta <= -this.scrollObservableThreshold) {
          lastScrollTop = newScrollTop;
          this.scrollObservableDelta = delta;
          this.scrollObservableScrollTop = newScrollTop;
          this.scrollObservableScrollBottom = newScrollBottom;
        }
      };

      if (this.scrollObsBind) {
        scrollElem.removeEventListener('scroll', this.scrollObsBind);
      }
      this.scrollObsBind = scrollFunc.bind(this);
      scrollElem.addEventListener('scroll', this.scrollObsBind);

      lastScrollHeight = scrollElem.offsetHeight;
    };

    if (runNow) doBind();
    else scheduleOnce('afterRender', this, doBind);
  }

  // runAfterRender for when whole component is not being destroyed, but instead elem scroll is being rebound
  tearDownScrollObservable(runAfterRender) {
    let doUnbind = () => {
      if (this.isDestroying || this.isDestroyed) return;

      let el = this.getScrollElement();
      if (!el) return;

      if (this.scrollObsBind) {
        el.removeEventListener('scroll', this.scrollObsBind);
        if (!this.isDestroying && !this.isDestroyed) {
          this.scrollObsBind = null;
        }
      }
    };

    if (runAfterRender) scheduleOnce('afterRender', this, doUnbind);
    else doUnbind();
  }

  @action
  blockUser() {
    this.chat.blockUser(this.thread);
  }

  @action
  reportUser() {
    dispatcher.dispatch('contact', 'reportUser', EmberObject.create(this.thread?.getUserChatParticipant));
  }

  @action
  messageRendered(msgObj, msgEl) {
    const threadId = msgObj.threadId || msgObj.thread?.id;

    if (threadId !== this.thread?.id) return;

    if (this.isMinimizedSmallOrExpandedChat) {
      if (msgObj.isWs && !msgObj.isMyMessage && !msgObj.renderedInChat) {
        set(msgObj, 'renderedInChat', true);

        this.newWsMsgsCount += 1;
      }

      return;
    }

    if (msgObj.isWs) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroyed || this.isDestroying) return;

        // multiple ws msgs may have arrived to thread after scheduleOnce was executed, only trigger didRenderAll for the newest one
        const wsMsgs = this.thread?.messages?.filterBy('isWs');
        const lastWsMsg = wsMsgs.length ? wsMsgs[wsMsgs.length - 1] : null;
        const isLastWsMsg = msgObj.id == lastWsMsg.id;

        if (isLastWsMsg) this.didRenderAllMessages(msgObj, msgEl);
      });
    } else {
      const isFirstFetch = this.thread?.messagesInitied && !this.firstMessagesRendered;

      if (isFirstFetch) {
        this.unreadAmountOnInit = this.thread?.newestMessagesShown ? this.thread.unread || 0 : 0;
        this.firstMessagesRendered = true;
      }

      this.didRenderAllMessages(msgObj, msgEl);
    }
  }

  @action
  deleteChatRequest(isChatWindow) {
    this.chat.deleteChatRequestConfirm({
      thread: this.thread,
      isChatWindow: isChatWindow,
      isChatRequestsPage: this.state.isChatRequestsPage,
    });
  }

  // for chat requests, 1:1
  // TODO - BE has recently added a reminder-related flags, so that should be handled there as well
  @action
  doFollow() {
    let other = this.thread?.getUserChatParticipant;

    if (!other) return;

    this.chat.doFollow(other, () => {
      let notContact = this.thread?.notContact;

      if (notContact) {
        let reminder = get(notContact, 'reminder');

        if (reminder) {
          set(reminder, 'remindDelay', true);
        } else {
          set(notContact, 'reminder', {
            remindSubscr: true,
            remindDelay: true,
          });
        }
      } else {
        this.thread?.set('notContact', {
          reminder: {
            remindSubscr: true,
            remindDelay: true,
          },
        });
      }
    });
  }

  @action
  openPrivacyMailPostbox() {
    openPostbox(
      {
        sharedWithNonContact: this.thread?.notContact,
        sharedWithThreadId: this.thread?.id,
        preselectedContacts: this.thread?.observableOthers?.map((u) => {
          // we have to map it because it's passed by reference and when user changes somehing in postbox contact
          // picker there will be a mess
          return {
            id: u.id,
            name: u.name,
            badges: u.badges,
            publicLinkId: u.publicLinkId,
            _links: {
              avatar: {
                href: `/api/v2/photo/profile/{imageSize}/${u.id}?f=${u.fingerprint}`,
              },
            },
          };
        }),
        target: 'privacymail',
        theme: 'privacymail',
      },
      this.dynamicDialogs
    );
  }

  @action
  startCall(videoEnabled) {
    // thread creation callback
    const startCallCallback = (thread) => {
      if (thread && thread.id) {
        dispatcher.dispatch('chat', 'openCall', {
          thread: thread,
          videoEnabled: videoEnabled,
        });
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    };

    if (this.thread?.id) {
      dispatcher.dispatch('chat', 'openCall', {
        thread: this.thread,
        videoEnabled: videoEnabled,
      });
    } else {
      // thread doesn't exist yet, has to be created before starting a call
      dispatcher.dispatch('chat', 'sendMessage', this.thread, {
        createWithoutMsg: true,
        doneCallback: startCallCallback,
      });
    }
  }

  @action
  fetchNewestMessages() {
    let thread = this.thread;
    const replaceThreadWithLatest = () => {
      if (this.fetchingNewerMessages) return;
      if (this.router.currentRouteName === 'app.chat.thread.message') {
        this.router.transitionTo('app.chat.thread.index');
      }

      this.fetchingNewerMessages = true;

      let threadId = this.thread?.id,
        cb = () => {
          if (!this.isDestroying && !this.isDestroyed && thread.id === threadId) {
            this.fetchingNewerMessages = false;
          }
        };

      dispatcher.dispatch('chat', 'getChatThread', {
        threadId: threadId,
        doneCb: cb,
        failCb: cb,
      });
    };

    set(this, 'thread.messages', A());
    set(this, 'thread.messagesInitied', false); // to not show placeholder in chat while fetching newest msgs

    this.lastFetchTryTime = null; // to treat it like first fetch (i.e. trigger scrolling after render in FF)

    // When we are in thread.message route by clicking replay, we want to replace the current thread with the latest one
    if (['app.chat.thread.index', 'app.chat.thread.message'].includes(this.router.currentRouteName)) {
      replaceThreadWithLatest();
    } else if (thread.id) {
      dispatcher.dispatch('chat', 'getChatThreadMessages', { threadId: thread.id });
    } else {
      this.router.transitionTo('app.chat.create-thread', {
        queryParams: {
          userId: thread.selectedItems?.[0]?.user?.id || thread.others?.[0]?.id,
        },
      });
    }
  }

  @action
  scrollToLastReadMessage(calledFromScroll) {
    const animateScroll = true;

    if (calledFromScroll) {
      this.scrollToUnread = false;

      if (this.unreadAmountOnInit > this.thread?.messages?.length && this.thread?.newestMessagesShown) {
        // there were more unread msgs than overall msgs in thread, scroll up to last msg
        this.scrollTo(20, true, true, animateScroll);
      }
      // - 5 to avoid immediately fetching more
    } else if (this.unreadAmountOnInit > this.thread?.messages?.length - 5) {
      this.scrollToUnread = true;
      this.fetchEarlierMessages();

      return;
    }

    this.fadeOutScrollToLastReadButton();

    const lastReadEl = this.element.querySelector('.unread-msgs-border');

    if (!lastReadEl) return;

    this.scrollTo(this.getElScrollPosition(lastReadEl), true, true, animateScroll);

    this.focusAndMarkAsRead();
  }

  @action
  scrollToFirstMissedWsMessage() {
    const animateScroll = true;

    this.fadeOutScrollToLastReadButton();

    const firstMissed = this.element.querySelectorAll('.chat-message')[this.newWsMsgsCount - 1];

    if (!firstMissed) return;

    this.scrollTo(this.getElScrollPosition(firstMissed), true, true, animateScroll);

    this.newWsMsgsCount = 0;
    this.isFirstMissedMessageAbove = false;
    this.isFirstMissedMessageBelow = false;

    this.focusAndMarkAsRead();
  }

  @action
  showBlockReportDropdown() {
    this.blockReportDropdownOpened = !this.blockReportDropdownOpened;
  }

  @action
  showChatParticipants() {
    this.chatInfoMembersActive = true;
    this.chatInfoMediaActive = false;

    if (this.isSmallChat) this.expand();
    if (!this.chatInfoActive) this.chatInfoActive = true;
  }

  @action
  openAudioRecording() {
    this.voiceRecordingVisible = true;
  }

  @action
  closeAudioRecording() {
    this.voiceRecordingVisible = false;
  }

  @action
  onKeyUp(text, e) {
    if (e.keyCode === 38 && !toServer(this.thread?.newMessage, { parseNativeMarkdown: true }).length) {
      dispatcher.dispatch('chat', 'editLastMsg', this.thread);
    }

    if (e.keyCode === 9) {
      this.onTabKey?.(e.shiftKey);
      e.preventDefault();
      return;
    }

    if (e.keyCode === 27) {
      this.onEscKey?.();
    }

    this.linkController.parseLinkFunc(e);
  }

  @action
  editorChange() {
    // editorChange is asynchronous event from text editor
    if (this.isDestroyed || this.isDestroying) return;

    if (this.thread) {
      this.chatTypingAction(toServer(this.thread?.newMessage, { parseNativeMarkdown: true }));
    }
  }

  @action
  focusTextarea() {
    if (this.editor) {
      setTimeout(() => {
        this.editor.focus();
      }, 0);
    }
  }

  @action
  cancelReply() {
    this.thread.replyTo = null;
  }

  @action
  openGiphyPopup() {
    popup(this, GiphyPopup, 'giphy-popup')
      .create({
        parent: this.element.querySelector('.giphy-button'),
        origin: this.isChatWindow ? 'chat-window' : 'expanded-chat',
        onSelect: (data) => this.gifOrStickerSelected(data),
        onClose: () => this.focusTextarea(),
      })
      .send('open');
  }

  @action
  openStickerPicker() {
    this.pickers.openStickerPicker(this);
  }

  // auto sending gifs/stickers after selecting them in pickers
  @action
  gifOrStickerSelected(gifOrSticker) {
    if (!gifOrSticker) return;

    const isSticker = !!gifOrSticker.id; // sticker has packageId, gif is just a string

    const sendCallback = () => {
      // for new chat creation there can be no thread
      if (this.thread) {
        this.thread?.set('replyTo', null);
      }
      this.focusAndMarkAsRead();
    };

    dispatcher.dispatch('chat', 'sendMessagesAtOnce', this.thread, {
      message: isSticker ? '' : toServer('', { gifUrls: [gifOrSticker] }),
      attachmentIds: isSticker ? [gifOrSticker] : [],
      replyTo: this.thread?.replyTo?.id,
      autoSendingAttachment: true,
      callback: sendCallback,
    });

    PS.Pub('chat.message.sent', this.thread?.id);
  }

  @alias('gifOrStickerSelected') setSticker; // sticker picker relies on callback with this name

  @action
  browsePhotos() {
    if (this.isChatWindow) {
      this.element.querySelector('#chat-window_photo-upload input').click();
    } else {
      this.element.querySelector('.chat-form-input-photo').click();
    }
  }

  @action
  browseFiles() {
    if (this.isChatWindow) {
      this.element.querySelector('#chat-window_file-upload input').click();
    } else {
      this.element.querySelector('.chat-form-input-file').click();
    }
  }

  @action
  sendByEnterChange() {
    dispatcher.dispatch('client-data', 'setPreferences', {
      sendDMByEnter: !this.account.activeUser?.sendDMByEnter,
    });
  }

  @action
  setChatProperty(propertyName, value) {
    this[propertyName] = value;
  }
}

/* eslint-disable lines-between-class-members */
/* eslint-disable ember/no-get */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
/* eslint-disable ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { next, later, scheduleOnce } from '@ember/runloop';

import Mentions from 'mewe/utils/mentions-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PostUtils from 'mewe/utils/post-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import utils from 'mewe/utils/environment-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import config from 'mewe/config';
import { factory as popupFactory } from 'mewe/utils/popup-utils';
import { Theme, FeedTypes } from 'mewe/constants';
import Storage from 'mewe/shared/storage';
import LinkController from 'mewe/pods/components/others/mw-postbox/controllers/link';
import 'mewe/services/postbox-upload';
import DocumentsApi from 'mewe/api/documents-api';
import { getElHeight } from 'mewe/utils/elements-utils';
import { isEdge } from 'mewe/shared/utils';
import dispatcher from 'mewe/dispatcher';
import { reads, alias } from '@ember/object/computed';
import { A } from '@ember/array';
import { get, set, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { addObserver, removeObserver } from '@ember/object/observers';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EmberObject from '@ember/object';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
export default class MwCommentYour extends Component {
  @tracked textEditorFocused;
  @tracked mentionsStrategy;
  @tracked onUploadDoneBinded;
  @tracked voiceRecordingVisible = false;
  @tracked sticker;
  @tracked attachmentType = 'doc';
  @tracked photosOnly = false;
  @tracked selectedGifs = A();
  @tracked uploadJobs = A();
  //LinkController properties that needs to tracked
  @tracked link;
  @tracked lastFetchedLink;
  @tracked isLoadingLink;
  @tracked isLoadingGfyLinkInfo;
  @tracked sendOptionsVisible;

  // Really on the EmberObject since this will be passed to the MwVoice
  audioObject = EmberObject.create({
    audioUploaded: false,
    audioRecordingUrl: null,
    audioRecordingBlob: null,
    isAudioConverting: false,
    isAudioRecording: false,
  });
  elementId = guidFor(this);
  maxTextLimit = config.maxTextLimit;
  dropZoneTimeout = null;
  photoUrl = EnvironmentUtils.getApiHost() + '/api/v2/photo/cm';
  fileUrl = EnvironmentUtils.getApiHost() + '/api/v2/attachment/upload/cm';

  @service router;
  @service pickers;
  @service('postboxUpload') uploadService;
  @service account;
  @service dynamicDialogs;

  @alias('args.post') post;
  @alias('args.commentPost') commentPost;

  @reads('args.comment') comment;
  @reads('account.activeUser') user;

  constructor() {
    super(...arguments);

    this.setMentionStrategy();

    this.linkPreviewRemoved = false;
    this.linkController = new LinkController(this);
    addObserver(this, 'selectedGifs.length', this.gifSelected);
    addObserver(this, 'uploadJobs.length', this.attachmentChange);
    addObserver(this, 'audioObject.audioRecordingBlob', this.audioRecordingBlobChanged);
    this.onUploadDoneBinded = this.onUploadDoneFunc.bind(this);
  }

  willDestroyElement() {
    document.removeEventListener('dragover', this.handleDrop);
    removeObserver(this, 'selectedGifs.length', this.gifSelected);
    removeObserver(this, 'uploadJobs.length', this.attachmentChange);
    removeObserver(this, 'audioObject.audioRecordingBlob', this.audioRecordingBlobChanged);
  }

  get scope() {
    return this._scope ?? this.args.scope;
  }

  @action
  setInputEl(el) {
    this.element = el;
    this.insertElement();
  }

  @action
  insertElement() {
    this.handleDrop = (e) => {
      const files = e.dataTransfer;
      e.stopPropagation();
      e.preventDefault();
      if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
        return;
      }
      // don't show drop zones when some dialog is opened (except when this comment is in single post dialog)
      if (this.dynamicDialogs.components.length && !this.args.isPostDialog) {
        return;
      }
      if (!this.isDestroyed && !this.isDestroying) {
        this.initDropZone();
      }
    };

    document.addEventListener('dragover', this.handleDrop);

    this.loadCommentFromStorage();

    if (!this.discoverGroupPost) {
      this.textEditorFocused = this.args.focusOnInsert;
    }
  }

  setMentionStrategy() {
    let comment = this.value;
    let scopeAndId = PostUtils.getPostScopeAndId(this.postOrCommentPost);
    let scopeId = scopeAndId.scopeId;

    if (!this.scope || this.scope === FeedTypes.ALL || this.scope === 'myworld') {
      this._scope = this.scope === 'myworld' ? FeedTypes.CONTACTS : scopeAndId.scope;
    }

    switch (this.scope) {
      case Theme.MYCLOUD:
        this.isMyCloud = true;
        break;

      case Theme.PROFILE:
      case Theme.CONTACTS:
        this.mentionsStrategy = Mentions.createTextCompleteStrategy('contacts', comment);
        break;

      case Theme.PRIVATEPOSTS:
        // commentPost in case of comment reply in PP
        this.mentionsStrategy = Mentions.createTextCompleteStrategy(
          {
            privacyPost: this.post || this.commentPost,
          },
          comment
        );

        break;

      case Theme.EVENT:
        this.mentionsStrategy = Mentions.createTextCompleteStrategy({ eventId: scopeId }, comment);
        break;

      case Theme.GROUPS:
      case Theme.GROUP:
        this.mentionsStrategy = Mentions.createTextCompleteStrategy({ groupId: scopeId }, comment);
        break;
    }
  }

  // checkings for discoverGroupPost are done to prevent opening comment area
  // in Discover Feed for group posts (page posts can be commented under)
  @computed('post', 'commentPost')
  get discoverGroupPost() {
    const post = this.commentPost || this.post;
    return post.discoverScope === 'group' ? post : null;
  }

  @computed('post.{groupId,group.isMember}')
  get isAuthorLinkDisabled() {
    // disable group/profile link in group posts if user is not member of group
    return this.post?.groupId && !this.post?.group.isMember;
  }

  @computed('attachmentsUploaded', 'link', 'showLinkPreview', 'voiceRecordingVisible')
  get showCommentMediaButtons() {
    return !this.voiceRecordingVisible && !this.attachmentsUploaded && !this.showLinkPreview;
  }

  @computed('voiceRecordingVisible', 'attachmentsUploaded', 'sticker')
  get showAddStickerButton() {
    // don't allow sticker with attachments other than audio, don't allow more than one sticker
    return !this.sticker && (!this.attachmentsUploaded || this.voiceRecordingVisible);
  }

  @computed('post.public', 'commentPost.public')
  get showCommentPublicInfo() {
    return this.post?.public || this.commentPost?.public;
  }

  @computed('post', 'commentPost', 'comment.post')
  get postOrCommentPost() {
    return this.post || this.commentPost || this.comment.post;
  }

  @computed('postOrCommentPost.group.id', 'postOrCommentPost.page.{avatar,isOwnerAdmin}', 'user')
  get userAvatar() {
    let avatar;

    if (get(this, 'postOrCommentPost.page.isOwnerAdmin')) {
      avatar = (get(this, 'postOrCommentPost.page.avatar') || '').replace('{imageSize}', '150x150');
    } else {
      avatar =
        utils.getImgHost(true) +
        utils.getCurrentUserAvatar('150x150', get(this, 'postOrCommentPost.group.id'), this.user);
    }

    return avatar;
  }

  @computed('postOrCommentPost.{postedByPage,page.isOwnerAdmin}')
  get photoAllowed() {
    // only owner/admin can add photo in comments under Page posts
    if (this.postOrCommentPost.get('postedByPage')) {
      return this.postOrCommentPost.get('page.isOwnerAdmin');
    }

    return true;
  }

  @computed(
    'postOrCommentPost.{postedByPage,page.isOwnerAdmin}',
    'isUploading',
    'uploadJobs.length',
    'selectedGifs.length',
    'sticker'
  )
  get uploadAllowed() {
    // only owner/admin can add photo in comments under Page posts
    if (this.postOrCommentPost.get('postedByPage')) {
      return this.postOrCommentPost.get('page.isOwnerAdmin');
    }

    if (this.sticker) {
      return false;
    }

    return !this.isUploading && !this.uploadJobs.length && !this.selectedGifs.length;
  }

  @computed('post')
  get isCommentReplyString() {
    return this.post ? 'comment' : 'reply';
  }

  @computed('post')
  get commentPlaceholder() {
    if (this.post) {
      return __('Add a comment');
    } else {
      return __('Write a reply');
    }
  }

  @computed('post')
  get publicCommentText() {
    if (this.post) {
      return __('This is a public post and your comment will be visible to everyone on MeWe.');
    } else {
      return __('This is a public post and your reply will be visible to everyone on MeWe.');
    }
  }

  @computed('elementId', 'post.id')
  get enterPressInputId() {
    return `comment-${this.elementId}-enter-press`;
  }

  @computed('selectedGifs.length', 'uploadJobs.length', 'isUploading')
  get attachmentsUploaded() {
    return this.selectedGifs.length || this.uploadJobs.length || this.isUploading;
  }

  @computed('comment.id', 'post.{id,postItemId}')
  get commentOrPostId() {
    return this.comment?.id ? this.comment.id : this.post?.postItemId;
  }

  @computed('args.{inMediaDialog,chatInfoActive}')
  get commentNarrow() {
    return this.args.chatInfoActive || this.args.inMediaDialog;
  }

  gifSelected() {
    if (this.selectedGifs.length === 1) {
      this.focusTextarea();
    }
  }

  attachmentChange() {
    if (!this.uploadJobs.length) {
      this.photosOnly = false;
    }
  }

  @action
  updateUploadJobs(action, job) {
    this.uploadJobs[action](job);
  }

  updateEditor(text) {
    if (this.editor) {
      this.editor.update(text);
    }
  }

  confirmExit() {
    return __(`You haven't finished your comment. Do you really want to leave without finishing?`);
  }

  initDropZone() {
    if (!this.uploadAllowed && !this.photoAllowed) {
      return;
    }
    if (this.uploadService.postboxOpened) {
      return;
    }

    const dropEl = this.element.querySelector('.drop-overlay');
    let timeout = this.dropZoneTimeout;

    if (!timeout) {
      dropEl.classList.add('to-drop');
    } else {
      clearTimeout(timeout);
    }

    let dropZoneTimeout = setTimeout(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.dropZoneTimeout = null;
      }
      dropEl.classList.remove('to-drop');
    }, 100);
    this.dropZoneTimeout = dropZoneTimeout;
  }

  updateCommentInLS() {
    const timer = new Date().getTime();
    const storingTimer = this.storingTimer;
    const commentText = this.newComment ? this.newComment.trim() : '';

    // saving text in LS while typing
    // it will be cleaned in LS after sending and can be restored in case of page refresh or route transition
    if (!commentText && commentText !== '') {
      return;
    }

    if (!storingTimer || timer - storingTimer > 100) {
      this.storingTimer = new Date().getTime();
      dispatcher.dispatch(
        'comment',
        'storeComposedComment',
        this.commentOrPostId,
        commentText,
        this.mentionsStrategy?.mentions
      );
    }
  }

  focusInCommentEditorFun() {
    if (this.args.inMediaDialog) {
      this.args.commentBoxExpandedUpdate?.(true);
      return;
    }

    const commentWrappers = this.element.getElementsByClassName('comment-your_bottom');

    if (!commentWrappers || !commentWrappers.length) {
      return;
    }

    // doesn't work good in Edge yet SG-26464
    if (isEdge()) {
      return;
    }

    // later() is needed, because there is animation set for 0.1s
    later(
      this,
      () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        commentWrappers[0].scrollIntoView({
          block: 'nearest',
        });
      },
      300
    );
  }

  onUploadDoneFunc() {
    this.focusTextarea();

    if (get(this, 'audioObject.audioRecordingBlob')) {
      set(this, 'audioObject.audioUploaded', true);
      set(this, 'audioObject.isAudioConverting', false);
      set(this, 'audioObject.isAudioRecording', false);
    }
  }

  audioRecordingBlobChanged() {
    const blob = get(this, 'audioObject.audioRecordingBlob');

    if (!blob) {
      this.cancelVoice();

      return;
    } else {
      this.audioUploadingXhr = DocumentsApi.uploadBlobAudio(blob);

      this.audioUploadingXhr
        .then((data) => {
          if (data.files && data.files[0]) {
            set(this, 'audioObject.audioUploaded', true);
            set(this, 'audioObject.isAudioConverting', false);
            set(this, 'audioObject.isAudioRecording', false);
            this.audioFileId = data.files[0].id;
            this.attachmentType = 'audio';
            this.focusTextarea();
          } else {
            this.cancelVoice();
          }
        })
        .catch((data) => {
          if (data.data && data.data.errorCode === 700) {
            this.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
          } else if (data.data && data.data.errorCode === 623) {
            FunctionalUtils.showLimitedPostingNotice();
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
          this.cancelVoice();
        });
    }
  }

  @computed('link', 'isLoadingLink')
  get showLinkPreview() {
    return this.isLoadingLink || this.link;
  }

  canDoLinkScrapping() {
    // no mixing link preview with other attachments
    return this.photoAllowed && this.uploadAllowed && !this.voiceRecordingVisible && !this.attachmentsUploaded;
  }

  parseTextValueKey = 'newComment';

  convertGifUrlFromLinks = true;

  @computed('link.image.url')
  get linkImageUrl() {
    return htmlSafe(`background-image: url(${this.link.get('image.url')})`);
  }

  loadCommentFromStorage() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    const commentOrPostId = this.commentOrPostId;
    if (!commentOrPostId) {
      return;
    }

    let commentsFromLS = Storage.getComments();
    if (commentsFromLS && commentsFromLS[commentOrPostId]) {
      this.newComment = commentsFromLS[commentOrPostId].text;

      if (this.mentionsStrategy) {
        set(this, 'mentionsStrategy.mentions', commentsFromLS[commentOrPostId].mentions);
      }
    }

    // focus has to be postponed so that text from LS can be set before
    next(this, () => {
      if (this.args.focusOnInsert) {
        this.focusTextarea();
      }
    });
  }

  @action
  focusInCommentEditor() {
    if (this.discoverGroupPost) {
      showGroupPreviewDialog(this.dynamicDialogs, this.postOrCommentPost?.group, {
        isDiscoverPostInteraction: true,
        isGroupPreview: true,
      });
      return;
    }

    this.textEditorFocused = true;
    this.focusInCommentEditorFun();
  }

  @action
  addComment() {
    if (this.isUploading || get(this, 'audioObject.isAudioConverting') || get(this, 'audioObject.isAudioRecording')) {
      FunctionalUtils.error(__('Uploading is in progress'));
      return;
    }

    const newCommentNonServer = this.newComment ? this.newComment.trim() : '';

    const newCommentServer = toServer(newCommentNonServer, {
      mentionsStrategy: this.mentionsStrategy,
      gifUrls: this.selectedGifs,
      parseNativeMarkdown: true,
    });

    if (
      newCommentServer === '' &&
      !this.uploadJobs.length &&
      !this.audioFileId &&
      !this.selectedGifs.length &&
      !this.sticker &&
      !this.link
    ) {
      return;
    }

    const options = {
      post: this.post,
      comment: this.comment,
      user: this.user,
      scope: this.scope,
      attachmentUpload: this.attachmentUpload,
      selectedGifs: this.selectedGifs,
      mentionsStrategy: this.mentionsStrategy,
      sticker: this.sticker,
      linkPreviewRemoved: this.linkPreviewRemoved,
    };

    if (this.uploadJobs.length) {
      const uploadJob = this.uploadJobs[0];
      options.fileId = uploadJob.fileId;
      options.attachmentType = uploadJob.attachmentType;
    }

    if (this.audioFileId) {
      options.fileId = this.audioFileId;
      options.attachmentType = 'audio';
    }

    if (this.link) {
      options.link = this.linkController.getLinkData();
    } else if (this.linkPreviewRemoved) {
      // SG-24393 - Ensure link preview is removed
      options.removeLink = true;
    }

    options.page = this.post?.page || this.commentPost?.page;
    options.newComment = newCommentServer;

    // in case of fail, restore data from options to component's params so it can be resent
    options.failCallback = (data) => {
      if (data && data.data && data.data.errorCode === 700) {
        storageAlert(this);
      } else {
        FunctionalUtils.error(__('This comment could not be shared. Check your internet connection and try again.'));
      }

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.newComment = newCommentNonServer;
      this.selectedGifs = options.selectedGifs;
      this.sending = false;

      this.editor.update(newCommentNonServer);

      // next() to first set text in line above
      next(this, () => {
        this.focusTextarea();
      });
    };
    // clean data that could be used in case of request failure
    options.doneCallback = () => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.mentionsStrategy) {
        set(this, 'mentionsStrategy.mentions', A());
      }

      this.sending = false;

      if (this.attachmentUpload) {
        if (this.attachmentUpload.isDestroyed || this.attachmentUpload.isDestroying) {
          return;
        }
        this.attachmentUpload.cleanAllUploadJobs?.();
      }
    };

    this.newComment = '';
    this.textEditorFocused = false;
    set(this, 'audioObject.audioUploaded', false);
    set(this, 'audioObject.audioRecordingUrl', null);
    this.voiceRecordingVisible = false;
    this.sticker = null;
    this.linkPreviewRemoved = false;
    this.sending = true;
    this.attachmentType = 'doc';
    this.selectedGifs = A();
    this.uploadJobs = A();

    if (this.editor) {
      this.editor.update('');
    }

    // text-editor instance is already destroyed, so need to set this there
    window.onbeforeunload = null;

    this.linkController.setInitialLinkProperties();

    if (this.args.inMediaDialog) {
      this.args.commentBoxExpandedUpdate?.(false);
    }

    dispatcher.dispatch('comment', 'addComment', options);

    if (this.comment) {
      this.args.replyAdded?.();
    }

    // scroll to newly added comment in MD
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      // SG-22498 after adding comment in MD scroll to last comment, same for replies to photo comment
      // not applies to replies when opened photo post and there are comments and replies
      if ((this.args.inMediaDialog && this.post) || this.mediaCommentInDialog) {
        const postWrapper = this.element.closest('.media-dialog_post');
        const postEl = this.element.closest('.c-mw-post, .media-dialog_post_comment');
        postWrapper.scrollTop = getElHeight(postEl);
      }
    });
  }

  @action
  focusTextarea() {
    if (this.editor) {
      this.editor.focus();
    }
  }

  @action
  openUserProfile() {
    if (this.scope === Theme.PAGE) {
      return;
    }

    this.router.transitionTo('app.publicid', this.user.get('profileId'));
  }

  @action
  toggleSendButtonOptions() {
    this.sendOptionsVisible = !this.sendOptionsVisible;
  }

  @action
  toggleWayOfSending() {
    dispatcher.dispatch('client-data', 'setPreferences', { sendDMByEnter: !this.user.get('sendDMByEnter') });
  }

  @action
  setChild(child) {
    set(this, 'attachmentUpload', child);
  }

  @action
  browseFiles() {
    this.attachmentType = 'doc';
    this.photosOnly = false;

    scheduleOnce('afterRender', () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.element.querySelector(`[type=file][name='file']`).click();
    });
  }

  @action
  browseImgFiles() {
    this.attachmentType = 'photo';
    this.photosOnly = true;
    scheduleOnce('afterRender', () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.element.querySelector(`[type=file][name='image']`).click();
    });
  }

  @action
  addGif() {
    import(
      /* webpackChunkName: 'app.popup.giphy' */
      'mewe/pods/components/popups/mw-giphy-popup'
    ).then((Popup) => {
      popupFactory(this, Popup.default, 'mw-giphy-popup')
        .create({
          parent: this.element.querySelector('.svg_icon-gif_new'),
          selectedGifs: this.selectedGifs,
          onClose: () => this.focusTextarea(),
        })
        .send('open');
    });

    return false;
  }

  @action
  setSelectedGifs(value) {
    this.selectedGifs = value;
  }

  @action
  textChanged(text, event) {
    this.linkController.parseLinkFunc(event);
    this.updateCommentInLS();
  }

  @action
  openAudioRecording() {
    this.voiceRecordingVisible = true;
    this.shareButtonDisabled = true;
  }

  @action
  cancelVoice() {
    this.attachmentUpload?.cleanAllUploadJobs();

    this.uploadJobs = A();
    set(this, 'audioObject.audioRecordingUrl', null);
    set(this, 'audioObject.audioRecordingBlob', null);
    this.voiceRecordingVisible = false;
    this.audioFileId = null;
  }

  @action
  parseLinkBlur() {
    this.linkController.parseLinkFunc({ type: 'focusout' });
  }

  @action
  removeLink() {
    this.linkController.removeLink();
  }

  @action
  openStickerPicker() {
    this.pickers.openStickerPicker(this);
  }

  @action
  removeSticker() {
    this.sticker = null;
  }

  @action
  setSticker(sticker, ctx = this) {
    ctx.sticker = sticker;
    ctx.focusTextarea?.();
  }

  @action
  fileUploadUpdate({ isUploading }) {
    set(this, 'isUploading', isUploading);
  }

  @action
  setUploadType(type) {
    this.photosOnly = type === 'photos';
    this.filesOnly = type === 'files';
  }

  //This will be removed when we adapt the LinkController with glimmer.
  setProperties(properties) {
    // Loop through each key in the `properties` object
    // eslint-disable-next-line no-unused-vars
    for (let key in properties) {
      // If the component has a tracked property with the same name as the key,
      // update the tracked property's value and trigger a re-render
      if (this[key] !== undefined && typeof this[key] !== 'function') {
        this[key] = properties[key];
      }
    }
  }

  set(prop, value) {
    this[prop] = value;
  }

  @action
  setAudioAttr(prop, value) {
    set(this, `audioObject.${prop}`, value);
  }

  @action
  setEditor(editor) {
    this.editor = editor;
  }
}

import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import Service from '@ember/service';
import Storage from 'mewe/shared/storage';
import { keys } from 'mewe/shared/keys';
import tokenManager from 'mewe/shared/token-manager';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { isInJail, isLocked } from 'mewe/utils/jail-utils';
import { publicIdEmailParams } from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { getQueryStringParams } from 'mewe/shared/utils';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import config from 'mewe/config';

export default class AuthenticationService extends Service {
  @service router;
  @service analytics;

  registrationCallback(registrationParams, registrationResponse, urlParams) {
    const params = registrationParams;
    const user = registrationResponse.user;

    Storage.markRegistration();
    Storage.remove(keys.smsSent);

    if (!user.locale && params.locale) {
      user.locale = params.locale;
    }

    tokenManager.set({ user: user });

    // redirection is delayed to make sure that token manager finished its job
    later(this, () => this.redirectAfterRegistration(registrationParams, user, urlParams), 1000);
  }

  redirectAfterRegistration(registrationParams = {}, user = {}, urlParams = {}) {
    // dsnpSignup sent in SMSv1, sessionId sent in SMSv2/v3 and email registration. Otherwise it's Mewe registration
    const isDsnpMode = registrationParams.sessionId || registrationParams.dsnpSignup;
    const isSiwaMode = registrationParams.siwaMode || urlParams.authorizationCode;

    urlParams.hash = '#new-user';

    if (isSiwaMode) {
      // DSNP users are granted with dark-theme and it should be turned on by default
      Storage.set(Storage.keys.desktopSettings, JSON.stringify({ activeTheme: 'dark' }));
      return this.doRedirect(urlParams);
    }

    // in DSNP flow we end up here only after SMS regisration and use is already confirmed/authenticated.
    // (email registration with DSNP ends up on email confirmation link page)
    if (isDsnpMode) {
      // DSNP users are granted with dark-theme and it should be turned on by default
      Storage.set(Storage.keys.desktopSettings, JSON.stringify({ activeTheme: 'dark' }));
      return this.doRedirect(urlParams);
    }

    // in old MeWe flow user has to confirm his email first by clicking on the link in email
    if (!isDsnpMode && !isSiwaMode) {
      Storage.set(Storage.keys.ftueUnconfirmedUser, JSON.stringify(user));
      this.router.transitionTo('welcome');
    }
  }

  loginCallback(loginParams, loginResponse, urlParams, migrationLoginCallback) {
    const user = loginResponse.user;
    const isEmailFlow = ~loginParams.username?.indexOf('@');
    const isMigrationPage = !!migrationLoginCallback; // migration has speciall callback, other logins continue to app

    tokenManager.set(loginResponse, false);
    CurrentUserStore.send('handle', user, true);

    this.analytics.sendEvent('loggedIn', {
      // `is_web3` is superproperty set before sending each event in the service,
      // but for `loggedIn` it has to be set here because service won't know yet if user is DSNP
      is_web3: !!loginResponse.dsnpDetails,
      is_web3_migration: isMigrationPage,
      login_type: isEmailFlow ? 'email' : 'phone',
    });

    // postponed to allow analytics to record events before reload,
    // this can be removed once we redirect without reloading page
    later(
      this,
      () => {
        CurrentUserStore.getState().deferred.promise.then(() => {
          if (isInJail(loginResponse)) {
            this.router.transitionTo('lockout');
          } else if (isLocked(loginResponse)) {
            this.router.transitionTo('verify');
          } else {
            // - after login in migration flow go to handle claiming step instead of redirecting to app
            if (isMigrationPage) {
              migrationLoginCallback();
            } else {
              this.redirectAfterLogin(urlParams);
            }
          }
        });
      },
      1000
    );
  }

  redirectAfterLogin(urlParams = {}) {
    if (urlParams.next) {
      // use substring of href instead of urlParams.next to avoid loosing query params from next url
      const nextIndex = window.location.href.indexOf('?next=');
      if (nextIndex !== -1) {
        // `/login?next=/foo&bar=xxx` -> `/foo&bar=xxx`
        let nextUrl = window.location.href.substring(nextIndex + 6);
        // `/foo&bar=xxx` -> `/foo?bar=xxx`
        nextUrl = nextUrl.replace('&', '?');

        window.location.href = nextUrl;
      } else {
        window.location.href = urlParams.next;
      }

      if (urlParams.next.indexOf('?download') !== -1) {
        window.setTimeout(() => (window.location.href = '/myworld'), 3500);
      }
    } else {
      this.doRedirect(urlParams);
    }
  }

  doRedirect(urlParams) {
    const hash = urlParams?.hash || '';

    // case for registration by email
    let cmpgn = urlParams?.cmpgn || '';

    // case for registration by sms
    let urlObj = new URL(window.location.href);
    // in future we can change it to more generic campaign parameter
    if (urlObj.searchParams.has('brave')) {
      cmpgn = 'brave';
    }

    if (urlParams) {
      // profile/page redirection - url is simply `/{publicId}` in both cases
      if (urlParams.ppId || urlParams.upId) {
        return (window.location = `/${urlParams.ppId || urlParams.upId}${hash}`);
      }

      if (urlParams.epId) {
        // event redirection - if there is `id-` prefix indicating actual eventId then
        // we go straight to event, otherwise it's publicId and we use it to fetch event public data to get eventId.
        const nonPublicId = ~urlParams.epId.indexOf('id-') ? urlParams.epId.replace('id-', '') : null;

        if (nonPublicId) {
          return (window.location = `/event/${nonPublicId}${hash}`);
        } else {
          return PublicPagesApi.getEvent(urlParams.epId).then(
            (data) => (window.location = `/event/${data.event.id}${hash}`)
          );
        }
      }

      if (urlParams.gpId) {
        // group redirection - if there is `id-` prefix indicating actual groupId then we go straight to group,
        // otherwise it's publicId and we use it to fetch group public data to get groupId.
        // - usecase is when we already checked on public page that user can go straigh to group, no need to repeat that.
        // - other usecase is private group invitation where we don't have publicId in the process
        const nonPublicId = ~urlParams.gpId.indexOf('id-') ? urlParams.gpId.replace('id-', '') : null;

        if (nonPublicId) {
          return (window.location = `/group/${nonPublicId}${hash}`);
        } else {
          return PublicPagesApi.group(urlParams.gpId).then((data) => {
            if (data.isMember || data.isInvited) {
              return (window.location = `/group/${data.id}${hash}`);
            } else {
              return (window.location = `/myworld?previewGroupId=${data.id}${hash}`);
            }
          });
        }
      }
    }

    // Campaign happens only with no other params (no group/page/event/user invitation)
    if (cmpgn) {
      return (window.location = `/myworld?${cmpgn}${hash}`);
    }

    // default redirection
    let defaultRedirectionParams = '';
    // deleted identifiers because of SIWA migration
    if (urlParams.removedCredentials) {
      defaultRedirectionParams = '?removedCredentials=true';
    }
    return (window.location = `/myworld${defaultRedirectionParams}${hash}`);
  }

  startSiwa(finallyCallback) {
    const params = {};
    const urlParams = getQueryStringParams();

    // passing origin of registration flow, later it will be used for redirection after confirming email.
    // in sms flow it's not needed because user is redirected from current component so it will be checked here.
    publicIdEmailParams.forEach((param) => {
      if (urlParams?.[param]) {
        params[param] = urlParams[param];
      }
    });

    PublicPagesApi.getSiwa(params)
      .then((res) => {
        if (config.environment != 'prod') {
          const getSmsTokenApi = res.location.replace('siwa/start', 'testing/siwa/token');
          MiscellaneousUtils.copyToClipboard(getSmsTokenApi, true);
        }

        window.location = res.location;
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        finallyCallback?.();
      });
  }
}

/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { postCustomInteraction } from 'mewe/utils/post-utils';
import PostApi from 'mewe/api/post-api';
import { Theme } from 'mewe/constants';
import PostUtils from 'mewe/utils/post-utils';
import GroupStore from 'mewe/stores/group-store';
import { FeedTypes } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import Mentions from 'mewe/utils/mentions-utils';
import HashtagsUtils from 'mewe/utils/hashtags-utils';
import storage from 'mewe/shared/storage';

export default class MwPostSnippet extends Component {
  @service purchase;
  @service account;
  @service dynamicDialogs;
  @service dynamicPopups;
  @service router;

  @reads('args.feedTheme') feedTheme;
  @reads('args.scope') scope;
  @reads('args.post') post;
  @reads('args.post.isTranslated') isTranslated;

  @tracked mentionsStrategy;
  @tracked hashtagsStrategy;
  @tracked didOpenComments;
  @tracked truncate;

  postApi = PostApi;
  profilePopupSelector = '.post_text .h-mention';

  constructor() {
    super(...arguments);

    if (this.args.isPostDialog && this.post.poll) {
      // if is single post dialog refresh options to show current status
      dispatcher.dispatch('poll', 'refreshVotes', this.post);
    }

    this.showProfilePopupBind = this.showProfilePopup.bind(this);
  }

  get videoFeedAutoPlaySetting() {
    return storage.get(storage.keys.videoFeedAutoPlaySetting);
  }

  get commentedOn() {
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');
    if (postedByPage) {
      return this.post.page;
    }
    return this.post.comments.feed.get('firstObject.owner');
  }

  get postedIn() {
    return this.post.page || this.post.owner;
  }

  @action
  openProfileCommentedOn() {
    let commentedProfile = this.post.comments.feed.get('firstObject.owner');
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');

    if (this.post.groupId) {
      return this.router.transitionTo('app.group.members.profile', this.post.groupId, commentedProfile.id);
    } else if (postedByPage) {
      return this.router.transitionTo('app.publicid', this.post.page.publicLinkId);
    } else {
      return this.router.transitionTo('app.publicid', commentedProfile.profileId);
    }
  }

  @action
  openProfilePostedIn() {
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');
    if (this.post.owner.id && this.post.groupId) {
      return this.router.transitionTo('app.group.members.profile', this.post.groupId, this.post.owner.id);
    } else if (postedByPage || this.postedIn?.urlId) {
      return this.router.transitionTo('app.publicid', this.post.page.publicLinkId);
    } else {
      return this.router.transitionTo('app.publicid', this.post.owner.publicLinkId);
    }
  }

  @action
  onInsert() {
    if (this.post.placeholder) return;

    // in case of group posts we need to check if user is member of that group
    // so after fetching groups list we check if group was returned from BE
    if (this.post.groupId) {
      GroupStore.getState().deferred.promise.then(() => {
        if (this.isDestroyed || this.isDestroying) return;

        const fullGroupModel = GroupStore.getGroupIfMember(this.post.groupId);
        // if user is group member then we have full group object and
        // post can be updated with that to replace SimpleGroup model
        if (fullGroupModel) {
          // groups from GET/groups response don't have 'isMember' field
          // and it's important for have that info for handling author/origin link handling
          set(fullGroupModel, 'isMember', true);

          set(this, 'post.group', fullGroupModel);
        }
      });
    }

    addObserver(this, 'post.isEditing', this.editingObserver);

    this.isInserted = true;

    //For not uthenticated users, set isPublicContent to true to use public proxy
    if (!this.account.isAuthenticated) {
      set(this, 'post.isPublicContent', true);
    }
  }

  @action
  onDestroy() {
    if (this.isInserted) {
      removeObserver(this, 'post.isEditing', this.editingObserver);
    }
  }

  get showPostVideo() {
    return this.post.medias?.length == 1 && this.post.medias?.[0]?.video;
  }

  @computed('post.{photo,video,medias.length}')
  get showPostPhoto() {
    if (this.post.photo && !(this.post.video && this.post.medias?.length == 1)) return true;

    return this.post.medias?.length > 1; // multiple photos, videos combined in one post
  }

  @computed('post.{discoverScope,isGroupPreview}')
  get isGroupPostPreview() {
    return this.post.discoverScope === 'group' || this.post.isGroupPreview;
  }

  @computed('post.isPublicContent', 'isGroupPostPreview')
  get isDisabledPostInteraction() {
    return this.post?.isPublicContent || this.isGroupPostPreview;
  }

  @computed('post.{groupId,group.isMember}')
  get isAuthorLinkDisabled() {
    // disable group/profile link for group posts if user is not member of group
    return this.post.groupId && !this.post.group.isMember;
  }

  showProfilePopup(target) {
    if (this.post?.isPublicContent) return;
    if (!this.isDestroyed && !this.isDestroying) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: target,
        owner: { id: target.getAttribute('data-userid') },
        group: this.post.group,
        isMention: true,
      });
    }
  }

  editingObserver() {
    if (!this.hashtagsStrategy) {
      const post = this.post;
      const mentionsScope = Mentions.getPostMentionsScope(post);
      const { scope, scopeId } = PostUtils.getPostScopeAndId(post);
      this.hashtagsStrategy = HashtagsUtils.getHashtagStrategy(scope, scopeId);
      this.mentionsStrategy = Mentions.createTextCompleteStrategy(mentionsScope, post);
    }
  }

  @computed('post.textDisplay', 'post.album')
  get showPostText() {
    return this.post.textDisplay || this.post.album;
  }

  @computed('post.album', 'args.inMediaDialog', 'args.threadId')
  get showPostAlbumName() {
    return this.post.album && !this.args.inMediaDialog && !this.args.threadId;
  }

  @computed('args.commentsOpened', 'didOpenComments')
  get commentsOpened() {
    return this.didOpenComments ?? this.args.commentsOpened;
  }

  @computed('post.pending', 'args.hidePostbar')
  get postbarVisible() {
    return this.args.postbarVisible ?? (!this.post.pending && !this.args.hidePostbar);
  }

  @computed('args.hidePostbar')
  get textHeight() {
    return this.args.hidePostbar && !this.args.isSharedPost ? 92 : 180;
  }

  @computed('post.textDisplay', 'args.{isRefPost,isPostDialog}')
  get displayCommentsDisabledText() {
    if (this.args.isPostDialog) {
      return true;
    } else if (!this.args.isRefPost) {
      return true;
    }
    return false;
  }


  @action
  openAlbumMediaDialog() {
    if (this.post?.isPublicContent && this.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.post);
      return;
    }

    const post = this.post;
    // shared post should be opened in SPV and there user can open album
    if (this.args.isSharedPost) return;

    let scope = post.scope || this.scope || this.feedTheme;

    if (scope == FeedTypes.ALL) {
      scope = PostUtils.getPostScopeAndId(post).scope;
    }

    this.dynamicDialogs.openDialog('media-dialog', {
      galleryView: true,
      mediaType: 'album',
      post: post,
      groupId: post.group.id,
      eventId: post.eventId || post.eventInfo?.id,
      userId: post.owner?.id, // owner undefined for e.g. page posts
      pageId: post.pageId,
      album: post.album,
      scope: scope,
      isMyCloud: this.feedTheme === Theme.MYCLOUD,
      isCurrentUserPostOwner: post.isCurrentUserPostOwner,
      allowMultipleInstances: true,
    });
  }

  @action
  setTruncate(value) {
    this.truncate = value;
  }
}

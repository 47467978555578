import BrowseCommunitiesBase from '../browse-communities-base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce, next } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { ds } from 'mewe/stores/ds';
import { getOrCreateCollectionFromHash as collection } from 'mewe/stores/models/collection';
import Scrolling from 'mewe/utils/scrolling-utils';
import Storage from 'mewe/shared/storage';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import {
  searchOpenGroups,
  clearSearchOpenGroups,
  fetchCategories,
  fetchGroupsForCategory,
  fetchMoreSearchOpenGroups,
  fetchMoreGroupsForCategory,
} from 'mewe/fetchers/fetch-groups';

const getGroups = (category) => collection(ds.groupsForCategory, category);

const SCROLL_EVENTNAME = 'communities-list';

const State = {
  BROWSE: 'Browse',
  SEARCH: 'Search',
  CATEGORIES: 'Categories',
};

class Model {
  @tracked groups = {};
  @tracked categories = {};
  @tracked searchedOpenGroups = {};
  @tracked featuredCategories = {};
}

export default class MwBrowseGroups extends BrowseCommunitiesBase {
  @service router;
  @service settings;
  @service account;
  @service dynamicDialogs;

  @tracked model = new Model();
  @tracked mobileSidebarVisible = false;
  @tracked currentLocale = '';
  @tracked currentCategory = '';
  @tracked searchValue = '';
  @tracked lastSearchValue = '';
  @tracked searchInputExpanded = false;

  scrolling = Scrolling();

  store = ds;
  states = State;
  maxResults = 20;
  offset = 0;

  constructor() {
    super(...arguments);

    try {
      this.setLocale(JSON.parse(Storage.get(Storage.keys.publiGroupsLocale)) || this.account.activeUser.locale);
    } catch (e) {
      this.setLocale(this.account.activeUser.locale);
    }

    fetchCategories({ locale: this.currentLocale });

    if (this.options?.openTopic) {
      next(this, () => {
        this.fetchCategory({ category: this.options.openTopic });
        this.openTopic = '';
      });
    }
  }

  @action
  onDestroy() {
    this.clearSearchValue();

    this.scrolling.unbindScrollDown(this.element.querySelector('.browse-communities_rows, .browse-communities_grid'));
    this.scrollingObserved.forEach((field) => removeObserver(this, field, this.toggleInfiniteScrollWrapped));

    this.modelObserved.forEach((field) => removeObserver(this, field, this.updateModelWrapped));
  }

  @action
  clearSearchValue() {
    this.clearSearch();

    this.searchValue = '';
    this.lastSearchValue = '';
    this.searchInputExpanded = false;
  }

  get featuredSorted() {
    return this.model.featuredCategories.items?.sortBy('position');
  }

  get groups() {
    return this.model.groups.items;
  }

  get showFeaturedPlaceholder() {
    return !this.model.featuredCategories.items?.length && !this.model.featuredCategories?.isFetching;
  }

  get showItemsPlaceholder() {
    return !this.model.groups.items?.length && !this.model.groups.isFetching;
  }

  get showSearchPlaceholder() {
    return (
      !this.model.searchedOpenGroups.items?.length && !this.model.searchedOpenGroups.isFetching && this.lastSearchValue
    );
  }

  get title() {
    if (this.selectedCategory) {
      return this.selectedCategory.name;
    } else if (this.searchValue) {
      return __('Search results for: {query}', {
        query: this.searchValue,
      });
    }

    return __('Featured Categories');
  }

  get selectedCategory() {
    return this.model.categories.items?.find((c) => c.category === this.currentCategory);
  }

  setupModel() {
    this.modelObserved = [
      'store.groupsForCategory.items.length',
      'store.searchedOpenGroups.items.length',
      'store.groupCategories.items.length',
      'store.featuredGroupCategories.items.length',
      'currentCategory',
    ];

    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.updateModelWrapped = () => scheduleOnce('afterRender', this, this.updateModel);
    this.modelObserved.forEach((field) => addObserver(this, field, this.updateModelWrapped));
    this.updateModel();
  }

  updateModel() {
    const category = this.currentCategory;

    this.model.groups = getGroups(category);
    this.model.categories = ds.groupCategories;
    this.model.searchedOpenGroups = ds.searchedOpenGroups;
    this.model.featuredCategories = ds.featuredGroupCategories;
  }

  setupInfiniteScroll() {
    this.scrollingObserved = [
      'currentCategory',
      'model.groups.items.length',
      'model.groups.nextPage',
      'store.searchedOpenGroups.items.length',
      'model.searchedOpenGroups.canShowMore',
    ];
    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.toggleInfiniteScrollWrapped = () => scheduleOnce('afterRender', this, this.toggleInfiniteScroll);
    this.scrollingObserved.forEach((property) => addObserver(this, property, this.toggleInfiniteScrollWrapped));
    this.toggleInfiniteScroll();
  }

  toggleInfiniteScroll() {
    if (this.state === State.SEARCH) {
      if (this.model.searchedOpenGroups.canShowMore) {
        this.scrolling.bindScrollDownElement(
          this.element.querySelector('.browse-communities_rows'),
          () => {
            if (!this.model.searchedOpenGroups.isFetching) {
              fetchMoreSearchOpenGroups({
                query: this.searchValue,
                pageSize: this.maxSearchResults,
                page: Math.ceil(this.model.searchedOpenGroups.offset / this.maxSearchResults) + 1,
              });
            }
          },
          200,
          SCROLL_EVENTNAME
        );
      }
    } else if (this.model.groups?.canShowMore) {
      if (this.currentCategory) {
        this.scrolling.bindScrollDownElement(
          this.element.querySelector('.browse-communities_grid'),
          () => {
            if (!this.model.groups?.isFetching) {
              fetchMoreGroupsForCategory({
                topic: this.currentCategory,
                maxResults: this.maxResults,
                offset: this.offset + this.model.groups.items?.length,
              });
            }
          },
          200,
          SCROLL_EVENTNAME
        );
      }
    } else {
      this.scrolling.unbindScrollDown(this.element.querySelector('.browse-communities_grid, .browse-communities_rows'));
    }
  }

  currentCategoryChanged() {
    const category = this.currentCategory;

    if (category) {
      fetchGroupsForCategory({
        topic: category,
        locale: this.currentLocale,
        offset: this.offset,
        maxResults: this.maxResults,
      });
    }
  }

  doSearch(params) {
    searchOpenGroups(params);
    this.lastSearchValue = this.searchValue;
  }

  @action
  clearSearch() {
    if (this.searchValue.length === 0) {
      this.model.searchedOpenGroups.isFetching = true;
      this.model.searchedOpenGroups.items = [];
    }

    if (this.state === State.SEARCH) {
      this.searchValue = '';
      this.lastSearchValue = '';
      this.searchInputExpanded = false;
      this._state = this.currentCategory ? State.BROWSE : State.CATEGORIES;

      clearSearchOpenGroups();
    }
  }

  @action
  backToStart() {
    this.clearSearchValue();

    this.currentCategory = null;
    this._state = State.CATEGORIES;
    this.mogbileSidebarVisible = false;
  }

  @action
  fetchCategory(category) {
    this.clearSearchValue();

    this.currentCategory = category.category;
    this._state = State.BROWSE;
    this.mogbileSidebarVisible = false;

    this.currentCategoryChanged();
    this.scrollToTop();
  }

  @action
  groupClicked(group) {
    if (group.isMember) {
      this.router.transitionTo('app.group', group.id);
      this.close();
    } else {
      showGroupPreviewDialog(this.dynamicDialogs, group, { isGroupPreview: true });
    }
  }

  @action
  setLocale(locale) {
    this.currentLocale = locale;

    fetchCategories({
      locale: this.currentLocale,
    });

    fetchGroupsForCategory({
      topic: this.currentCategory,
      locale: this.currentLocale,
      offset: this.offset,
      maxResults: this.maxResults,
    });
  }
}

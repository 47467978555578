import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { getConfigCatFlagPromise } from 'mewe/shared/config-cat';
import CurrentUserStore from 'mewe/stores/current-user-store';
import Session from 'mewe/shared/session';
import Mixpanel from 'mixpanel-browser';
import config from 'mewe/config';
import * as Sentry from '@sentry/ember';
import { isUndefined } from 'mewe/utils/miscellaneous-utils';
import { isNumber } from 'lodash';
import Storage from 'mewe/shared/storage';

import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[Analytics service]', color: 'red', enabled: true }).log;

/**
 *  TAXONOMY: https://docs.google.com/spreadsheets/d/15sy6gbyLZWGwq1gRwY_1LqWucDq93w4mlJEF-ejRPaM
 **/

/**
 *  OTHER EVENTS not listed here
 *  Mixpanel.reset() done in token-manager.js after logout
 **/

export default class Analytics extends Service {
  @service router;
  @service account;

  initialise() {
    verbose(`Initialise analytics`);
    const currentUser = CurrentUserStore.getState();

    // Mixpanel initialisation is done for both authenticated and unauthenticated users
    // but only after authentication we do 'identify' user with his 'distinctId'
    const initialiseMixpanel = (canUseProxy) => {
      verbose(`MP CanUseProxy: ${canUseProxy}`);
      verbose(`MP init with host: ${canUseProxy ? config.mixpanelApiHost : 'default'}`);

      const options = {
        debug: false, // debug fixed to false as since we use proxy it's throwing errors from MP side
        ignore_dnt: config.environment !== 'prod', // ignore do not track
      };

      if (canUseProxy) {
        options.api_host = config.mixpanelApiHost;
      }

      Mixpanel.init(config.mixpanelApiKey, options);
      verbose(`MP init done`);

      currentUser.deferred.promise.then(() => {
        if (!currentUser.distinctId) return;
        Mixpanel.identify(currentUser.distinctId);
        verbose(`MP identify done`);
      });

      this.trackSession();
    };

    // firstly get the flag from ConfigCat to know if we can use proxy for MP or not
    getConfigCatFlagPromise('is_mixpanel_proxy_enabled', true).then(initialiseMixpanel);
  }

  // ALL EVENTS AFTER INITIALISATION SHOULD BE SENT THROUGH THIS LAYER FOR VALIDATION
  sendEvent(name, ...args) {
    if (!name || !this[`_${name}`]) {
      Sentry.captureException(new Error(`Analytics: Mixpanel sendEvent - missing or incorrect event name: ${name}`));
      verbose(`Mixpanel sendEvent - missing or incorrect event name: ${name}`);
      return;
    }

    Session.isAuthenticated().then(({ isAuthenticated }) => {
      // store this to be used in mixpanelTrack method without calling Session twice for a single event
      this.isLoggedIn = !!isAuthenticated;

      // TODO - think about some global solution for this:
      // there might be issue with sending events from routes outside the `app.` scope
      // for authenticated users because CurrentUser promise can be not resolved (we need to make sure
      // that CurrentUser fetched and promise is resolved before sending any events)

      // logged in user, events will be sent with distinctId
      if (isAuthenticated) {
        // postpone any events until current user is loaded because only then
        // user is identified (in the token-manager, right after fetching user info)
        CurrentUserStore.getState().deferred.promise.then(() => {
          const distinctId = CurrentUserStore.getState().distinctId;

          // there won't be `distinctId` for users registered with "/auth/register/tests"
          // which is an endpoint created to be used during automated tests
          // reason is that we don't want to use tracking quota during automated tests
          if (!distinctId) {
            Sentry.captureException(new Error(`Analytics: Mixpanel sendEvent "${name}" - missing distinctId`));
            verbose(`Mixpanel sendEvent "${name}" - missing distinctId`);
            return;
          }

          this[`_${name}`]?.(...args);
        });
      }
      // not logged in user, e.g. on homepage
      else {
        this[`_${name}`]?.(...args);
      }
    });
  }

  // actual event request to mixpanel
  _mixpanelTrack(eventName, params = {}) {
    // all mixpanel events should have client_type for tracking platforms
    params.client_type = 'web';
    params.is_logged_in = this.isLoggedIn || false;

    // for not logged in users don't send `is_web3` param at all (it's unknown in such case)
    if (this.isLoggedIn) {
      // for `loggedIn` event this property will be passed directly event param because it's known there (in login process),
      // but it's unknown here (before fetching current user info)
      if (eventName !== 'Logged In' && this.account.activeUser) {
        params.is_web3 = !!this.account.activeUser.dsnpHandle?.length;
      }
    }

    // xTraceId added to each event for easier tracking on BE side (SG-41146)
    const trc = Storage.get(Storage.keys.xTraceId);
    if (trc) {
      params.x_trace_id = trc;
    }

    verbose(`MP event "${eventName}":`, params);
    Mixpanel.track(eventName, params);
  }

  _webSession() {
    this._mixpanelTrack('Web Session');
  }

  _pageViewed(path) {
    if (!path) {
      path = location.pathname;
    }

    this._mixpanelTrack('Page Viewed', {
      path: path,
    });
  }

  _popupViewed(name) {
    if (!name) {
      Sentry.captureException(new Error(`Analytics: Popup Viewed - missing or insufficient params`));
      verbose(`Popup Viewed - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Popup Viewed', {
      name: name,
    });
  }

  _buttonClicked(name) {
    if (!name) {
      Sentry.captureException(new Error(`Analytics: Button Clicked - missing or insufficient params`));
      verbose(`Button Clicked - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Button Clicked', {
      name: name,
    });
  }

  _groupJoined(group) {
    if (!group || !group.groupThematicType) {
      Sentry.captureException(
        new Error(`Analytics: Group Joined - missing or insufficient params, groupId: ${group?.id}`)
      );
      verbose(`Group Joined - missing or insufficient params, groupId: ${group?.id}`);
      return;
    }

    const groupType = group.isPublicApply ? 'selective' : group.isPublic ? 'open' : 'private';

    const params = {
      group_category: group.groupThematicType,
      group_type: groupType,
    };

    this._mixpanelTrack('Group Joined', params);
  }

  _purchaseMade(params = {}) {
    if (!params.itemId?.length || !params.type?.length || !params.purchase_provider) {
      Sentry.captureException(new Error(`Analytics: Purchase Made - missing or insufficient params`));
      verbose(`Purchase Made - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Purchase Made', params);
  }

  _purchaseCancelled(params = {}) {
    if (!params.itemId?.length || !params.type?.length || !params.purchase_provider || !params.reason) {
      Sentry.captureException(new Error(`Analytics: Purchase Cancelled - missing or insufficient params`));
      verbose(`Purchase Cancelled - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Purchase Cancelled', params);
  }

  _groupCreated(group) {
    if (!group || !group.groupThematicType) {
      Sentry.captureException(
        new Error(`Analytics: Group Created - missing or insufficient params, grouopId: ${group?.id}`)
      );
      verbose(`Group Created - missing or insufficient params, grouopId: ${group?.id}`);
      return;
    }

    const groupType = group.isPublic ? (group.isPublicApply ? 'selective' : 'open') : 'private';

    const params = {
      group_category: group.groupThematicType,
      group_type: groupType,
      group_id: group.id,
      group_name: group.name,
      group_link_id: group.publicUrlId,
    };

    this._mixpanelTrack('Group Created', params);
  }

  _contentTranslated(params) {
    if (!params) {
      Sentry.captureException(
        new Error(`Analytics: Content Translated - missing or insufficient params, id: ${params?.id}`)
      );
      verbose(`Content Translated - missing or insufficient params, id: ${params?.id}`);
      return;
    }

    let values = {
      word_count: params.word_count,
      character_count: params.character_count,
      context: params.context,
      content_type: params.applicationId ? 'group_public_apply_answers' : params.content_type,
      source_language: params.source_language,
      destination_language: params.destination_language,
      comments_count: params.comments_count,
    };

    if (['post', 'comment', 'reply'].includes(values.content_type)) {
      values.emoji_count = params.emoji_count;
    }

    this._mixpanelTrack('Content Translated', values);
  }

  _contentTranslationLimit(params) {
    if (!params) {
      Sentry.captureException(
        new Error(`Analytics: Translation limit reached - missing or insufficient params, id: ${params?.id}`)
      );
      verbose(`Translation limit reached - missing or insufficient params, id: ${params?.id}`);
      return;
    }

    this._mixpanelTrack('Translation limit reached', params);
  }

  _onboardingDialog(params) {
    if (!params) {
      Sentry.captureException(new Error(`Analytics: Onboarding Dialog - missing or insufficient params`));
      verbose(`Onboarding Dialog - missing or insufficient params`);
      return;
    }
    const values = {};

    if (params.name) {
      values.name = params.name;
    }

    if (params.selected_privacy) {
      values.selected_privacy = params.selected_privacy;
    }

    this._mixpanelTrack(params.type, values);
  }

  _notificationCenterOpened(params = {}) {
    if (isUndefined(params.has_unseen)) {
      Sentry.captureException(new Error(`Analytics: Notification Center Opened - missing or insufficient params`));
      verbose(`Notification Center Opened - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Notification Center Opened', params);
  }

  _notificationCenterNotificationClicked(params = {}) {
    if (!params.notification_type) {
      Sentry.captureException(
        new Error(`Analytics: Notification Center Notification Clicked - missing or insufficient params`)
      );
      verbose(`Notification Center Notification Clicked - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Notification Center Notification Clicked', params);
  }

  _amplicaWebpageLoaded(params = {}) {
    if (!params.trace_id) {
      Sentry.captureException(new Error(`Analytics: Amplica Webpage Loaded - missing or insufficient params`));
      verbose(`Amplica Webpage Loaded - missing or insufficient params`);
      return;
    }
    this._mixpanelTrack('Amplica Webpage Loaded', params);
  }

  _isUsingDarkTheme(params = {}) {
    if (isUndefined(params.isEnabled)) {
      Sentry.captureException(new Error(`Analytics: User is using dark theme - missing or insufficient params`));
      verbose(`User is using dark theme - missing or insufficient params`);
      return;
    }

    verbose(`MP people.set${params?.onLoad ? '_once' : ''}("is_using_dark_theme_web"): ${params.isEnabled}`);

    if (params?.onLoad) {
      Mixpanel.people.set_once('is_using_dark_theme_web', params.isEnabled);
    } else {
      Mixpanel.people.set('is_using_dark_theme_web', params.isEnabled);
    }
  }

  _learnMoreClicked(params = {}) {
    if (!params.location || !params.element) {
      Sentry.captureException(new Error(`Analytics: Learn More Clicked - missing or insufficient params`));
      verbose(`Learn More Clicked - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Learn More Clicked', params);
  }

  _openContentViewed(params = {}) {
    if (!params.content) {
      Sentry.captureException(new Error(`Analytics: Open Content Viewed - missing or insufficient params`));
      verbose(`Open Content Viewed - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Open Content Viewed', params);
  }

  _discoverCategoriesSelected(params = {}) {
    if (!params.categories?.length) {
      Sentry.captureException(new Error(`Analytics: Discover Categories Selected - missing or insufficient params`));
      verbose(`Discover Categories Selected - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Discover Categories Selected', params);
  }

  _custodialWalletFailed(params = {}) {
    // error_id can be "0" so we need to check if it's undefined
    if (isUndefined(params.error_id) || !params.trace_id) {
      Sentry.captureException(new Error(`Analytics: Custodial Wallet Failed - missing or insufficient params`));
      verbose(`Custodial Wallet Failed - missing or insufficient params`);
      return;
    }

    // sessionId is sent only in SMS flows
    // we want to send sessionId only if it's not empty
    if (isUndefined(params.session_id) || !params.session_id) {
      delete params.session_id;
    }

    // maxlength of mixpanel params is 255 bytes (255 characters)
    if (params.stack_trace?.length) {
      params.stack_trace = params.stack_trace.slice(0, 255);
    }

    this._mixpanelTrack('Custodial Wallet Failed', params);
  }

  _photoDownloaded(params = {}) {
    if (!params.location || typeof params?.is_owner === 'undefined') {
      Sentry.captureException(new Error(`Analytics: Photo Downloaded - missing or insufficient params`));
      verbose(`Photo Downloaded - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Photo Downloaded', params);
  }

  _typingInSearchStarted(params = {}) {
    if (!params.context) {
      Sentry.captureException(new Error(`Analytics: Typing in Search Started - missing or insufficient params`));
      verbose(`Typing in Search Started - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Typing in Search Started', params);
  }

  _storiesWatched(params = {}) {
    if (!params.context) {
      Sentry.captureException(new Error(`Analytics: Stories Watched - missing or insufficient params`));
      verbose(`Stories Watched - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Stories Watched', params);
  }

  _logInIdentifierEntered(params = {}) {
    if (!params?.login_type) {
      Sentry.captureException(new Error(`Analytics: Log In Identifier Entered - missing or insufficient params`));
      verbose(`Log In Identifier Entered - missing or insufficient params`);
      return;
    }

    if (!params.is_web3_migration) {
      params.is_web3_migration = false;
    }

    this._mixpanelTrack('Log In Identifier Entered', params);
  }

  _loggedIn(params = {}) {
    if (!params?.login_type) {
      Sentry.captureException(new Error(`Analytics: Logged In - missing login_type`));
      verbose(`Log In - missing login_type`);
      return;
    }

    if (!params.is_web3_migration) {
      params.is_web3_migration = false;
    }

    this._mixpanelTrack('Logged In', params);
  }

  _loggedOut(params = {}) {
    params.is_automatic = false; // currently hardcoded and we track only manual logout

    this._mixpanelTrack('Logged Out', params);
  }

  _campaignSucceeded(params = {}) {
    if (!params.campaign_id || !params.campaign_type) {
      Sentry.captureException(new Error(`Analytics: Campaign Succeeded - missing or insufficient params`));
      verbose(`Campaign Succeeded - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Campaign Succeeded', params);
  }

  _premiumScreenViewed(params = {}) {
    if (!params.context) {
      Sentry.captureException(new Error(`Analytics: Premium Screen Viewed - missing or insufficient params`));
      verbose(`Premium Screen Viewed - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Premium Screen Viewed', params);
  }

  _postViewed(params = {}) {
    if (!params.post_id) {
      Sentry.captureException(new Error(`Analytics: Post Viewed - missing or insufficient params`));
      verbose(`Post Viewed - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Post Viewed', params);
  }

  _bookmarkedPostOpened(params = {}) {
    if (!params.post_id) {
      Sentry.captureException(new Error(`Analytics: Bookmarked Post Opened - missing or insufficient params`));
      verbose(`Bookmarked Post Opened - missing or insufficient params`);
      return;
    }

    this._mixpanelTrack('Bookmarked Post Opened', params);
  }

  // standalone function for tracking Sessions //
  trackSession() {
    const verbose = Verbose({ prefix: '[Session Timer]', color: 'darkorange', enabled: true }).log;

    const sessionsThreshold = 30 * 60 * 1000; // min. time between sessions: 30min
    const sessionLength = 10 * 1000; // min. focus time to track session: 10s

    let sessionTimerId, thresholdTimerId;

    const getLastSessionEventTs = () => {
      let lastSessionEventTs = 0;

      try {
        // sessionData: { lastSessionEventTs: number }
        const sessionData = JSON.parse(Storage.get(Storage.keys.sessionData));

        if (sessionData && isNumber(sessionData.lastSessionEventTs)) {
          lastSessionEventTs = sessionData.lastSessionEventTs;
        }
      } catch (e) {}

      return lastSessionEventTs;
    };

    const sendSessionEvent = () => {
      Storage.set(
        Storage.keys.sessionData,
        JSON.stringify({
          lastSessionEventTs: Date.now(),
        })
      );

      verbose(`🏁 SESSION COMPLETED: ${new Date().toTimeString().slice(0, 8)}`);
      sessionTimerId = null;

      this.sendEvent('webSession');

      startThresholdTimer();
    };

    const startSessionTimer = () => {
      window.clearTimeout(sessionTimerId);
      sessionTimerId = window.setTimeout(sendSessionEvent, sessionLength);

      verbose(`⏱ START SESSION TIMER (10s): ${new Date().toTimeString().slice(0, 8)}`);
    };

    const resetSessionTimer = () => {
      if (sessionTimerId) {
        window.clearTimeout(sessionTimerId);
        sessionTimerId = null;
      }

      verbose(`🚫 PAGE UNFOCUSED: ${new Date().toTimeString().slice(0, 8)}`);
    };

    const startThresholdTimer = () => {
      const lastSessionEventTs = getLastSessionEventTs();
      const thresholdTimeLeft = sessionsThreshold - (Date.now() - lastSessionEventTs);

      window.clearTimeout(thresholdTimerId);

      thresholdTimerId = window.setTimeout(updateTimer, thresholdTimeLeft);

      verbose(
        `🐌 THRESHOLD TIME LEFT (${Math.round(thresholdTimeLeft / 1000)}s): ${new Date().toTimeString().slice(0, 8)}`
      );
    };

    const updateTimer = () => {
      const lastSessionEventTs = getLastSessionEventTs();

      // if tab is not focused then reset session timer
      if (document.hidden) {
        resetSessionTimer();
      }
      // if tab is focused then start session timer
      else {
        // session event was sent already before
        if (lastSessionEventTs) {
          // minimum time between session events passed, start session timer immediately
          if (Date.now() - lastSessionEventTs > sessionsThreshold) {
            startSessionTimer();
          }
          // wait until minimum time between session will pass to start session timer
          else {
            startThresholdTimer();
          }
        }
        // no sessions before, start session timer
        else {
          startSessionTimer();
        }
      }
    };

    document.addEventListener('visibilitychange', updateTimer);

    updateTimer();
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { stubTrue } from 'lodash';
import { next, scheduleOnce } from '@ember/runloop';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { postsPerPage, maxPostsForFTUE, FeedTypes, Theme } from 'mewe/constants';
import { checkOptions } from 'mewe/utils/component-utils';
import dispatcher from 'mewe/dispatcher';

const initialRenderPostsCount = 3;

const render = (eItem) => eItem && stubTrue(eItem.set('placeholder', false));

const placeholder = (eItem) => eItem && eItem.set('placeholder', true);

export default class FeedSimple extends Component {
  options = {
    mandatory: ['feed', 'theme'],
    optional: [
      'isPublicContent',
      'featuredFirst',
      'feedInHome',
      'eventData',
      'group',
      'groupId',
      'page',
      'getScrollContainer',
      'openHashTagFromTop',
      'isWrappersDialog',
      'tag',
      'contact',
      'classNames',
    ],
  };

  @service router;
  @service account;
  @service dynamicDialogs;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);
    checkOptions(this.args.classNames || [], this.options || [], this.attrs);
  }

  @action
  onInsert() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.renderNextOrEnd();
    });

    this.setupInfiniteScroll();
    this.setupPendingPostsCheck();

    this.postsLengthObserverBind = this.postsLengthObserver.bind(this);
    addObserver(this, 'feed.posts.length', this.postsLengthObserverBind);
    this.initialRender();

    this.refreshPostfeedBind = this.refreshPostfeed.bind(this);
    PS.Sub('refresh.postfeed', this.refreshPostfeedBind);
  }

  @action
  onDestroy() {
    if (typeof this.args.getScrollContainer === 'function') {
      this.scrolling.unbindScrollDown(this.args.getScrollContainer());
    } else {
      this.scrolling.unbindScrollDown();
    }

    this.scrollingObserved.forEach((field) => removeObserver(this, field, this.infiniteScrollDownWrapped));
    this.pendingPostsObserved.forEach((field) => removeObserver(this, field, this.pendingPostsCheckWrapped));

    removeObserver(this, 'feed.posts.length', this.postsLengthObserverBind);

    PS.Unsub('refresh.postfeed', this.refreshPostfeedBind);
  }

  isCommentAction(target) {
    return (
      target.closest('.voice_buttons') ||
      target.closest('.comment-your_btn-cancel') ||
      target.closest('.comment-your_btn') ||
      target.closest('.comment_actions-left') ||
      target.closest('.comment-your_right') ||
      target.closest('.comment_actions-right') ||
      target.closest('.comment_header_settings_button') ||
      target.closest('.comment-replay') ||
      target.closest('.comment_reply') ||
      target.closest('.comment-delete-option') ||
      target.closest('.comment-replay-options') ||
      target.closest('.edit-form_buttons') ||
      target.closest('.c-mw-edit-form')
    );
  }

  @action
  openPostInDialog(post, event) {
    if (this.isCommentAction(event.target)) return;
    let scope;
    if (post.groupId) {
      scope = Theme.GROUP;
    } else if (post.pageId) {
      scope = Theme.PAGE;
    } else if (post.eventId) {
      scope = Theme.EVENT;
    } else {
      scope = Theme.HOME;
    }
    this.dynamicDialogs.openDialog('single-post-dialog', {
      post: post,
      commentId: post.comments.feed.find((c) => c.forSnippet)?.id,
      forSnippet: true,
      scope,
    });
  }

  get feed() {
    return this.args.feed;
  }

  get rendered() {
    return this.feed.posts.filterBy('placeholder', false);
  }

  get placeholders() {
    return this.feed.posts.filterBy('placeholder', true);
  }

  get isFailover() {
    return this.feed?.safeMode && !this.feed?.posts?.length;
  }

  get feedRoute() {
    if (this.args.group?.id) {
      return { name: 'app.group.index.feed', id: this.args.group.id };
    } else if (this.args.eventData?.id) {
      return { name: 'app.event.feed', id: this.args.eventData.id };
    }
  }

  get feedPendingRoute() {
    if (this.args.group?.id) {
      return { name: 'app.group.index.feed-pending', id: this.args.group.id };
    } else if (this.args.eventData?.id) {
      return { name: 'app.event.feed-pending', id: this.args.eventData.id };
    }
  }

  get sortedFeed() {
    // Doesn't need to be sorted, should order by the backend
    if (this.args.theme === 'threads' || this.args.theme === 'discover') {
      // When do pagination for threads, the BE wouldn't know previous page posts, possible to get the same post twice
      return this.feed.posts.uniqBy('id');
    }

    return this.args.featuredFirst ? this.feed.sortedPostsFeaturedFirst : this.feed.sortedPosts;
  }

  get hasPendingPosts() {
    const F = FeedTypes;

    if ([F.GROUP_CONTACT, F.EVENT_CONTACT, F.DISCOVER].indexOf(this.feed.type) > -1) return false;
    if (this.args.tag) return false;

    if (this.args.group?.id) {
      return get(this, 'args.group.hasPendingPosts');
    } else if (this.args.eventData?.id) {
      return get(this, 'args.eventData.hasPendingPosts');
    }
  }

  get emptyFeedText() {
    const theme = this.args.theme;

    if (theme === Theme.GROUPS) {
      return __("You don't have any posts in your groups yet");
    } else if (theme === Theme.PAGES) {
      return __("You don't have any posts in your pages yet");
    } else if (this.args.contact && !this.args.contact?.isMe) {
      return __('{name} has not posted yet', { name: this.args.contact.firstName });
    }
  }

  setupInfiniteScroll() {
    // reference those fields as `args.feed` because observing simply `feed` doesn't work as expected
    this.scrollingObserved = ['args.feed.posts.length', 'args.feed.nextPage', 'args.feed.id'];
    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.infiniteScrollDownWrapped = () => scheduleOnce('afterRender', this, this.infiniteScrollDown);
    this.scrollingObserved.forEach((property) => addObserver(this, property, this.infiniteScrollDownWrapped));
    this.infiniteScrollDown();
  }

  setupPendingPostsCheck() {
    // reference those fields as `args.feed` because observing simply `feed` doesn't work as expected
    this.pendingPostsObserved = ['args.feed.posts.length', 'args.feed.isFetching'];
    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.pendingPostsCheckWrapped = () => scheduleOnce('afterRender', this, this.pendingPostsCheck);
    this.pendingPostsObserved.forEach((property) => addObserver(this, property, this.pendingPostsCheckWrapped));
    this.pendingPostsCheck();
  }

  infiniteScrollDown() {
    const loadFn = () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.loadMorePosts();
    };

    if (typeof this.args.getScrollContainer === 'function') {
      this.scrolling.bindScrollDownElement(this.args.getScrollContainer(), loadFn, 1200);
    } else {
      this.scrolling.bindScrollDown(() => loadFn(), 1200);
    }
  }

  // if route with pending feed was opened and fetching feed didn't return any posts then redirect to all posts feed and hide "all/pending" tabs
  // same if last post in pending feed will be removed/rejected/approved, don't stay in empty pending feed but redirect
  pendingPostsCheck() {
    // feed might not be passed yet during initialization (SG-32430)
    if (!this.feed) return;

    if (
      (this.feed.type == FeedTypes.GROUP_PENDING || this.feed.type == FeedTypes.EVENT_PENDING) &&
      this.feed.posts.length === 0 &&
      !this.feed.isFetching
    ) {
      if (this.feed.nextPage) {
        dispatcher.dispatch('feed', 'loadMore', this.feed);
      } else if (this.args.group?.id) {
        this.args.group.set('hasPendingPosts', false);
        this.router.transitionTo('app.group.index.feed');
      } else {
        this.args.eventData.set('hasPendingPosts', false);
        this.router.transitionTo('app.event.feed');
      }
    }
  }

  sliceMap(from, to, fun) {
    return this.feed.posts.slice(from, to).map(fun);
  }

  initialRender() {
    const length = this.feed.posts.length;
    if (length <= postsPerPage) {
      this.sliceMap(0, initialRenderPostsCount, render);
      this.sliceMap(initialRenderPostsCount, length, placeholder);
    }
  }

  postsLengthObserver() {
    const postsLength = this.feed.posts.length;
    const lastPostAboveFoldWasRemoved =
      postsLength === maxPostsForFTUE - 1 && this.previousPostsLength === maxPostsForFTUE;

    this.feed.posts.map((post, index, posts) => (post.scheduled ? posts.removeAt(index) : null));

    this.previousPostsLength = postsLength;

    if (postsLength && postsLength < postsPerPage && this.feed.canShowMore) {
      // SG-24048 - there is another page to load without scrolling
      dispatcher.dispatch('feed', 'loadMore', this.feed);
    } else if (lastPostAboveFoldWasRemoved) {
      // try to fetch more if user removes a bunch of posts from top of feed
      dispatcher.dispatch('feed', 'loadMore', this.feed);
    } else {
      this.renderNextOrEnd();
    }
  }

  refreshPostfeed() {
    dispatcher.dispatch('feed', 'refreshPostfeed', this.feed);
  }

  renderNextOrEnd() {
    return this.rendered.length < this.feed.posts.length
      ? next(this, this.renderFirstFromPlaceholders)
      : this.afterAllRendered();
  }

  renderFirstFromPlaceholders() {
    return (
      !this.isDestroyed &&
      !this.isDestroying &&
      this.placeholders.length &&
      render(this.placeholders[0]) &&
      this.renderNextOrEnd()
    );
  }

  // posts are rendered each in different run loop, this is called in the end
  afterAllRendered() {
    // pre-rendering emoji picker, rendering of it is heavy and takes a lot of time (on 5x slowdown it takes 5s) so move to the very last
    // EmojiPicker(getOwner(this));//
    this.sliceMap(0, this.feed.posts.length, render);
  }

  @action
  loadMorePosts() {
    if (this.feed?.canShowMore && !this.feed?.isFetching && !this.feed?.safeMode) {
      dispatcher.dispatch('feed', 'loadMore', this.feed);
    }
  }

  @action
  openHashTagFromTop(tag, scopeId, post) {
    if (this.args.openHashTagFromTop) {
      this.args.openHashTagFromTop(tag, scopeId, post);
    } else {
      this.router.transitionTo({ queryParams: { tag: tag || null } });
    }
  }
}

import Component from '@glimmer/component';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import ChatApi from 'mewe/api/chat-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { inject as service } from '@ember/service';

export default class MwPrivacymailReceivers extends Component {
  @service router;

  scrolling = Scrolling();

  maxResults = 20;

  @tracked offset = 0;
  @tracked receivers = A();
  @tracked receiversDropdownOpened;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('ul'));
  }

  loadReceivers() {
    const params = {
      offset: this.offset,
      maxResults: this.maxResults,
    };

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      if (!data.receivers || !data.receivers.length) {
        this.scrolling.unbindScrollDown(this.element.querySelector('ul'));
        return;
      }

      this.receivers.pushObjects(data.receivers);
      this.offset += data.receivers.length;

      later(this, () => {
        if (this.isDestroying || this.isDestroyed) return;
        if (data.receivers.length) {
          this.scrolling.bindScrollDownElement(this.element.querySelector('ul'), () => {
            this.loadReceivers();
          });
        }
      });
    };

    ChatApi.getReceivers(this.args.post.threadId, params)
      .then(callback)
      .catch((resp) => {
        if (resp && resp.status) {
          FunctionalUtils.showDefaultErrorMessage();
        }
      });
  }

  @action
  openProfile(user) {
    this.router.transitionTo('app.publicid', user.publicLinkId);
  }

  @action
  openReceiversDropdown() {
    this.offset = 0;
    this.receivers = A();

    this.loadReceivers();
    this.receiversDropdownOpened = true;
  }

  @action
  closeDropdown() {
    this.receiversDropdownOpened = false;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwPostPhotoFour extends Component {
  get firstElement() {
    return this.args.post.previewImages?.[0];
  }

  get middleElements() {
    if (this.args.post.mediasCount > 4) {
      return this.args.post.previewImages.slice(1, 3); // remove first and >3 elements, keep second and third
    } else {
      return this.args.post.previewImages.slice(1); // remove first element
    }
  }

  get lastElement() {
    if (this.args.post.mediasCount > 4) {
      return this.args.post.previewImages[this.args.post.previewImages.length - 1];
    }
    return undefined;
  }

  @action
  openMediaDialog(image) {
    this.args.openMediaDialog(this.args.post, image);
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwPostPhotoThree extends Component {
  get firstElement() {
    return this.args.post.previewImages[0];
  }

  get lastElements() {
    return this.args.post.previewImages.slice(1);
  }

  @action
  openMediaDialog(image) {
    this.args.openMediaDialog(this.args.post, image);
  }
}

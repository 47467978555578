import { Theme } from 'mewe/constants';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { getFeed } from 'mewe/fetchers/fetch-feed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

import { action, set } from '@ember/object';

import PostComponent from '../../posts/mw-post';

export default class MwChatPost extends PostComponent {
  @service dynamicDialogs;
  @service dynamicPopups;

  @tracked post = this.args.post;
  @tracked showOptionsPopup = false;
  @tracked dropdownId = '';
  @tracked parent = null;
  @tracked truncate;

  profilePopupSelector = '.post_text .h-mention';

  constructor() {
    super(...arguments);

    if (this.post.comments?.feed?.length === 0 || this.args.isChatMedia) {
      this.didOpenComments = true;
    }

    if (!this.post.threadId && this.args.scope === Theme.PRIVATEPOSTS) {
      // used later for post scope/scopeId identification as backend doesn't provide threadId
      set(this, 'post.threadId', this.args.threadId);
    }

    next(() => {
      // chat post needs to be in ds store to be possible to find it later for updates
      getFeed('chat-posts', 'chat-posts').posts.pushObject(this.post);
    });
  }

  @action
  onInsert(element) {
    this.element = element;
    super.onInsert(...arguments);
  }

  willDestroy() {
    getFeed('chat-posts', 'chat-posts').posts.removeObject(this.post);
  }

  get maxOptionLengthForNoTrim() {
    return this.args.chatInfoActive ? 10 : 20;
  }

  @action
  showProfilePopup(target) {
    if (!this.isDestroyed && !this.isDestroying) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: target,
        owner: { id: target.getAttribute('data-userid') },
        group: this.post.group,
        isMention: true,
      });
    }
  }

  @action
  openOptionsPopup() {
    const className = '.dropdown-opener--chat-post-options-dropdown';
    const dropdownId = 'chat-post-options-popup';
    const dropdownElement = document.getElementsByClassName('chat-post-options-dropdown');

    if (dropdownElement.length > 0) {
      if (dropdownId === dropdownElement[0].getAttribute('data-dropdownid')) {
        return;
      }
    }

    this.parent = this.element.querySelector(className);
    this.dropdownId = dropdownId;
    this.showOptionsPopup = true;
  }

  @action
  closeOptionsPopup() {
    this.parent = null;
    this.dropdownId = '';
    this.showOptionsPopup = false;
  }

  @action
  setFeatured(doFeature) {
    this.post.set('featured', doFeature);
  }

  @action
  repostTo(target) {
    openPostbox({ target: target, postToRepost: this.post, theme: 'privacymail' }, this.dynamicDialogs);
  }

  @action
  shareTo(target) {
    openPostbox(
      { target: target, postToShare: this.post.refPost || this.post, theme: 'privacymail' },
      this.dynamicDialogs
    );
  }

  @action
  setTruncate(value) {
    this.truncate = value;
  }
}

import { each } from 'lodash';
import Component from '@glimmer/component';

import PS from 'mewe/utils/pubsub';
import { bufferToMp3 } from 'mewe/workers/encode-mp3-worker';
import { getDurationTimeFromSeconds, recordingAnimationApi } from 'mewe/utils/audio-utils';
import { isNumber } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import MathUtils from 'mewe/utils/math-utils';

export default class MwVideoPlayer extends Component {
  @tracked isPlaying = false;
  @tracked voiceRecordingVisible = false;
  @tracked isAudioRecording = false;
  @tracked isAudioConverting = false;
  @tracked audioRecordingUrl = null;
  @tracked audioRecordingBlob = null;
  @tracked mediaRecorder = null;
  @tracked recordTime = 0;

  voiceId = MathUtils.generateId();

  constructor() {
    super(...arguments);
    this.recordingAnimationFrames = 60;
  }

  get setParentAttr() {
    return this.args.setParentAttr;
  }

  @action
  onInsert() {
    this.setDefaultProperties();
    addObserver(this, 'isPlaying', this.isPlayingObserver);
  }

  @action
  destroyComponent() {
    removeObserver(this, 'isPlaying', this.isPlayingObserver);
    this.stopAudioRec();
    this.clearRecordTimeInterval();
    this.clearAnimationInterval();
    this.setDefaultProperties();
    this.setParentAttr?.('isAudioRecording', false);
    this.setParentAttr?.('isAudioConverting', false);
    this.setParentAttr?.('audioRecordingUrl', null);
    this.setParentAttr?.('audioRecordingBlob', null);
  }

  setDefaultProperties = () => {
    this.recordedChunks = [];
    this.mediaRecorder = null;
    this.audioSettings = {
      volumeChanging: false,
      playPause: false,
    };
    this.isPlaying = false;
    this.isAudioRecording = false;
    this.isAudioConverting = false;
    this.audioRecordingUrl = null;
    this.audioRecordingBlob = null;
  };

  isPlayingObserver() {
    if (this.isPlaying) this.setAnimationInterval();
    else this.clearAnimationInterval();
  }

  startAudioRec() {
    const _this = this;

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      const recordedChunks = _this.recordedChunks;
      const mediaRecorder = new MediaRecorder(stream, { mimeType: _this.mimeType });

      _this.setRecordTimeInterval();
      _this.setAnimationInterval();

      _this.isAudioRecording = true;
      this.setParentAttr?.('isAudioRecording', true);
      _this.mimeType = _this.getSupportedMimeTypes(0);

      mediaRecorder.start();

      mediaRecorder.ondataavailable = function (e) {
        if (_this.isDestroyed || _this.isDestroying) return;

        if (e.data.size > 0) {
          recordedChunks.push(e.data);
          _this.recordedChunks = recordedChunks;
        }
      };
      mediaRecorder.onstop = function (e) {
        if (_this.isDestroyed || _this.isDestroying) return;

        // stop stream to clean recording icon in browser
        stream.getTracks().forEach((track) => track.stop());

        if (recordedChunks && recordedChunks.length > 0) {
          let blob = new Blob(_this.recordedChunks, {
            type: _this.mimeType,
          });

          const audioContext = new AudioContext();
          const fileReader = new FileReader();

          // Set up file reader on loaded end event
          fileReader.onloadend = () => {
            const arrayBuffer = fileReader.result;
            audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
              const MP3Blob = bufferToMp3(audioBuffer);

              _this.audioRecordingUrl = URL.createObjectURL(MP3Blob);
              _this.audioRecorderInput = null;
              _this.audioRecordingBlob = MP3Blob;
              _this.setParentAttr?.('audioRecordingBlob', MP3Blob);
              _this.isAudioConverting = false;
              _this.setParentAttr?.('isAudioConverting', false);
            });
          };
          fileReader.readAsArrayBuffer(blob);
        }
      };

      _this.mediaRecorder = mediaRecorder;
    });
  }

  getSupportedMimeTypes(fifo = false) {
    const types = ['webm', 'ogg', 'mp3', 'x-matroska'];
    const codecs = [
      'vp9',
      'vp9.0',
      'vp8',
      'vp8.0',
      'avc1',
      'av1',
      'h265',
      'h.265',
      'h264',
      'h.264',
      'opus',
      'pcm',
      'aac',
      'mpeg',
      'mp4a',
    ];
    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];

    types.forEach((type) => {
      const mimeType = `audio/${type}`;
      codecs.forEach((codec) =>
        [
          `${mimeType};codecs=${codec}`,
          `${mimeType};codecs:${codec}`,
          `${mimeType};codecs=${codec.toUpperCase()}`,
          `${mimeType};codecs:${codec.toUpperCase()}`,
        ].forEach((variation) => {
          if (isSupported(variation)) supported.push(variation);
        })
      );
      if (isSupported(mimeType)) supported.push(mimeType);
    });

    return isNumber(fifo) ? supported[fifo] : supported;
  }

  stopAudioRec() {
    this.clearRecordTimeInterval();
    this.clearAnimationInterval();

    const mediaRecorder = this.mediaRecorder;

    if (mediaRecorder) {
      if (mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }

      this.isAudioConverting = true;
      this.setParentAttr?.('isAudioConverting', true);
      this.mediaRecorder = mediaRecorder;
    }

    this.isAudioRecording = false;
    this.setParentAttr?.('isAudioRecording', false);
  }

  get audioObj() {
    return {
      name: this.audioRecordingBlob?.name,
      urlProvided: this.audioRecordingUrl,
    };
  }

  get audioUploaded() {
    return this.args.audioUploaded ?? true;
  }

  get recordTimeDisplay() {
    return getDurationTimeFromSeconds(this.recordTime);
  }

  get showLoader() {
    return this.isAudioConverting || (this.audioRecordingBlob && !this.audioUploaded);
  }

  get showRecordingAnimation() {
    return this.isAudioRecording || this.isPlaying;
  }

  processRecordingAnimation() {
    each(this.recordingAnimation, (pathObject) => {
      each(pathObject.path, (obj) => {
        if (obj.grow) {
          obj.incrementProperty('value', pathObject.v);
        } else {
          obj.decrementProperty('value', pathObject.v);
        }

        if (obj.get('value') >= pathObject.max) obj.set('grow', false);
        if (obj.get('value') <= pathObject.min) obj.set('grow', true);
      });
    });
  }

  setAnimationInterval() {
    this.recordingAnimation = recordingAnimationApi;

    setTimeout(() => {
      if (this.isDestroyed || this.isDestroying) return;

      this.animationInterval = window.setInterval(() => {
        if (this.isDestroyed || this.isDestroying) return;

        this.processRecordingAnimation();
      }, this.recordingAnimationFrames);
    }, 0);
  }

  setRecordTimeInterval() {
    this.recordTimeInterval = window.setInterval(() => {
      if (this.isDestroyed || this.isDestroying) return;
      this.recordTime++;
    }, 1000);
  }

  clearAnimationInterval() {
    window.clearInterval(this.animationInterval);
  }

  clearRecordTimeInterval() {
    window.clearInterval(this.recordTimeInterval);
  }

  @action
  toggleAudioRecording() {
    if (this.isAudioRecording) this.stopAudioRec();
    else this.startAudioRec();
  }

  @action
  deleteAudioRecording() {
    this.isPlaying = false;
    this.audioRecordingUrl = null;
    this.audioRecordingBlob = null;
    this.setParentAttr?.('audioRecordingBlob', null);
    this.recordedChunks = [];
    this.voiceRecordingVisible = false;
    this.setParentAttr?.('voiceRecordingVisible', false);
    this.recordTime = 0;
  }

  @action
  toggleAudioPlaying() {
    if (this.isPlaying) PS.Pub(`audio.pause.${this.voiceId}`);
    else PS.Pub(`audio.play.${this.voiceId}`);
  }

  @action
  sendMessage() {
    if (this.args.elementId) {
      PS.Pub(`chat.message.send.${this.args.elementId}`);
    }
  }

  @action
  setIsPlaying(value) {
    this.isPlaying = value;
  }
}

import { assert } from '@ember/debug';
import ApiUtil from './api';
import { Theme } from 'mewe/constants';

const apiVersion = (scope) => (scope.split('/').indexOf('group') > -1 ? 'apiRoot3' : null);

/**
 * import PostApi from 'mewe/api/post-api';
 */
class Api extends ApiUtil {
  editPost(params, postId, state) {
    let groupOrEventId, groupOrEventScope, apiRoot;
    const scheduledPath = function () {
      if (params.schedule) {
        delete params.schedule;
        return '/scheduled/';
      }
      return '/';
    };

    if (state.scope == 'event' || state.scope == 'group' || state.groupId || state.eventId) {
      if (state.groupId || state.scope == 'group') {
        groupOrEventId = state.groupId || state.scopeId;
        groupOrEventScope = 'group';
        apiRoot = 'apiRoot3';
      } else {
        groupOrEventId = state.eventId || state.scopeId;
        groupOrEventScope = 'event';
      }

      if (state.pending) {
        if (state.multiPartId) {
          return this.put(
            `/${groupOrEventScope}/${groupOrEventId}/pending/post/${postId}/edit/${state.multiPartId}`,
            {
              data: JSON.stringify(params),
            },
            apiRoot
          );
        } else {
          return this.put(
            `/${groupOrEventScope}/${groupOrEventId}/pending/post/${postId}/edit`,
            {
              data: JSON.stringify(params),
            },
            apiRoot
          );
        }
      } else {
        return this.put(
          `/${groupOrEventScope}/${groupOrEventId}${scheduledPath()}post/${postId}/edit`,
          {
            data: JSON.stringify(params),
          },
          apiRoot
        );
      }
    } else if (params.threadId) {
      return this.put(`/privateposts/${params.threadId}/post/${postId}/edit`, {
        data: JSON.stringify(params),
      });
    } else if (state.scope == 'page') {
      return this.put(`/pages/page/${state.scopeId}${scheduledPath()}post/${postId}/edit`, {
        data: JSON.stringify(params),
      });
    } else {
      return this.put(`/home${scheduledPath()}post/${postId}/edit`, {
        data: JSON.stringify(params),
      });
    }
  }

  addPost({ params, scope }) {
    assert('Missing params in addPost', params);
    assert('Missing scope in addPost', scope);

    const apiVersion = ['group', 'mycloud'].indexOf(scope.split('/')[0]) > -1 ? 'apiRoot3' : null;
    let path = `/${scope}/post`;

    return this.post(
      path,
      {
        data: JSON.stringify(params),
      },
      apiVersion
    );
  }

  deletePost(postItemId, scope, scopeId, scheduled) {
    const apiRoot = scope === Theme.GROUP ? 'apiRoot3' : 'apiRoot2';
    const scheduledPath = scheduled ? '/scheduled/' : '/';
    if (scope === Theme.PRIVATEPOSTS) scope = 'privateposts';

    if (scope === Theme.CONTACTS) {
      return this.del(`/home${scheduledPath}post/${postItemId}`);
    } else {
      if (scope === 'page') {
        scope = 'pages/page';
      }

      return this.del(`/${scope}/${scopeId}${scheduledPath}post/${postItemId}`, {}, apiRoot);
    }
  }

  approvePost(postItemId, scope, scopeId) {
    if (scope === Theme.GROUP) {
      return this.post(`/group/${scopeId}/pending/post/${postItemId}/approve`, {}, 'apiRoot3');
    } else if (scope === Theme.EVENT) {
      return this.post(`/event/${scopeId}/pending/post/${postItemId}/approve`);
    }
  }

  rejectPost(postItemId, scope, scopeId) {
    if (scope === Theme.GROUP) {
      return this.del(`/group/${scopeId}/pending/post/${postItemId}`, {}, 'apiRoot3');
    } else if (scope === Theme.EVENT) {
      return this.del(`/event/${scopeId}/pending/post/${postItemId}`);
    }
  }

  releasePost(postId, scopePath) {
    assert('Missing postId in releasePost method', postId);
    assert('Missing scopePath in releasePost method', scopePath);

    const apiRoot = ~scopePath.indexOf('group') ? 'apiRoot3' : '';

    return this.post(`/${scopePath}/scheduled/post/${postId}/release`, {}, apiRoot);
  }

  featurePost({ postId, contacts, groupId, eventId, pageId }) {
    if (contacts) return this.post('/home/post/' + postId + '/feature');
    else if (pageId) return this.post('/pages/page/' + pageId + '/post/' + postId + '/feature');
    else if (groupId) return this.post('/group/' + groupId + '/post/' + postId + '/feature', null, 'apiRoot3');
    else if (eventId) return this.post('/event/' + eventId + '/post/' + postId + '/feature');
  }

  unfeaturePost({ postId, contacts, groupId, eventId, pageId }) {
    if (contacts) return this.del('/home/post/' + postId + '/feature');
    else if (pageId) return this.del('/pages/page/' + pageId + '/post/' + postId + '/feature');
    else if (groupId) return this.del('/group/' + groupId + '/post/' + postId + '/feature', null, 'apiRoot3');
    else if (eventId) return this.del('/event/' + eventId + '/post/' + postId + '/feature');
  }

  postEmoji(options, emoji) {
    if (!options || !options.model) return;

    switch (options.scope) {
      case Theme.PRIVATEPOSTS:
        return this.post(`/privateposts/${options.model.threadId}/post/${options.model.postItemId}/emojis`, {
          data: JSON.stringify([emoji]),
        });
      case Theme.GROUP:
      case Theme.GROUPS:
        return this.post(
          `/group/${options.model.groupId}/post/${options.model.postItemId}/emojis`,
          { data: JSON.stringify([emoji]) },
          'apiRoot3'
        );
      case Theme.EVENT:
        return this.post(`/event/${options.model.eventId}/post/${options.model.postItemId}/emojis`, {
          data: JSON.stringify([emoji]),
        });
      case Theme.PAGE:
        return this.post(`/pages/page/${options.model.pageId}/post/${options.model.postItemId}/emojis`, {
          data: JSON.stringify([emoji]),
        });
      default:
        return this.post(`/home/post/${options.model.postItemId}/emojis`, {
          data: JSON.stringify([emoji]),
        });
    }
  }

  deleteEmoji(options, params) {
    if (!options || !options.model) return;

    params.emojis = encodeURIComponent(params.emojis);

    switch (options.scope) {
      case Theme.PRIVATEPOSTS:
        return this.del(
          `/privateposts/${options.model.threadId}/post/${options.model.postItemId}/emojis?emojis=${params.emojis}`
        );
      case Theme.GROUP:
      case Theme.GROUPS:
        return this.del(
          `/group/${options.model.groupId}/post/${options.model.postItemId}/emojis?emojis=${params.emojis}`,
          {},
          'apiRoot3'
        );
      case Theme.EVENT:
        return this.del(
          `/event/${options.model.eventId}/post/${options.model.postItemId}/emojis?emojis=${params.emojis}`
        );
      case Theme.PAGE:
        return this.del(
          `/pages/page/${options.model.pageId}/post/${options.model.postItemId}/emojis?emojis=${params.emojis}`
        );
      default:
        return this.del(`/home/post/${options.model.postItemId}/emojis?emojis=${params.emojis}`);
    }
  }

  getEmoji(options, params) {
    if (!options || !options.model) return;

    if (params.nextPage) {
      return this.get(params.nextPage);
    }

    const headers = { data: params };

    switch (options.scope) {
      case Theme.PRIVATEPOSTS:
        return this.get(`/privateposts/${options.model.threadId}/post/${options.model.postItemId}/emoji`, headers);
      case Theme.GROUP:
      case Theme.GROUPS:
        return this.get(`/group/${options.model.groupId}/post/${options.model.postItemId}/emoji`, headers, 'apiRoot3');
      case Theme.EVENT:
        return this.get(`/event/${options.model.eventId}/post/${options.model.postItemId}/emoji`, headers);
      case Theme.PAGE:
        return this.get(`/pages/page/${options.model.pageId}/post/${options.model.postItemId}/emoji`, headers);
      default:
        return this.get(`/home/post/${options.model.postItemId}/emoji`, headers);
    }
  }

  getAllEmojis(options, params) {
    if (!options || !options.model) return;

    if (params.nextPage) {
      return this.get(params.nextPage);
    }

    const headers = { data: params };

    switch (options.scope) {
      case Theme.PRIVATEPOSTS:
        return this.get(`/privateposts/${options.model.threadId}/post/${options.model.postItemId}/emojis`, headers);
      case Theme.GROUP:
      case Theme.GROUPS:
        return this.get(`/group/${options.model.groupId}/post/${options.model.postItemId}/emojis`, headers, 'apiRoot3');
      case Theme.EVENT:
        return this.get(`/event/${options.model.eventId}/post/${options.model.postItemId}/emojis`, headers);
      case Theme.PAGE:
        return this.get(`/pages/page/${options.model.pageId}/post/${options.model.postItemId}/emojis`, headers);
      default:
        return this.get(`/home/post/${options.model.postItemId}/emojis`, headers);
    }
  }

  getHashTags(params, scope, scopeId) {
    const headers = { data: params };

    switch (scope) {
      case Theme.GROUP:
        return this.get(`/group/${scopeId}/post/hashtags`, headers, 'apiRoot3');
      case Theme.PAGE:
        return this.get(`/pages/page/${scopeId}/post/hashtags`, headers);
      case Theme.EVENT:
        return this.get(`/event/${scopeId}/post/hashtags`, headers);
      default:
        return this.get(`/home/post/hashtags`, headers);
    }
  }

  getMultiPhoto(scope, params) {
    switch (scope) {
      case Theme.PRIVATEPOSTS:
        return this.get(`/privateposts/${params.threadId}/post/${params.postItemId}/multipost/medias`);
      case Theme.GROUP:
      case Theme.GROUPS:
        return this.get(`/group/${params.groupId}/post/${params.postItemId}/multipost/medias`, {}, 'apiRoot3');
      case Theme.PAGE:
        return this.get(`/pages/page/${params.pageId}/post/${params.postItemId}/multipost/medias`);
      case Theme.EVENT:
        return this.get(`/event/${params.eventId}/post/${params.postItemId}/multipost/medias`);
      default:
        return this.get(`/home/post/${params.postItemId}/multipost/medias`);
    }
  }

  addToAlbum(params) {
    const albumName = encodeURIComponent(params.album);

    switch (params.scope) {
      case Theme.CONTACTS:
        return this.put(`/home/post/${params.postItemId}/add-to-album/${albumName}`);
      case Theme.GROUP:
        return this.put(`/group/${params.scopeId}/post/${params.postItemId}/add-to-album/${albumName}`, {}, 'apiRoot3');
      case Theme.EVENT:
        return this.put(`/event/${params.scopeId}/post/${params.postItemId}/add-to-album/${albumName}`);
      case Theme.PAGE:
      case Theme.PAGES:
        return this.put(`/pages/page/${params.scopeId}/post/${params.postItemId}/add-to-album/${albumName}`);
    }
  }

  removeMultiphotoFromAlbum(params) {
    params.albumName = encodeURIComponent(params.albumName);

    if (params.scope === Theme.CONTACTS) {
      return this.del(`/home/post/${params.postItemId}/multipost/remove-from-album/${params.albumName}`);
    } else if (params.scope === Theme.GROUP) {
      return this.del(
        `/group/${params.groupId}/post/${params.postItemId}/multipost/remove-from-album/${params.albumName}`,
        {},
        'apiRoot3'
      );
    } else if (params.scope === Theme.EVENT) {
      return this.del(
        `/event/${params.eventId}/post/${params.postItemId}/multipost/remove-from-album/${params.albumName}`
      );
    } else if (params.scope === Theme.PAGE) {
      return this.del(
        `/pages/page/${params.pageId}/post/${params.postItemId}/multipost/remove-from-album/${params.albumName}`
      );
    }
  }

  removePostsFromAlbum(params) {
    let url, apiRoot;

    if (params.scope === Theme.EVENT) {
      url = '/event/' + params.eventId;
    } else if (params.scope === Theme.GROUP) {
      apiRoot = 'apiRoot3';
      url = '/group/' + params.groupId;
    } else if (params.scope === Theme.CONTACTS || params.scope === Theme.PROFILE) {
      url = '/home';
    } else if (params.scope === Theme.PAGE) {
      url = '/pages/page/' + params.pageId;
    } else if (params.scope === Theme.MYCLOUD) {
      apiRoot = 'apiRoot3';
      url = '/mycloud';
    }

    const data = {
      mediaIds: params.mediaIds || params.postItemIds,
    };

    return this.post(
      `${url}/album/${encodeURIComponent(params.albumName)}/remove-media`,
      {
        data: JSON.stringify(data),
      },
      apiRoot
    );
  }

  getPostDetails(params) {
    switch (params.scope) {
      case Theme.PRIVATEPOSTS:
        return this.get(`/privateposts/${params.threadId}/post/${params.postItemId}`);
      case Theme.GROUP:
        if (params.isPending) {
          return this.get(`/group/${params.groupId}/pending/post/${params.postItemId}`, {}, 'apiRoot3');
        } else {
          return this.get(`/group/${params.groupId}/post/${params.postItemId}`, {}, 'apiRoot3');
        }
        break;
      case Theme.EVENT:
        if (params.isPending) {
          return this.get(`/event/${params.eventId}/pending/post/${params.postItemId}`);
        } else {
          return this.get(`/event/${params.eventId}/post/${params.postItemId}`);
        }
        break;
      case Theme.PAGE:
        return this.get(`/pages/page/${params.pageId}/post/${params.postItemId}`);
      case Theme.PAGE_LINK_ID:
        return this.get(`/pages/pageLinkId/${params.pageId}/post/${params.postItemId}`);
      default:
        return this.get(`/home/post/${params.postItemId}`);
    }
  }

  followPost(postItemId) {
    return this.put(`/post/${postItemId}/follow`);
  }

  unfollowPost(postItemId) {
    return this.del(`/post/${postItemId}/follow`);
  }

  togglePublicPost(postItemId, scheduled, params = false) {
    const scheduledPath = scheduled ? '/scheduled/' : '/';
    let postPath = 'contacts';

    if (params === 'everyone') {
      postPath = 'everyone';
    }

    return this.put(`/home${scheduledPath}post/${postItemId}/${postPath}`);
  }

  toggleCommentsDisabled(postItemId, scheduled = false, params) {
    const scheduledPath = scheduled ? '/scheduled/' : '/';
    return this.put(`/home${scheduledPath}post/${postItemId}/edit`, {
      data: JSON.stringify(params),
    });
  }

  publishToFriends(postItemId, scheduled) {
    const scheduledPath = scheduled ? '/scheduled/' : '/';
    return this.put(`/home${scheduledPath}post/${postItemId}/closefriends`);
  }

  reshare({ params, target, scope, postItemId }) {
    assert('Missing params in reshare', params);
    assert('Missing target in reshare', target);
    assert('Missing scope in reshare', scope);
    assert('Missing postItemId in reshare', postItemId);

    return this.post(
      `${scope}/post/${postItemId}/reshare/${target}`,
      {
        data: JSON.stringify(params),
      },
      apiVersion(scope)
    );
  }

  resharesList({ limit, scope, postItemId, nextPage }) {
    if (nextPage) return this.get(nextPage);
    else return this.get(`${scope}/post/${postItemId}/reshare?limit=${limit}`, {}, apiVersion(scope));
  }

  reschedule({ params, postId, scope }) {
    assert('Missing params in reschedule', params);
    assert('Missing postID in reschedule', postId);
    assert('Missing scope in reschedule', scope);

    return this.post(
      `${scope}/${postId}/reschedule`,
      {
        data: JSON.stringify(params),
      },
      apiVersion(scope)
    );
  }
}

export default new Api();

import { computed } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import ChatUtils from 'mewe/utils/chat-utils';
import { isDifferentDay, durationTextFromSeconds } from 'mewe/utils/datetime-utils';
import { Post } from './post-model';
import Emojis from './emojis-model';
import { modelText } from 'mewe/stores/models/mixins/model-text';
import { modelGifUrls } from 'mewe/stores/models/mixins/model-gif-urls';
import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import { modelEmojisList } from 'mewe/stores/models/mixins/model-emojis-list';
import { Media } from './entry-model';
import { or } from '@ember/object/computed';

const EntryRef = Model.extend({
  storyId: attr('string'),
  storytellerId: attr('string'),
  storytellerType: attr('string'),
  journalId: attr('string'),
  media: belongsTo(Media),
  isLive: attr('boolean'),
});

export default (CurrentUserStore, ChatStore, Thread) => {
  const model = Model.extend(modelText, modelEmojisReady, modelEmojisList, modelGifUrls, {
    id: attr('string'),
    threadId: attr('string'),
    text: attr('string'),
    date: attr('number'),
    authorId: attr('string'),
    originalAuthor: attr('object'),
    isConsecutive: attr('boolean', {
      defaultValue() {
        return false;
      },
    }),
    isConsecutiveReverse: attr('boolean', {
      defaultValue() {
        return false;
      },
    }),
    author: attr('object', {
      defaultValue() {
        let authorId = this.get('authorId');
        return this.get('thread.participants') && this.get('thread.participants').find((p) => p.id === authorId);
      },
    }),
    userName: attr('string'), // TODO fix in backend, it's needed only for chat search
    canForward: attr('boolean'),
    canEmojify: attr('boolean', {
      defaultValue: function () {
        return !this.deleted && !this.privatePost;
      },
    }),
    privatePost: belongsTo(Post),
    privacyMailId: attr('string'),
    editedAt: attr('number'),
    thread: belongsTo(Thread),
    attachment: attr('object'), // message.replTo has just single 'attachment'
    attachments: attr('array', {
      defaultValue: function () {
        return A();
      },
    }),
    groupId: attr('string'),
    group: attr('object'),
    call: attr('object'),
    participation: attr('object'),
    eventType: attr('string'), // 'CallEnded', 'ParticipantJoined', 'ParticipantLeft', 'ChatNameChanged'

    canDownloadAtt: computed('attachments', function () {
      const att = this.attachments?.[0];
      return att && att.aType !== 'photo';
    }),

    canDownloadPhoto: computed('attachments', function () {
      const att = this.attachments?.[0];
      return att && att.aType === 'photo';
    }),

    canEdit: computed('attachments.length', 'call', 'isGif', 'isMyMessage', 'privatePost', function () {
      return this.isMyMessage && !this.privatePost && !this.get('attachments.length') && !this.isGif && !this.call;
    }),

    deleted: attr('boolean', {
      defaultValue() {
        return false;
      },
    }),

    expiresIn: attr('string'),
    isPre: attr('boolean', {
      defaultValue: false,
    }),
    isWs: attr('boolean', {
      defaultValue: false,
    }),
    cameFromWs: attr('boolean', {
      defaultValue: false,
    }),
    preId: attr('string'),
    dissapearingType: attr('string'),
    failedToSend: attr('boolean', {
      defaultValue: false,
    }),

    links: attr('array'), // scrapped links from message's text

    messageType: attr('string'), // <- smart search
    searchGroupId: attr('string'), // <- smart search
    textPlain: attr('string'), // <- smart search
    textParts: attr('array'), // <- smart search
    userId: attr('string'), // <- smart search
    groupName: attr('string'), // <- smart search
    recordType: attr('string'), // <- smart search
    _links: attr('object'), // <- smart search

    emojis: belongsTo(Emojis, {
      defaultValue: function () {
        return Emojis.create();
      },
    }),

    story: belongsTo(EntryRef),

    aType: computed('attachments.length', function () {
      if (this.attachments && this.attachments.length) {
        return this.attachments[0].aType;
      }
    }),

    computedAuthorId: or('authorId', 'author.id', 'author.userId'),

    computedAuthor: computed('author', 'authorId', 'originalAuthor', 'thread.participants', function () {
      if (typeof this.author !== 'undefined' && this.author !== null) {
        return this.author;
      }

      if (typeof this.originalAuthor !== 'undefined' && this.originalAuthor !== null) {
        return this.originalAuthor;
      }

      const authorId = this.authorId;
      let computed = this.get('thread.participants') && this.get('thread.participants').find((p) => p.id === authorId);

      return computed;
    }),

    isMyMessage: computed('computedAuthorId', function () {
      return CurrentUserStore.getState().get('id') === this.computedAuthorId;
    }),

    textServer: computed('deleted', 'emojisReady', 'isMyMessage', 'privatePost', 'text', {
      get() {
        var text = this.text || '';

        if (this.privatePost) {
          return this.isMyMessage ? __('You sent a private post') : __('Sent a private post');
        }

        if (this.deleted) {
          text = __('This message was deleted');
        }

        return text;
      },
      set(key, value) {
        this._textServer = value;

        return value;
      },
    }),

    canCopy: computed('privatePost', 'textServer', function () {
      return this.textServer && !this.privatePost;
    }),

    expiresInPretty: computed('expiresIn', function () {
      var expiresIn = this.expiresIn;

      if (expiresIn) {
        var hours = expiresIn / (60 * 60);
        var minutes = (expiresIn % (60 * 60)) / 60;

        if (hours >= 1) {
          return `${Math.ceil(hours)}h`;
        } else if (minutes >= 1) {
          return `${Math.ceil(minutes)}m`;
        } else {
          return `${expiresIn}s`;
        }
      }
    }),

    disappearingContentSource: computed('attachments.length', 'expiresIn', function () {
      if (this.attachments && this.attachments.length) {
        let href = this.attachments[0]._links.self.href;

        if (this.attachments[0].aType === 'video') {
          return href.replace('{resolution}', 'original');
        } else {
          return href.replace('{imageSize}', '400x400');
        }
      }
    }),

    disappearingContentType: computed('dissapearingType', 'expiresIn', function () {
      if (this.expiresIn) {
        if (this.dissapearingType === 'mewe_me') {
          return 'mewe_me';
        } else if (this.dissapearingType === 'mewe_we') {
          return 'mewe_we';
        } else {
          return 'mewe_dc';
        }
      }
    }),

    displayDate: computed('date', 'id', 'thread', 'thread.messages.length', function () {
      const messages = this.get('thread.messages') || []; // no messages in new thread
      const messageIndex = messages.findIndex((m) => m.id === this.id);

      if (messageIndex < 0) {
        return false;
      }

      const prevMessage = messages[messageIndex + 1];
      return !prevMessage || isDifferentDay(prevMessage.get('date'), this.date);
    }),

    callText: computed('call.video', 'isMissedCall', 'isMyMessage', 'thread.getUserChatParticipant', function () {
      if (!this.call) {
        return;
      }

      // for now only 1-1 calls are possible
      const otherUser = this.get('thread.getUserChatParticipant');

      if (this.isMissedCall) {
        if (this.isMyMessage) {
          if (this.get('call.video')) {
            return __('{firstName} missed a video call from you', { firstName: otherUser.name });
          } else {
            return __('{firstName} missed a call from you', { firstName: otherUser.name });
          }
        } else if (this.get('call.video')) {
          return __('You missed a video call from {firstName}', { firstName: otherUser.name });
        } else {
          return __('You missed a call from {firstName}', { firstName: otherUser.name });
        }
      } else if (this.get('call.video')) {
        return __('Video call with {firstName} ended', { firstName: otherUser.name });
      } else {
        return __('Voice call with {firstName} ended', { firstName: otherUser.name });
      }
    }),

    callTextLastMsg: computed('call.video', 'isMissedCall', function () {
      if (!this.call) {
        return;
      }

      if (this.isMissedCall) {
        if (this.get('call.video')) {
          return __('Missed Video Call');
        } else {
          return __('Missed Voice Call');
        }
      } else if (this.get('call.video')) {
        return __('Video Call');
      } else {
        return __('Voice Call');
      }
    }),

    isMissedCall: computed('call', 'call.duration', function () {
      // duration === 0 if declined, no duration if not picked up at all
      return this.call && (!this.get('call.duration') || this.get('call.duration') === 0);
    }),

    isAnsweredCall: computed('call', 'call.duration', function () {
      return this.call && this.get('call.duration') && this.get('call.duration') > 0;
    }),

    callDurationText: computed('isAnsweredCall', 'call.duration', function () {
      if (this.isAnsweredCall) {
        return durationTextFromSeconds(this.get('call.duration'));
      }
    }),

    textPartsHighlighted: computed('textParts', function () {
      return this.textParts ? A(this.textParts).filterBy('isHighlighted') : null;
    }),

    stickers: attr('array'),

    sticker: computed('stickers.length', function () {
      return this.get('stickers.length') ? this.stickers[0] : null;
    }),

    chatEventMessage: computed('eventType', 'text', 'participation', 'thread.staredBy', function () {
      return ChatUtils.textForChatEvent(this, ChatStore);
    }),
  });

  model.reopen({
    replyTo: belongsTo(model),
  });

  model.reopenClass({
    resourceName: 'chat-message',
    replyTo: belongsTo(model),
  });

  return model;
};

import { htmlSafe } from '@ember/template';
import EmberObject from '@ember/object';
import { modifier } from 'ember-modifier';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default modifier((element, [], options) => {
  let videoHeight = 337;
  let maxPostWidth = 600;

  const post = options.post || EmberObject.create();
  const media = EmberObject.create(options.media);

  const getImage = () => {
    let image = {};

    if (media.get('video')) {
      image = media.get('photo') || {};

      if (!image.size) image.size = {};

      // video has always the same size
      image.size.width = maxPostWidth;
      image.size.height = videoHeight;

      if (media.get('photo._links.img.href')) {
        image.imageLink =
          EnvironmentUtils.getImgHost(true, options.isPublicContent) +
          media.get('photo._links.img.href').replace('{imageSize}', '800x800');
      }
    } else if (media.get('photo')) {
      image = media.get('photo');

      if (media.get('photo._links.img.href')) {
        image.imageLink =
          EnvironmentUtils.getImgHost(true, options.isPublicContent) +
          media.get('photo._links.img.href').replace('{imageSize}', '800x800').replace('{static}', '1');
      }
    } else if (post.get('eventInfo')) {
      image = post.get('medias')?.[0];

      if (image) {
        image.ratio = 0.34; // 260/770 - needs to be hardcoded here as we are getting size of original image, not cropped 770x300 one, simplest solution now, can be refactored later
        const imageLink = post.get('medias')[0].get('photo._links.img.href');
        if (imageLink) {
          image.imageLink =
            EnvironmentUtils.getImgHost(true, options.isPublicContent) +
            imageLink.replace('{imageSize}', '800x800').replace('{static}', '1');
        }
      }
    } else if (post.get('photo')) {
      image = post.get('photo');
      if (post.get('photo._links.img.href')) {
        image.imageLink =
          EnvironmentUtils.getImgHost(true, options.isPublicContent) +
          post.get('photo._links.img.href').replace('{imageSize}', '800x800').replace('{static}', '1');
      }
    } else if (post.get('link')) {
      // if there is no preview image then link has format '/api/v2/proxy/img?target=' and shouldn't be displayed
      if (post.get('link._links.thumbnail.href') === '/api/v2/proxy/img?enc=b64&target=') {
        return image;
      }
      image.size = {};
      image.size.width = post.get('link.thumbnailSize.width') || 800;
      image.size.height = post.get('link.thumbnailSize.height') || 600;
      image.imageLink = post.get('link._links.thumbnail.href');
      maxPostWidth -= 35; // 35 is padding between the image inside a post
    }

    return image;
  };

  let image;
  let imagePlaceholderHeight;
  let postImage = getImage();

  if (!postImage || !postImage.size) return;

  // if we set predefined ration in post image e.g. event
  const ratio = postImage.ratio ? postImage.ratio : postImage.size.height / postImage.size.width;

  if (postImage.size.width <= maxPostWidth && !postImage.animated) {
    imagePlaceholderHeight = postImage.size.height;
  } else {
    imagePlaceholderHeight = maxPostWidth * ratio;
  }

  element.style = htmlSafe(`height: ${imagePlaceholderHeight}px;`);

  image = new Image();
  image.src = postImage.imageLink;

  image.onload = () => {
    if (!media.video) {
      element.style.height = ``;
    }

    image = null;
  };
});

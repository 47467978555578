import Component from '@glimmer/component';
import { A } from '@ember/array';
import { action, set, get, getProperties } from '@ember/object';
import { scheduleOnce, later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import ChatApi from 'mewe/api/chat-api';
import PostApi from 'mewe/api/post-api';
import DocumentsApi from 'mewe/api/documents-api';
import PurchasesStore from 'mewe/stores/purchases-store';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import Mentions from 'mewe/utils/mentions-utils';
import HashtagsUtils from 'mewe/utils/hashtags-utils';
import PostUtils from 'mewe/utils/post-utils';
import PostCommons from 'mewe/utils/posting-utils';
import PostStatus from './controllers/status';
import PostPhoto from './controllers/photo';
import PostDocument from './controllers/document';
import PostVideo from './controllers/video';
import PostPoll from './controllers/poll';
import PostLink from './controllers/link';
import { isMeWeTeam } from 'mewe/helpers/is-mewe-team';
import toServer from 'mewe/stores/text-parsers/to-server';
import toEdit from 'mewe/stores/text-parsers/to-edit';
import { Theme, Target, TargetContact, maxHashtags } from 'mewe/constants';
import config from 'mewe/config';
import { factory as popupFactory } from 'mewe/utils/popup-utils';
import PS from 'mewe/utils/pubsub';
import { ds } from 'mewe/stores/ds';
import TwitterText from 'twitter-text';
import PagesApi from 'mewe/api/pages-api';
import { stubTrue, cond, matches } from 'lodash';
import postboxDebug from './postbox-debug';
import Verbose from 'mewe/utils/verbose';
import GroupStore from 'mewe/stores/group-store';
import PermissionsUtils from 'mewe/utils/group-permissions-utils';
import dispatcher from 'mewe/dispatcher';
import { setPostboxOpen } from 'mewe/utils/scrolling-utils';
import { next } from '@ember/runloop';

const gt = (v, o) => v > o;
const username = (contacts) => (contacts && contacts.length ? contacts[0].firstName || contacts[0].name : '');

const placeholder = {
  photo: (v) => () => __('Say something about this {count} photo', { count: v.count }), // { count: } needed or i18n extractor lib doesn't understand it's a plural
  contact: (v) => () => __('Share something with {userName}', v),
  poll: () => __('Please answer my question!'),
  video: () => __('Say something about this video'),
  contacts: () => __('Tell your friends what’s happening'),
  page: () => __('Share a post with your followers'),
  event: () => __('Post to this event'),
  mycloud: () => __('Add photos and files to My Cloud'),
  groups: () => __('Share something with one of your groups'),
  group: () => __('Post to this group'),
  profile: () => __('Share something on your timeline'),
};

const verbose = Verbose({ prefix: '[Postbox]', color: 'orangered', enabled: false });
const log = verbose.log;

const POSTBOX_HEADER_CLASS = '.postbox_header_textarea';

class PhotoPostWrapper {
  @tracked photos = A();
  @tracked loading = null;
}

class VideoPostWrapper {
  @tracked loading = null;
  @tracked progress = null;
  @tracked fail = null;
}

class PollSettingsWrapper {
  @tracked days = A();
  @tracked hours = A();
  @tracked minutes = A();
  @tracked maxOptions = null;
  @tracked selectedDays = 0;
  @tracked selectedHours = 0;
  @tracked selectedMinutes = 0;
}

// after refactoring postbox to glimmer please check for conditions to be removed in 3 places in 'controllers/link'
export default class MwPostbox extends Component {
  @service router;
  @service pickers;
  @service analytics;
  @service account;
  @service dynamicDialogs;
  @service('postboxUpload') uploadService;

  // - potentially unused, cant find it in code
  // upload = false;
  // canPost = null;

  maxTextLimit = config.maxTextLimit;

  // general
  @tracked theme = this.args.theme;
  @tracked newPost = '';
  @tracked postingType;
  @tracked posterPopupOpened;
  @tracked includeOriginalPost = true;
  @tracked isSharing = false;
  @tracked commentsDisabled = false;
  @tracked shareWithVisible;
  @tracked shareWithAvailable;
  @tracked administrated;
  @tracked storeState;
  @tracked presetEmojis = A();
  @tracked isPublic = false;
  @tracked selectedContacts = A();
  @tracked _scheduleVisible;
  @tracked _selectedAlbumName;
  @tracked _selectedGroup;
  @tracked _selectedEvent;
  @tracked _selectedPage;

  // text
  @tracked editor;
  @tracked selectedTags = A();
  @tracked mentionsStrategy = null;
  @tracked hashtagsStrategy = null;

  // attachments
  @tracked sticker;
  @tracked selectedGifs = A();
  @tracked isLoadingGfyLinkInfo;

  // link
  @tracked link;
  @tracked isLoadingLink;

  // video
  @tracked video = new VideoPostWrapper();
  @tracked videoPreviewSrc;
  @tracked videoPreviewPlaceholder;

  // photo
  @tracked photo = new PhotoPostWrapper();
  @tracked photoIsBig = false;

  // poll
  @tracked showPoll = false;
  @tracked isPollValid = false;
  @tracked pollQuestionError = false;
  @tracked pollSettings = new PollSettingsWrapper();
  @tracked pollQuestion = '';
  @tracked pollOptions = A();

  // audio
  @tracked voiceRecordingVisible;
  @tracked audioUploaded;
  @tracked audioRecordingUrl;
  @tracked audioRecordingBlob;
  @tracked isAudioConverting;
  @tracked isAudioRecording;
  @tracked isAudioUploading;

  // files
  @tracked documents = A();
  @tracked hasDocumentsInProgress = false;

  premiumPreviewTrackingContext = 'post_scheduling';

  get group() {
    return this._selectedGroup ?? this.args.preselectedGroup;
  }
  get event() {
    return this._selectedEvent ?? this.args.preselectedEvent;
  }
  get page() {
    return this._selectedPage ?? this.args.preselectedPage;
  }
  get groupId() {
    return this.group?.id;
  }
  get eventId() {
    return this.event?.id;
  }
  get pageId() {
    return this.page?.id;
  }
  get target() {
    return this.args.target;
  }
  get postToShare() {
    return this.args.postToShare;
  }
  get postToRepost() {
    return this.args.postToRepost;
  }

  constructor() {
    super(...arguments);

    this.dynamicDialogs.registerCloseFunction(this.args.dialogId, this.confirmClose);

    this.storeState = PurchasesStore.getState();

    this.uploadService.postboxOpened = true;

    setPostboxOpen(true);

    this.newPostHandler = this._newPostHandler.bind(this);
    PS.Sub('post.add', this.newPostHandler);

    if (verbose.isEnabled) {
      postboxDebug(this, log);
    }

    if (this.postToRepost) {
      this.newPost = toEdit(this.postToRepost.textServer).trim();

      if (this.postToRepost.medias?.length) {
        if (this.postToRepost.medias[0].video) {
          this.preselectedVideo = this.postToRepost.medias[0].mediaId;
          this.uploadService.prefillVideo(this.preselectedVideo);
        } else {
          if (this.postToRepost.mediasCount > 4) {
            PostApi.getMultiPhoto(this.theme, this.postToRepost).then((data) => {
              if (this.isDestroying || this.isDestroyed) return;

              this.photo.photos.pushObjects(
                data.medias.map((photo) => {
                  return {
                    id: photo.photo.id,
                    tempId: photo.photo.id,
                    existing: true,
                    orientation: 'normal',
                    url: photo.photo._links.img.href.replace('{imageSize}', '400x400').replace('{static}', '1'),
                    isUrl: true,
                    mediaId: photo.mediaId,
                  };
                })
              );

              this.uploadService.prefillImages(this.photo.photos);
            });
          } else {
            this.photo.photos.pushObjects(
              this.postToRepost.medias.map((photo) => {
                let inconsistenUrl =
                  photo.imgUrl ||
                  photo.imageUrl ||
                  photo.photo.imageLink ||
                  (photo.photo && photo.photo._links && photo.photo._links.img
                    ? photo.photo._links.img.href.replace('{imageSize}', '800x800').replace('{static}', '1')
                    : null);

                return {
                  id: photo.photo.id,
                  tempId: photo.photo.id, // need to be able to change order of photos in postbox (MW)
                  existing: true,
                  orientation: 'normal',
                  url: inconsistenUrl,
                  isUrl: true,
                  mediaId: photo.mediaId,
                };
              })
            );

            this.uploadService.prefillImages(this.photo.photos);
          }
        }
      }

      if (this.postToRepost.sticker) {
        this.sticker = this.postToRepost.sticker;
      }

      if (this.postToRepost.gifUrls?.length) {
        this.selectedGifs = this.postToRepost.gifUrls;
      }

      // only if there is 1 file and it's audio, if multiple files and first audio then whole post's type is multi-file
      if (this.postToRepost.files.length === 1 && this.postToRepost.files[0].isAudio) {
        this.voiceRecordingVisible = true;
        this.audioUploaded = true;
        this.audioRecordingUrl = this.postToRepost.files[0]._links.url.href;
        this.audioFileId = this.postToRepost.postItemId;
      }
    }

    if (this.args.preselectedPhotos?.length) {
      next(() => {
        this.photo.photos.pushObjects(this.args.preselectedPhotos);
      });
    }

    if (this.args.preselectedContacts) {
      next(() => {
        this.selectedContacts.pushObjects(this.args.preselectedContacts);
      });
    }

    if (this.args.preselectedFiles?.length) {
      this.preselectedFiles = this.args.preselectedFiles;
      this.uploadService.prefillFiles(this.args.preselectedFiles);
    }

    if (this.args.newPostPrefilled) {
      this.newPost = this.args.newPostPrefilled;
    }

    this.postingType = this.account.activeUser.public ? TargetContact.PUBLIC : TargetContact.PRIVATE;

    // allow userpicker when sharing to users (PP) - consistent with apps
    this.shareWithAvailable = !this.targetEntity() || this.targetEntity().name === 'users';

    if (this.target === Target.PAGE && !this.page) {
      this.administrated = ds.administrated;
    } else if (this.isPostingToEvent && !this.postToRepost && !this.postToShare && !this.args.preselectedEvent) {
      // EVENT theme will effect in posting to this event, if there are any receivers/groups passed to postbox then do not set eventId
      // it could be e.g. opening postbox dialog to create PP from chat
      const rootEvent = this.args.eventState?.rootEvent;
      if (rootEvent?.id) this._selectedEvent = rootEvent;
    }

    window.onbeforeunload = (e) => this.onBeforeUnload(e);

    this.setAutocomplete();
    this.updateHeight();
  }

  @action
  onInsert(element) {
    this.element = element;

    this.afterRender();

    this.postStatus = new PostStatus(this);
    this.postPhoto = new PostPhoto(this);
    this.postVideo = new PostVideo(this);
    this.postDocument = new PostDocument(this);
    this.postPoll = new PostPoll(this);
    this.linkController = new PostLink(this);

    this.uploadService.setControllers(this.postPhoto, this.postDocument, this.postVideo);

    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;

      if (this.newPost) {
        this.linkController.parseLink(); // might be a link so try to parse
      }

      // posting/sharing to timeline doesn't require selecting target entity (Timeline is a target itself).
      // sharing to any other destination requires target entity selection if it's not preselected
      if (this.target !== Target.CONTACTS) {
        const isTargetSelected = this.targetEntity() || this.args.preselectedContacts?.length;
        this.shareWithVisible = this.postToShare || this.postToRepost || !isTargetSelected;
      }

      if (this.args.openWithPhotoBrowser) {
        this.addPhoto();
      } else if (this.args.openWithVideoBrowser) {
        this.addVideo();
      } else if (this.args.openWithFileBrowser) {
        this.addFile();
      }
    });

    this.args.sendPostboxToParent?.(this);

    if (this.args.preselectedFilesToUpload) {
      this.uploadService.add(this.args.preselectedFilesToUpload);
    }

    if (this.postToRepost?.link && !this.postToRepost?.medias?.length) {
      this.linkController.fetchLinkInfo(this.postToRepost.link._links.url.href);
    }

    if (this.postToShare?.link) {
      this.linkController.fetchLinkInfo(this.postToShare.link._links.url.href);
    }

    addObserver(this, 'showAddToAlbum', this.openAlbumPicker);
    addObserver(this, 'audioRecordingBlob', this.audioRecordingBlobChanged);
    addObserver(this, 'canSchedule', this.canScheduleChange);

    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.targetFieldsToObserve = ['target', 'groupId', 'eventId', 'pageId', 'selectedContacts.length'];
    this.targetChangedWrapped = () => scheduleOnce('afterRender', this, this.targetChanged);
    this.targetFieldsToObserve.forEach((field) => addObserver(this, field, this.targetChangedWrapped));
  }

  @action
  onDestroy() {
    if (this.pasteHandlerBind) {
      this.element.querySelector(POSTBOX_HEADER_CLASS).removeEventListener('paste', this.pasteHandlerBind);
    }

    document.removeEventListener('dragover', this.dragoverHandlerBind);
    this.element.removeEventListener('drop', this.dropHandlerBind);

    window.onbeforeunload = null;

    setPostboxOpen(false);

    if (typeof this.postingController.cleanAfter === 'function') {
      this.postingController.cleanAfter(this);
    }

    this.uploadService.clear();

    PS.Unsub('post.add', this.newPostHandler);

    removeObserver(this, 'showAddToAlbum', this.openAlbumPicker);
    removeObserver(this, 'audioRecordingBlob', this.audioRecordingBlobChanged);
    removeObserver(this, 'canSchedule', this.canScheduleChange);

    this.targetFieldsToObserve.forEach((field) => removeObserver(this, field, this.targetChangedWrapped));
  }

  @action
  inputPhotoChange() {
    const inputPhoto = this.element.querySelector('#fake-upload-photo');

    this.uploadService.add(inputPhoto.files);
    inputPhoto.value = null;
  }

  @action
  inputPhotoPollChange() {
    const inputPhotoPoll = this.element.querySelector('#fake-upload-photo-poll');
    this.postPoll.handlePhoto({ file: inputPhotoPoll.files[0], index: this.currentPollPhotoIndex });
  }

  @action
  inputVideoChange() {
    const inputVideo = this.element.querySelector('#fake-upload-video');

    this.uploadService.add(inputVideo.files);
    inputVideo.value = null;
  }

  @action
  inputDocumentChange() {
    const inputDocument = this.element.querySelector('#fake-upload-document');

    this.uploadService.add(inputDocument.files);
    inputDocument.value = null;
  }

  @action
  togglePostComments() {
    this.commentsDisabled = !this.commentsDisabled;
  }

  setAutocomplete() {
    let targetEntity = this.targetEntity(),
      targetId = targetEntity ? targetEntity.value : null,
      strategyParam;

    const isNotMyProfile = this.theme === Theme.PROFILE && !this.args.user?.isMe;

    if (targetEntity) {
      strategyParam = targetEntity.hash;
    } else if (isNotMyProfile) {
      strategyParam = this.args.user?.isMe ? Theme.CONTACTS : Theme.PROFILE;
    } else if (this.target === Target.CONTACTS) {
      strategyParam = Target.CONTACTS;
    }

    // when sharing post to group/page/event/PP - we can't autocomplete
    // mentions/hashtags until some sharing target is selected
    const isSharing = this.postToRepost || this.postToShare;
    if (isSharing && !targetId && this.target !== Target.CONTACTS) return;
    // no autocomplete when posting in /groups scope but group was not selected
    if (this.theme === Theme.GROUPS && !targetId) return;

    // no mentions for pages
    if (strategyParam && !this.page) {
      this.mentionsStrategy = Mentions.createTextCompleteStrategy(
        strategyParam,
        this.postToRepost,
        isNotMyProfile ? this.args.user : null
      );
    }

    this.hashtagsStrategy = HashtagsUtils.getHashtagStrategy(this.target, targetId);

    if (!this.account.activeUser.public) {
      this.postingType = null;
    }
  }

  get scheduleVisible() {
    return this._scheduleVisible ?? (this.args.scheduleVisible || false);
  }

  get selectedAlbumName() {
    return this._selectedAlbumName ?? (this.args.selectedAlbumName || '');
  }

  get showAlbumFavoritesWarning() {
    return this.postingType === 'friends' && this.selectedAlbumName;
  }

  get isPostingToEvent() {
    return (
      (this.theme === Theme.EVENT || this.theme === Theme.EVENTS) && !this.args.selectedContacts?.length && !this.group
    );
  }

  get shareButtonText() {
    if (this.target === Target.MYCLOUD || this.postToRepost?.isEditing) {
      return __('Save');
    }

    return __('Post');
  }

  get contactsSharingText() {
    if (this.postingType === 'friends') {
      return __('Favorites');
    }

    if (this.account.activeUser.public) {
      if (this.postingType === 'public') {
        return __('Anyone');
      }
    } else {
      if (this.postingType === 'everyone') {
        return __('Everyone');
      } else {
        return __('My Followers');
      }
    }
  }

  get postboxStyle() {
    if (this.showVideo) {
      return 'video';
    }

    if (this.documents.length) {
      return 'documents';
    }
  }

  get shareButtonDisabled() {
    // photos uploading
    if (this.photo.loading) return true;

    // audio converting
    if (this.isAudioConverting || this.isAudioRecording || this.isAudioUploading) return true;

    // is sharing
    if (this.isSharing) return true;

    // doc uploading
    if (this.hasDocumentsInProgress) return true;

    // video uploading
    if (this.video.loading === true || (this.video.progress > 0 && this.video.progress < 100)) return true;

    // target is correctly set (eg. choosen group or page, choosen mycloud)
    let targetNotReady = [Target.CONTACTS, Target.MYCLOUD].includes(this.target) ? false : !this.targetEntity();

    if (targetNotReady) return true;

    // edited post can have crap removed from it
    if (this.postToRepost?.isEditing) return false;

    let thereArePreselectedFiles = this.args.preselectedFiles?.length;

    let thereArePhotos = this.photo.photos?.length;

    let thereAreLoadedDocuments = this.documents.filter((el) => el.progress === 100).length;

    let thereIsVideoLoaded = this.video.progress == 100 && this.video.loading === false;

    let thereIsLink = this.link;

    const thereArePhotosLoaded = this.photo.photos?.filter((el) => el.loading === false).length;

    if (
      thereArePhotosLoaded ||
      thereIsLink ||
      thereIsVideoLoaded ||
      thereAreLoadedDocuments ||
      thereArePhotos ||
      thereArePreselectedFiles ||
      this.postToShare ||
      toServer(this.newPost, { parseNativeMarkdown: true }) ||
      this.sticker ||
      this.selectedGifs.length ||
      this.audioUploaded ||
      (this.showPoll && this.isPollValid) ||
      this.preselectedVideo ||
      this.selectedTags.length
    )
      return false;

    return true;
  }

  // allow link scrapping when there are images
  get allowLinkScrapping() {
    if (this.photo.photos?.length) {
      return true;
    }

    return !this.hasSomeAttachments && !this.isUploadItemMode;
  }

  get hasSomeAttachments() {
    if (this.isAudioRecording || this.showPoll || this.preselectedFiles?.length) {
      return true;
    }

    return false;
  }

  get isMeWe() {
    return isMeWeTeam() || this.account.activeUser.id === '5602c780e4b08f388c897a39';
  }

  get arePresetEmojisAvailable() {
    return this.isMeWe && !this.scheduleVisible && !this.postToRepost?.isEditing && this.target !== Theme.MYCLOUD;
  }

  get arePresetEmojisVisible() {
    return this.isMeWe && this.presetEmojis.length && this.arePresetEmojisAvailable;
  }

  // format ready to use for param in post/reshare request: array of strings
  get presetEmojisList() {
    return this.presetEmojis.map((e) => e.unicode || e.shortname);
  }

  get showControlsButtons() {
    return this.args.setShowControlsButtons === false ? false : !this.hasSomeAttachments;
  }

  get showFilesButton() {
    if (this.args.preselectedFiles?.length) {
      return true;
    }

    if (this.args.setShowFilesButton !== undefined) return this.args.setShowFilesButton;

    return false;
  }

  get canMarkHashtags() {
    return this.target !== Target.PRIVATEPOST;
  }

  @action
  openAlbumPicker() {
    if (this.showAddToAlbum && !this.albumAlreadyShown) {
      if (this.args.openWithAlbumDropdown) {
        this.albumAlreadyShown = true;
        scheduleOnce('afterRender', () => {
          if (this.isDestroying || this.isDestroyed) return;
          this.element.querySelector('.album-picker .dropdown-menu_opener').click();
        });
      }
    }
  }

  @action
  audioRecordingBlobChanged() {
    const blob = this.audioRecordingBlob;

    if (blob) {
      this.isAudioUploading = true;

      this.audioUploadingXhr = DocumentsApi.uploadBlobAudio(blob);

      this.audioUploadingXhr
        .then((data) => {
          if (data.files && data.files[0]) {
            this.audioUploaded = true;
            this.audioFileId = data.files[0].id;
          }

          log('audio upload complete');
        })
        .catch((data) => {
          if (data.data && data.data.errorCode === 700) {
            this.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
          this.cancelVoice();
        })
        .finally(() => {
          this.isAudioUploading = false;
        });
    } else {
      this.audioUploaded = null;
      this.audioFileId = null;
    }
  }

  get showAddToAlbum() {
    return this.target !== Theme.EVENT && this.target !== Theme.PRIVATEPOSTS && (this.showVideo || this.showPhoto);
  }

  get showVideo() {
    return this.video.loading || this.video.progress === 100 || this.preselectedVideo;
  }

  get showPhoto() {
    return this.photo.photos?.length;
  }

  // LINK scrapping vvvv
  parseTextValueKey = 'newPost';
  convertGifUrlFromLinks = true;

  get showLinkPreview() {
    return (this.isLoadingLink || this.link) && !this.postToShare;
  }

  canDoLinkScrapping() {
    // you cannot have link preview, if you're not in status mode
    return this.allowLinkScrapping && !this.postToShare?.link;
  }
  // LINK scrapping ^^^^

  get getTheme() {
    if (this.theme === Theme.CONTACTS || this.theme === Theme.PROFILE) {
      return 'myworld';
    } else {
      return this.theme;
    }
  }

  get postboxPlaceholder() {
    const postingScope = this.target;

    const getText = cond([
      [matches({ showPoll: true }), placeholder.poll],
      [matches({ 'selectedContacts.length': 1 }), placeholder.contact({ userName: username(this.selectedContacts) })],
      [matches({ 'selectedContacts.length': gt(1) }), placeholder.contacts],
      [
        matches({ 'args.myCloudUploading': true, 'args.openWithPhotoBrowser': true }),
        placeholder.photo({ count: this.photo.photos?.length }),
      ],
      [matches({ 'args.myCloudUploading': true, 'args.openWithPhotoBrowser': false }), placeholder.video],
      [stubTrue, placeholder[postingScope] || placeholder.contacts],
    ]);

    return getText(getProperties('showPoll', 'selectedContacts', 'args.myCloudUploading', 'args.openWithPhotoBrowser'));
  }

  afterRender() {
    // Disable D&D if post is being shared
    if (!this.postToShare) {
      this.dragoverHandler = (e) => {
        const files = e.dataTransfer;
        e.stopPropagation();
        e.preventDefault();
        if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
          e.preventDefault();
          return;
        }
        if (!this.isDestroyed && !this.isDestroying) {
          this.initDropzones();
        }
      };

      this.dropHandler = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.uploadService.add(ev.dataTransfer.files);
      };

      this.pasteHandler = (ev) => {
        if (ev.clipboardData && ev.clipboardData.files && ev.clipboardData.files[0] && !this.showPoll) {
          this.uploadService.add(ev.clipboardData.files);
        }

        setTimeout(() => {
          this.linkController.parseLink();
        }, 0);
      };
      this.pasteHandlerBind = this.pasteHandler.bind(this);
      let el = this.element.querySelector(POSTBOX_HEADER_CLASS);
      if (el) el.addEventListener('paste', this.pasteHandlerBind);
    } else {
      this.dragoverHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };

      this.dropHandler = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      };
    }

    //when drop is disabled, there is still need to prevent other handlers to run
    this.dragoverHandlerBind = this.dragoverHandler.bind(this);
    document.addEventListener('dragover', this.dragoverHandlerBind);

    this.dropHandlerBind = this.dropHandler.bind(this);
    this.element.addEventListener('drop', this.dropHandlerBind);
  }

  canUploadNonImage() {
    // can upload non-image if there is no image uploaded yet
    if (!this.photo.photos?.length) {
      return true;
    } else {
      log('err: Non supported image type');
      return false;
    }
  }

  get isUploadItemMode() {
    return this.showVideo || this.photo.photos?.length || this.documents.length;
  }

  get showAddPhotoButton() {
    return !this.sticker && !this.postToShare && !this.isUploadItemMode;
  }

  get showAddFileButton() {
    return !this.sticker && !this.voiceRecordingVisible && !this.postToShare && !this.isUploadItemMode;
  }

  get showAddStickerButton() {
    return !this.sticker && !this.selectedGifs.length && !this.isUploadItemMode;
  }

  get showAddGifButton() {
    return !this.sticker && !this.selectedGifs.length && !this.isUploadItemMode;
  }

  get showAddPollButton() {
    return !this.sticker && !this.postToShare && !this.isUploadItemMode;
  }

  get showVoiceRecordButton() {
    return !this.voiceRecordingVisible && !this.isUploadItemMode;
  }

  get postingController() {
    if (this.photo.photos?.length) return this.postPhoto;
    if (this.documents.length) return this.postDocument;
    if (this.showPoll) return this.postPoll;

    // reposting video is posting using status controller
    if (this.showVideo && !this.preselectedVideo) return this.postVideo;

    return this.postStatus;
  }

  getNewPostToServer(newPost, mentionsStrategy, selectedGifs) {
    const text = this.newPost || '';

    const res = toServer(text + this.getTagsText(), {
      mentionsStrategy: mentionsStrategy,
      gifUrls: selectedGifs,
      parseNativeMarkdown: true,
    });

    return (res || '').trim();
  }

  getTagsText() {
    let tagsText = '';

    if (this.selectedTags.length) {
      this.selectedTags.forEach((tag) => (tagsText += ' #' + tag.name));
    }

    return tagsText;
  }

  get alreadyUsedTagsCount() {
    return TwitterText.extractHashtagsWithIndices(this.newPost).length;
  }

  maxHashtags = maxHashtags;

  get postToShareTarget() {
    switch (this.target) {
      case Target.PRIVATEPOST:
        // if not includeOriginalPost then it's posting request, not sharing
        return this.includeOriginalPost ? `chatthread/${this.threadIds[0]}` : `privateposts/${this.threadIds[0]}`;

      case Target.GROUP:
        return `group/${this.group?.id}`;

      case Target.PAGE:
        return `page/${this.page?.id}`;

      case Target.EVENT:
        return `event/${this.event?.id}`;

      default:
        return `home`;
    }
  }

  get postToShareScope() {
    let { scope, scopeId } = PostUtils.getPostScopeAndId(this.postToShare);

    switch (scope) {
      case Theme.PRIVATEPOSTS:
        return `/privateposts/${scopeId}`;
      case Theme.GROUP:
      case Theme.GROUPS:
        return `/group/${scopeId}`;
      case Theme.PAGE:
      case Theme.PAGES:
        return `/pages/page/${scopeId}`;
      case Theme.EVENT:
        return `/event/${scopeId}`;
      default:
        return `/home`;
    }
  }

  @action
  scheduleAction(timestamp) {
    this.share(timestamp);
  }

  @action
  shareAction() {
    this.share();
  }

  share(scheduleTimestamp) {
    if (this.isSharing) return;
    if (this.shareButtonDisabled) return;

    const postToServer = this.getNewPostToServer(this.newPost, this.mentionsStrategy, this.selectedGifs);

    this.newPostServer = postToServer;
    this.doShare(scheduleTimestamp);
  }

  doShare(schedule) {
    const postOrShare = () => {
      if (this.postToShare && this.includeOriginalPost) {
        // for posting it's set in posting utils share function
        this.isSharing = true;

        const params = {
          newText: this.newPostServer,
          audioId: this.audioFileId,
          postedByPage: !!this.page,
          emojisPreset: this.presetEmojisList,
          commentsDisabled: this.commentsDisabled,
        };

        if (this.postingType === 'everyone') {
          params.everyone = true;
        } else if (this.postingType === 'friends') {
          params.closeFriends = true;
        }

        if (this.sticker) {
          params.stickers = [this.sticker];
        }

        if (schedule) {
          params.schedule = schedule;
        }

        PostApi.reshare({
          params,
          target: this.postToShareTarget,
          scope: this.postToShareScope,
          postItemId: this.postToShare.postItemId,
        })
          .then((data) => {
            if (params.schedule) {
              FunctionalUtils.info(__('Your post has been scheduled'));
            } else {
              const isPostingToCurrentLocation = PostCommons.isPostingToCurrentLocation(
                this.postToShareTarget,
                data.post,
                this.router
              );

              if (!isPostingToCurrentLocation) {
                if (this.postToShare?.isAlbum) {
                  FunctionalUtils.info(__('Album Shared'));
                } else {
                  FunctionalUtils.info(__('Post Shared'));
                }
              }
            }

            if (data.post) {
              if (this.target === Theme.PAGE) {
                let pageId = data.post && data.post.pageId;

                // we have at this point fetched administrated pages, but with no info about publish status (MW)
                PagesApi.page({ id: pageId })
                  .then((data) => {
                    dispatcher.dispatch('feed', 'addPost', data.post, !data.page.published);
                  })
                  .catch(() => {
                    dispatcher.dispatch('feed', 'addPost', data.post);
                  });
              } else {
                dispatcher.dispatch('feed', 'addPost', data.post);
              }
            }

            this.close();
          })
          .catch((data) => {
            if (data && data.status === 403) {
              FunctionalUtils.error(__("Sorry, this post can't be shared"));
            }
            this.isSharing = false;
          });
      } else {
        const p = { schedule, commentsDisabled: this.commentsDisabled, canAddEmoji: !this.commentsDisabled };

        if (this.postToRepost?.isEditing) {
          p.edit = true;
        }

        this.postingController.shareController(p);
      }
    };

    // if sending PP then check if thread with users exists
    let users = this.selectedContacts;

    if (users?.length) {
      users = users.map((u) => u.id);

      ChatApi.getThreads({
        receivers: users,
        maxResults: 50,
      }).then((data) => {
        let foundThread;

        // there might be more than one thread with given list of participants,
        // in such case check for thread result with exact ID as the thread
        // from where postbox was initialised - sharedWithThreadId (SG-35804)
        if (data.threads) {
          if (this.args.sharedWithThreadId) {
            foundThread = data.threads.find((t) => t.id === this.args.sharedWithThreadId);
          }

          // there might be no thread with same ID because e.g. participants could be edited
          // during post creation in postbox, then select any non-closed thread from results
          if (!foundThread) {
            foundThread = data.threads.find((t) => !t.closed);
          }
        }

        if (foundThread) {
          this.threadIds = [foundThread.id];
          postOrShare();
        } else {
          // there is no chat between users yet - create it first.
          // applicable for POST->ReshareToPP action when selected a contact without existing 1-1 chat
          ChatApi.createChatThread({
            receivers: users,
          }).then((data) => {
            this.threadIds = [data.id];
            postOrShare();
          });
        }
      });
    } else {
      postOrShare();
    }
  }

  @action
  setAlbumName(value) {
    this._selectedAlbumName = value;
  }

  targetEntity() {
    let target = this.target,
      value,
      idName;

    if (target === Target.EVENT && this.eventId) {
      value = this.eventId;
      idName = 'eventId';
    } else if (target === Target.PAGE && this.pageId) {
      value = this.pageId;
      idName = 'pageId';
    } else if (target === Target.GROUP && this.groupId) {
      value = this.groupId;
      idName = 'groupId';
    } else if (target === Target.PRIVATEPOST && this.selectedContacts?.length) {
      value = this.selectedContacts;
      idName = 'users';
    } else if (target === Target.PRIVATEPOST && this.postToRepost?.isEditing) {
      value = this.postToRepost?.id;
      idName = 'privatePostEdit';
    }

    if (!value) return null;

    return {
      value: value,
      name: idName,
      hash: {
        [idName]: value,
      },
    };
  }

  /*
   * runs always after posting, either successful or not
   */
  alwaysAfterPosting() {
    if (this.isDestroying || this.isDestroyed) return;
    this.isSharing = false;
  }

  _continue() {
    this.isSharing = false;
  }

  err(message) {
    FunctionalUtils.error(message);
    log('Postbox error');
  }

  //we can check type of file only after upload
  //so we have to initialize dropzone beforehand and then decide how to process file after upload
  initDropzones() {
    const postboxDropZoneEl = this.element.querySelector('.drop-overlay'),
      timeout = this.dropZoneTimeout;

    if (!postboxDropZoneEl) return;

    if (!timeout) {
      // limit to trigger only once while dragover
      postboxDropZoneEl.classList.add('to-drop');
    } else {
      clearTimeout(timeout);
    }

    this.dropZoneTimeout = setTimeout(() => {
      if (this.isDestroyed || this.isDestroying) return;

      this.dropZoneTimeout = null;
      postboxDropZoneEl.classList.remove('to-drop');
    }, 100);
  }

  onBeforeUnload(e) {
    if (this.isEmpty() || this.confirmDialogShown) {
      return;
    }

    let { message } = this.getConfirmCloseMsgs();

    if (e) {
      e.returnValue = message;
    }

    return message;
  }

  isEmpty() {
    const text = (toServer(this.newPost, { parseNativeMarkdown: true }) || '').trim();

    return (
      text === '' &&
      (!this.postingController || this.postingController.isEmpty()) &&
      !this.selectedGifs.length &&
      !this.sticker &&
      !this.showPoll &&
      !this.isAudioRecording &&
      !this.isAudioConverting
    );
  }

  @action
  updateHeight() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;
      this.recalculatePostboxScroll();
    });
  }

  // update autocomplete when post recipients are changed
  targetChanged() {
    this.setAutocomplete();
  }

  get daysValue() {
    if (this.pollSettings.selectedDays) {
      return __('{count} day', {
        count: this.pollSettings.selectedDays,
      });
    }
    return __('Days');
  }

  get hoursValue() {
    if (this.pollSettings.selectedHours) {
      return __('{count} hour', {
        count: this.pollSettings.selectedHours,
      });
    }
    return __('Hours');
  }

  get minutesValue() {
    if (this.pollSettings.selectedMinutes) {
      return __('{count} minute', {
        count: this.pollSettings.selectedMinutes,
      });
    }
    return __('Minutes');
  }

  get daysDefault() {
    return __('Days');
  }
  get hoursDefault() {
    return __('Hours');
  }
  get minutesDefault() {
    return __('Minutes');
  }

  get showPublicShare() {
    return (
      this.target === Target.CONTACTS &&
      this.shareWithVisible &&
      (this.theme != Theme.PROFILE || this.target !== Target.PRIVATEPOST)
    );
  }

  get isShareWithEnabled() {
    if (this.group || this.event) return false;

    // creating PP in thread with non-contact - disable adding more users because
    // new multiuser thread can't be created with an user who is not a contact
    if (this.args.sharedWithNonContact) return false;

    /**
     * SG-22361, SG-23277, true for sharing post from user's profile page,
     * false when creating PP from profile page postbox
     */
    if (this.theme === Theme.PROFILE && this.target === Target.PRIVATEPOST) {
      return false;
    }

    if (this.postToRepost?.isEditing) return false;

    return [Target.PRIVATEPOST, Target.GROUP, Target.EVENT, Target.CONTACTS].includes(this.target);
  }

  get showPageAvatar() {
    return this.page;
  }

  get avatarUrl() {
    if (this.page) {
      return this.page.avatar;
    } else {
      let userAvatar =
        EnvironmentUtils.getImgHost(true) +
        this.account.activeUser._links?.avatar?.href?.replace('{imageSize}', '150x150');
      return this.groupIdOrEventGroupId
        ? userAvatar + `&group=${this.groupIdOrEventGroupId}&f=${this.account.activeUser.fprint}`
        : userAvatar;
    }
  }

  get groupIdOrEventGroupId() {
    return this.groupId || get(this, 'args.eventState.rootEvent.groupId');
  }

  get shareWithText() {
    if (this.target === Target.MYCLOUD) return '';

    const group = this.group,
      event = this.event,
      contactsCount = this.selectedContacts?.length || 0;

    let shareWithText;

    const getTextByUsers = () => {
      if (contactsCount === 1) {
        shareWithText = this.selectedContacts?.[0].name;
      } else {
        shareWithText = __('{count} Followers', { count: contactsCount });
      }
      return shareWithText;
    };

    const getTextByTarget = () => {
      switch (this.target) {
        case Target.CONTACTS:
          shareWithText = this.contactsSharingText;
          break;
        case Target.GROUP:
          shareWithText = this.group?.name || __('Select a group');
          break;
        case Target.EVENT:
          shareWithText = this.event?.name || __('Select an event');
          break;
        case Target.PAGE:
          shareWithText = this.page?.name || __('Select a page');
          break;
        case Target.PRIVATEPOST:
          if (this.postToRepost?.isEditing) {
            shareWithText = __('Private post');
          } else {
            shareWithText = contactsCount > 0 ? getTextByUsers() : __('Select a Follower');
          }
          break;
        default:
          shareWithText = contactsCount > 0 ? getTextByUsers() : __('Select a Follower');
          break;
      }

      return shareWithText;
    };

    if (this.postToShare || this.postToRepost?.isEditing) {
      shareWithText = getTextByTarget();
    } else if (this.page) {
      shareWithText = this.page.name;
    } else if (group) {
      shareWithText = group.name;
    } else if (contactsCount > 0) {
      shareWithText = getTextByUsers();
    } else if (event) {
      shareWithText = event.name;
    } else if (this.target) {
      shareWithText = getTextByTarget();
    }

    return shareWithText;
  }

  _newPostHandler() {
    this.recalculatePostboxScroll();
  }

  updateEditor(text) {
    if (this.editor) {
      this.editor.update(text);
    }
  }

  @action
  canScheduleChange() {
    if (!this.canSchedule && this.scheduleVisible) {
      this.hideSchedule();
    }
  }

  get isLimitedMember() {
    return GroupStore.getGroupIfMember({ id: this.groupId })?.role === 'Limited';
  }

  get notPrivacymail() {
    return this.target !== 'privacymail';
  }

  get hasPremium() {
    return this.storeState.hasPremium;
  }

  get canScheduleWithoutModeration() {
    const canSchedule = () => {
      return Object.values({
        homeView: this.target === 'contacts',
        groupViewSelectedGroups: this.target === 'group' && typeof this.group !== 'undefined',
        groupViewScheduledtab: this.target === 'group' && typeof this.groupId !== 'undefined',
        whoCanScheduleInGroups:
          this.group?.role === 'Contributor' || this.group?.role === 'Admin' || this.group?.isOwner,
      }).includes(true);
    };
    const notGroupScope = this.target !== 'group';

    if (notGroupScope) return canSchedule();
    else {
      const selectedGroupPermissions = GroupStore.getState({ id: this.groupId })?.permissions;
      const canPostInSelected = PermissionsUtils.canPostWithoutModeration(selectedGroupPermissions);

      return canPostInSelected && canSchedule();
    }
  }

  get canSchedule() {
    if (!this.showScheduleButton) return false;

    // not a page, then premium is required
    return this.hasPremium || this.target === 'page';
  }

  // show button when user would be able to schedule a post if was a premium user
  get showScheduleButton() {
    // premium is not required to schedule in Pages, they are already paid
    if (this.target === 'page') return true;

    return !this.eventId && !this.isLimitedMember && this.notPrivacymail && this.canScheduleWithoutModeration;
  }

  recalculatePostboxScroll() {
    later(() => {
      if (this.isDestroying || this.isDestroyed) return;
      this.args.recalculatePostboxScroll?.();
    }, 100);
  }

  @action
  setPostingType(type) {
    this.postingType = type;
  }

  @action
  datePickerOpenedUpdate(value) {
    this.datePickerOpened = value;
    this.args.datePickerOpenedUpdate?.();
    this.updateHeight();
  }

  @action
  imageLoaded() {
    this.recalculatePostboxScroll();
  }

  @action
  keyUpAction(value, e) {
    this.linkController.parseLinkFunc(e);
    this.recalculatePostboxScroll();
  }

  /************************ PHOTO ************************/

  @action
  addPhoto() {
    document.getElementById('fake-upload-photo').click();
  }

  @action
  removePhoto(photo) {
    this.postPhoto.removePhoto(photo);
  }

  /************************ GIF ************************/
  @action
  addGif() {
    import('mewe/pods/components/popups/mw-giphy-popup').then((Popup) => {
      const gifsDialog = popupFactory(this, Popup.default, 'mw-giphy-popup').create({
        parent: this.element.querySelector('.svg_icon-gif_new'),
        selectedGifs: this.selectedGifs,
        onClose: () => {
          this.focusTextarea();
          this.gifsDialog = null;
          this.updateHeight();
        },
      });

      gifsDialog.send('open');
      this.gifsDialog = gifsDialog;
    });

    return false;
  }

  /************************ FILE ************************/
  @action
  addFile() {
    document.getElementById('fake-upload-document').click();
  }

  @action
  removeFile(file) {
    this.postDocument.removeFile(file);
  }

  /************************ VIDEO ************************/
  @action
  addVideo() {
    document.getElementById('fake-upload-video').click();
  }

  /************************ POLL ************************/
  @action
  addPoll() {
    this.showPoll = true;
    this.updateHeight();
  }

  @action
  cancelPoll() {
    this.showPoll = false;
    this.postPoll.clear();
    this.updateHeight();
  }

  @action
  addPollOption() {
    this.postPoll.addPollOption();
    this.pollContentChanged();
  }

  @action
  addPollPhoto(index) {
    this.currentPollPhotoIndex = index;
    document.getElementById('fake-upload-photo-poll').click();
    this.pollContentChanged();
  }

  @action
  removePollPhoto(index) {
    this.postPoll.removePhoto(index);
    this.pollContentChanged();
  }

  @action
  pollContentChanged() {
    this.postPoll.validatePoll();
    this.updateHeight();
  }

  @action
  setDays(days) {
    set(this, 'pollSettings.selectedDays', days);
  }

  @action
  setHours(hours) {
    set(this, 'pollSettings.selectedHours', hours);
  }

  @action
  setMinutes(minutes) {
    set(this, 'pollSettings.selectedMinutes', minutes);
  }

  @action
  cancelVoice() {
    this.audioFileId = null;
    this.audioRecordingUrl = null;
    this.audioRecordingBlob = null;
    this.voiceRecordingVisible = false;
    this.updateHeight();
  }

  @action
  cancelUpload() {
    if (this.video.loading || this.video.progress) {
      this.postVideo.removeVideo();
    }
    if (this.uploadXhr) {
      this.uploadXhr.cancel();
    }
  }

  @action
  cancelUploadPreselected(file) {
    A(this.args.preselectedFiles).removeObject(file);
    this.uploadService.remove(file.id);
  }

  getConfirmCloseMsgs() {
    let message = __(`Closing this popup will delete your post. Are you sure you want to delete?`);
    let cancelBtnText = __('Continue editing');
    let okBtnText = __('Delete post');
    let title = __('Cancel post?');

    return { title, message, okBtnText, cancelBtnText };
  }

  @action
  confirmClose(afterCloseCallback) {
    if (!this.isEmpty()) {
      let { title, message, okBtnText, cancelBtnText } = this.getConfirmCloseMsgs();

      this.confirmDialogShown = true;
      let setConfirmDialogShownToFalse = () => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.confirmDialogShown = false;
        }
      };

      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: title,
        message: message,
        okButtonText: okBtnText,
        cancelButtonText: cancelBtnText,
        onConfirm: () => {
          setConfirmDialogShownToFalse();
          this.close(afterCloseCallback);
        },
        onCancel: setConfirmDialogShownToFalse,
      });
    } else {
      this.close(afterCloseCallback);
    }
  }

  @action
  close(afterCloseCallback) {
    if (this.isDestroying || this.isDestroyed) return;

    this.uploadService.clear();
    this.uploadService.postboxOpened = false;
    setPostboxOpen(false);

    this.newPost = '';
    this.newPostServer = '';
    this._selectedAlbumName = null;
    this.isSharing = false;

    if (this.postToRepost?.isEditing) {
      this.postToRepost.isEditing = false;
    }

    this.linkController.setInitialLinkProperties();

    if (typeof this.postingController.cleanAfter === 'function') {
      this.postingController.cleanAfter(this);
    }

    this.shareWithVisible = false;
    this.selectedGifs = A();
    this._selectedAlbumName = null;
    this.photoUploadDialog = null;
    this.filesToUpload = A();
    this.showPoll = false;

    if (this.gifsDialog) {
      this.gifsDialog.send('close');
    }

    // in case postbox was opened in dialog
    if (this.args.dialogId) {
      this.dynamicDialogs.close(this.args.dialogId, true);
    }

    this.args.closePostboxDialog?.();

    if (typeof afterCloseCallback === 'function') {
      afterCloseCallback();
    }
  }

  @action
  toggleShareWith() {
    this.shareWithVisible = !this.shareWithVisible;
  }

  @action
  focusTextarea() {
    if (!this.isDestroying && !this.isDestroyed && this.editor) this.editor.focus();
  }

  @action
  openAudioRecording() {
    this.voiceRecordingVisible = true;
  }

  @action
  togglePublicPost(value) {
    this.isPublic = value;
    this.toggleShareWith();
  }

  @action
  showSchedule() {
    if (this.canSchedule) {
      this._scheduleVisible = true;
    } else {
      this.dynamicDialogs.openDialog('premium-feature-dialog', { context: this.premiumPreviewTrackingContext });
    }
  }

  @action
  hideSchedule() {
    this._scheduleVisible = false;
  }

  @action
  onSelectTarget(targetObj) {
    switch (this.target) {
      case Target.PAGE:
        this._selectedPage = targetObj;
        break;

      case Target.GROUP:
        this._selectedGroup = targetObj;
        break;

      case Target.EVENT:
        this._selectedEvent = targetObj;
        break;
    }
  }

  @action
  onSelectContact(targetObj) {
    this.selectedContacts.pushObject(targetObj);
  }

  @action
  unSelectContact(targetObj) {
    const toRemove = this.selectedContacts.find((c) => c.id === targetObj.id);
    this.selectedContacts.removeObject(toRemove);
  }

  @action
  removePresetEmoji(emoji) {
    this.presetEmojis.removeObject(emoji);
  }

  @action
  toggleLinkThumbnail() {
    set(this, 'link.noThumbnail', !this.link.noThumbnail);
  }

  @action
  nextLinkThumbnail() {
    set(this, 'link.thumbnailCounter', MathUtils.mod(this.link.thumbnailCounter + 1, this.link.images?.length));
    set(this, 'link.image', this.link.images[this.link.thumbnailCounter]);
  }

  @action
  prevLinkThumbnail() {
    set(this, 'link.thumbnailCounter', MathUtils.mod(this.link.thumbnailCounter - 1, this.link.images?.length));
    set(this, 'link.image', this.link.images[this.link.thumbnailCounter]);
  }

  @action
  removeLink() {
    this.linkController.removeLink();
  }

  @action
  openStickerPicker() {
    this.pickers.openStickerPicker(this);
  }

  @action
  removeSticker() {
    this.sticker = null;
    this.updateHeight();
  }

  @action
  addEmoji(emoji) {
    this.presetEmojis.pushObject(emoji);
  }

  @action
  setEmojiPicker(isOpen) {
    this.emojiPickerOpened = isOpen;
  }

  @action
  setSticker(sticker, ctx = this) {
    ctx.sticker = sticker;
    ctx.focusTextarea();
    ctx.updateHeight();
  }

  @action
  photosSorted(idArray) {
    const sortedPhotos = idArray
      .map((id) => {
        return this.photo.photos?.toArray().find((photo) => photo.id === id);
      })
      .filter((photo) => photo);
    this.photo.photos = A(sortedPhotos);
  }

  @action
  closeDropdown() {
    this.posterPopupOpened = false;
  }

  @action
  setPostBoxProperty(propertyName, value) {
    this[propertyName] = value;
  }

  @action
  setEditor(editor) {
    this.editor = editor;
  }

  @action
  updateContent(propertyName, content) {
    this[propertyName] = content;
  }

  @action
  updateOptionContent(option, content) {
    option.set('text', content);
  }

  @action
  updatePhotoCaption(image, caption) {
    image.caption = caption;
  }

  @action
  updateNewPost(content) {
    //we need this check to avoid update post content two times in the same loop
    if (this.newPost !== content) {
      this.newPost = content;
    }
  }
}

/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

import { getMembershipStatusInfo, getGroupContactsText } from 'mewe/utils/group-utils-light';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';

export default class MwSmartSearchGroup extends Component {
  @service router;
  @service dynamicDialogs;

  @computed('args.group.{alreadyApplied,isMember,isInvited}')
  get membershipStatus() {
    return getMembershipStatusInfo(this.args.group);
  }

  @computed('args.group.groupContacts.length')
  get groupContactsText() {
    return getGroupContactsText(this.args.group);
  }

  @action
  click() {
    if (this.args.group.isMember) {
      this.router.transitionTo('app.group', this.args.group.id);
      this.args.closeSmartSearch();
    } else {
      showGroupPreviewDialog(this.dynamicDialogs, this.args.group, { isGroupPreview: true });
    }
  }
}

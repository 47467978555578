import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';

var model = Model.extend(modelProfileId, {
  id: attr('string'),
  publicLinkId: attr('string'),
  firstName: attr('string'),
  fprint: attr('string'),
  fingerprint: attr('string'),
  lastName: attr('string'),
  name: attr('string'),
  badges: attr('object'),
  _links: attr('object'),
});

model.reopenClass({
  resourceName: 'simple-user',
  aliases: {
    publicUrl: 'publicLinkId',
  },
});

export default model;

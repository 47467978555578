import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';

export default class Component extends PopupOpener {
  layout = layout;

  popupType = 'dropdown-popup';

  closeOnClickOutside = true;

  closeOnScroll = true;

  dontCloseAt = '.follow-post';

  theme = 'contacts';

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();

    Object.assign(position, {
      reposition: { elementHeight: 230 },
      placement: {
        top: true,
        left: isRtl,
      },
      translate: {
        x: -20,
        y: -10,
        altY: 5,
      },
    });

    return position;
  }
}

import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';

export default class MwPostPollOption extends Component {
  @service router;

  @reads('args.option') option;

  @reads('args.post') post;

  @computed('isPostDialog', 'option.index', 'post.{id,postItemId}')
  get resultId() {
    const postId = this.post.postItemId || this.post.id;
    return `poll-result-${postId}-${this.option.index}${this.isPostDialog ? '-dialog' : ''}${Date.now()}`;
  }

  @computed('option.votes')
  get votesCount() {
    return this.option.votes || 0;
  }

  @computed('post.isTranslated', 'option.{text.length,index,textTranslated}')
  get optionText() {
    if (this.option.text?.length) {
      return this.post.isTranslated && this.option.textTranslated ? this.option.textTranslated : this.option.text;
    }

    return __('Option {number}', { number: this.option.index + 1 });
  }

  @action
  vote(event) {
    event.preventDefault();

    if (event.target.closest('.poll_photo')) {
      return;
    }

    if (
      this.args.isSharedPost ||
      this.post.previewOnlyPost ||
      this.post.poll.closed ||
      this.post.isGroupPreview ||
      this.post.scheduled ||
      this.post.pending ||
      this.post.customInteraction
    ) {
      return;
    }

    if (this.option.selected) {
      dispatcher.dispatch('poll', 'deleteVote', this.post);
    } else {
      dispatcher.dispatch('poll', 'vote', this.post, this.option.index);
    }
  }
}

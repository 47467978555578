import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { FeedTypes, Theme } from 'mewe/constants';
import DiscoverApi from 'mewe/api/discover-api';
import BookmarksApi from 'mewe/api/bookmarks-api';
import { openFtueRecommendations, showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import dispatcher from 'mewe/dispatcher';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import { inject as service } from '@ember/service';

export default class MwPostOptions extends Component {
  @service dynamicDialogs;
  @service router;
  @service account;
  @reads('args.post') post;

  @computed('feedTheme', 'post.eventId')
  get canShowAlbumOption() {
    // Should not show ablum option if the post from the event #SG-33727
    return this.feedTheme !== Theme.EVENT && isEmpty(this.post.eventId);
  }

  @computed('post.{isGroupPost,pending}', 'post.group.{groupMuted,isOwnerAdmin}')
  get showMuteGroupPosts() {
    return (
      this.post.isGroupPost &&
      this.post.getGroupIfMember() &&
      !this.post.pending &&
      !this.post.scheduled &&
      !this.post.group.groupMuted &&
      !this.post.group.isOwnerAdmin
    );
  }

  /*
   * user can feature his posts from his own profile feed
   * user cannot feature any post in his myworld feed, even his own
   * user cannot feature any post in all GROUPS feed, even his own, even in posts of his owned groups
   * user cannot feature any post in all PAGES feed, even his own, even in posts of his owned pages
   */
  @computed('post.canFeature', 'args.feedTheme', 'args.inMediaDialog')
  get showFeaturePostOption() {
    //copy post's permission when opened in media dialog because of SG-25814
    if (this.args.inMediaDialog) return this.post.permissions?.canFeature;
    // not allowed to Feature post in aggregated feeds, not showing "Featured post"
    if (
      this.args.feedTheme === Theme.GROUPS ||
      this.args.feedTheme === Theme.PAGES ||
      this.args.feedTheme === FeedTypes.ALL ||
      this.args.feedTheme === Theme.CONTACTS
    ) {
      return false;
    }

    // display Feature Post in your own profile feed
    // don't display Feature Post in Profile feed, even his own posts
    if (this.post.canFeature && this.args.feedTheme === Theme.PROFILE) return true;

    return this.post.canFeature; // post permission from backend
  }

  @action
  openFtueCategories() {
    openFtueRecommendations(this.dynamicDialogs, 'post-options');
  }

  @action
  hidePost() {
    const postId = this.post.id;

    DiscoverApi.ignoreContent('post', postId).then(() => {
      const post = ds.feeds[FeedTypes.DISCOVER]?.posts?.find((p) => p.id === postId);
      if (post) {
        post.set('temporarilyHidden', 'singlePost');
      }

      this.dynamicDialogs.close(this.args.compArgs.dialogId); // in case the hiding action was done in MD
    });

    this.args.closePostDropdown();
  }

  @action
  hideCommunity() {
    const post = this.post;
    const ignoreType = post.postedByPage ? 'page' : 'group';
    const ignoreId = post.postedByPage ? post.pageId : post.groupId;

    DiscoverApi.ignoreContent(ignoreType, ignoreId).then(() => {
      const feed = ds.feeds[FeedTypes.DISCOVER];
      const posts = feed?.posts?.filter((p) => {
        if (ignoreType === 'group') {
          return p.groupId === ignoreId;
        } else {
          return p.pageId === ignoreId;
        }
      });
      posts.forEach((p) => p.set('temporarilyHidden', 'wholeCommunity'));

      this.dynamicDialogs.close(this.args.compArgs.dialogId); // in case the hiding action was done in MD
    });

    this.args.closePostDropdown();
  }

  @action
  joinOrFollow() {
    if (this.post.postedByPage) {
      dispatcher.dispatch('pages', 'toggleFollow', this.post.page, true, true);
    } else {
      showGroupPreviewDialog(this.dynamicDialogs, this.post.group, { isGroupPreview: true });
    }

    this.args.closePostDropdown();
  }

  @action
  toggleFavoriteeUser() {}

  @action
  copyProfileLink() {
    MiscellaneousUtils.copyToClipboard(`${EnvironmentUtils.getHost()}/${this.post.owner.publicLinkId}`);
    this.args.closePostDropdown();
  }

  @action
  toggleBookmark() {
    if (this.post.bookmarkId) {

      BookmarksApi.removeBookmark(this.post.bookmarkId)
        .then(() => {
          this.post.bookmarkId = null;
          this.args.closePostDropdown();
          FunctionalUtils.info('Post removed from bookmarks.');
        })
        .catch(() => {
          FunctionalUtils.showDefaultErrorMessage();
        });

    } else {

      const params = {
        postId: this.post.postItemId,
        context: this.args.context
      };

      BookmarksApi.addBookmark(params)
        .then((data) => {
          this.post.bookmarkId = data.bookmarkId;
          this.args.closePostDropdown();
          FunctionalUtils.message(
            `Post added to bookmarks. <u id='error-action'>View bookmarks.<\/u>`,
            'info',
            () => this.router.transitionTo('app.publicid.bookmarks', this.account.activeUser.profileId));
        })
        .catch(() => {
          FunctionalUtils.showDefaultErrorMessage();
        });

    }
  }
}

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TaskManager extends Component {
  @service('tasks') TaskService;

  @tracked tasks;

  constructor() {
    super(...arguments);
    this.tasks = this.TaskService.getTasks();
  }

  @action
  clear(task) {
    task.clear();
  }
  @action
  cancel(task) {
    task.abort();
  }
  @action
  responses(task) {
    console.clear();
    console.log(task.id, 'responses: ', task.responses);
  }
  @action
  xhrs(task) {
    console.clear();
    console.log(task.id, 'xhrs: ', task.xhrs);
  }
}

import BookmarksApi from 'mewe/api/bookmarks-api';
import { fetchUtil, unsetFlag, toBookmark } from 'mewe/fetchers/utils';
import { handleOtherStores } from 'mewe/fetchers/fetch-feed';
import { tap } from 'mewe/utils/fp';
import { ds } from 'mewe/stores/ds';
import { storeCollectionItems, pushCollectionItems, setNextPage } from 'mewe/stores/models/collection';

export const fetchBookmarks = (params) =>
  fetchUtil(BookmarksApi.getBookmarks(params), ds.bookmarks)
    .then(tap(setNextPage(ds.bookmarks)))
    .then(tap(handleOtherStores))
    .then((data) => {
      const storeOrPush = params?.nextPage ? pushCollectionItems : storeCollectionItems;
      storeOrPush(ds.bookmarks, data.bookmarks.map(toBookmark));
      return data;
    })
    .then(unsetFlag(ds.bookmarks));
/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import {
  setupEmbeddedVideoPlayer,
  teardownEmbeddedVideoPlayer,
  getVideoSourcesFromPost,
  hlsPlaylistLoader,
  hlsFragmentLoader,
} from 'mewe/utils/video-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import Hls from 'hls.js/dist/hls.light.js';
import { guidFor } from '@ember/object/internals';
import storage from 'mewe/shared/storage';
import { inject as service } from '@ember/service';

export default class MwVideoPlayer extends Component {
  @service inViewport;

  @tracked isPlaying = false;
  @tracked isVideoInViewport = false;
  @tracked videoPlayPromise = null;

  elementId = `player_${guidFor(this)}`;
  setupEmbeddedVideoPlayer = setupEmbeddedVideoPlayer;
  teardownEmbeddedVideoPlayer = teardownEmbeddedVideoPlayer;
  hls = null;
  imageSize;
  sources;
  videoEl;

  constructor() {
    super(...arguments);
    this.imageSize = this.args.imageSize ? this.args.imageSize : '800x800';

    this.sources = getVideoSourcesFromPost(this.args.media);
  }

  get videoFeedAutoPlaySetting() {
    return storage.get(storage.keys.videoFeedAutoPlaySetting);
  }

  @action
  onInsert(element) {
    this.videoEl = element.querySelector('video');
    if (!this.videoEl) {
      return;
    }
    this.videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;

    this.videoEl.onplaying = () => {
      this.isPlaying = true;
    };
    this.videoEl.onpause = () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.isPlaying = false;
    };
    this.videoEl.onvolumechange = () => {
      storage.set(storage.keys.unmuteVideos, !this.videoEl.muted);
    };

    // HLS will be used when hls source is first.
    if (this.sources[0]?.type == 'video/hls') {
      this.enableHlsPlayback(this.sources[0].src);
    }

    if (this.args.onlyPlay) {
      this.videoEl.oncanplay = () => {
        if (this.videoFeedAutoPlaySetting === null || this.videoFeedAutoPlaySetting) {
          this.videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;
          this.videoPlayPromise = this.videoEl.play();

          if (this.videoPlayPromise) {
            this.videoPlayPromise.catch((e) => {
              if (e.code === DOMException.ABORT_ERR) {
                return;
              }
              throw e;
            });
          }
        }
      };
    }

    this.setupInViewportAutplayDetect();
  }

  setupInViewportAutplayDetect() {
    const loader = document.getElementById(this.elementId);
    const viewportConfig = {
      viewportSpy: true,
      intersectionThreshold: 1,
    };
    const { onEnter, onExit } = this.inViewport.watchElement(loader, viewportConfig);

    if (this.args.autoplay ?? this.videoFeedAutoPlaySetting) {
      onEnter(this.didEnterViewport.bind(this));
    }

    onExit(this.didExitViewport.bind(this));
  }

  didEnterViewport() {
    if (this.videoEl) {
      this.videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;
      if (this.videoEl.paused && !this.isPlaying && !document.webkitCurrentFullScreenElement) {
        this.videoEl.currentTime = 0;
        // this.videoEl.muted = true; // remove bug no first intercation
        this.videoPlayPromise = this.videoEl.play();

        if (this.videoPlayPromise) {
          this.videoPlayPromise.catch((e) => {
            if (e.code === DOMException.ABORT_ERR) {
              return;
            }
            throw e;
          });
        }
      }
    }
  }

  didExitViewport() {
    if (this.videoEl) {
      this.videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;

      if (!this.videoEl.paused && this.isPlaying && !document.webkitCurrentFullScreenElement) {
        if (this.videoPlayPromise) {
          this.videoPlayPromise.then(() => {
            this.videoEl.pause();
            this.videoPlayPromise = null;
          });
        } else if (!this.videoFeedAutoPlaySetting) {
          this.videoEl.pause();
        }
      }
    }
  }

  willDestroy = () => {
    const loader = document.getElementById(this.elementId);
    this.inViewport.stopWatching(loader);
  };

  enableHlsPlayback(source) {
    if (!this.videoEl) {
      console.error('Hsl playback - missing video element');
    }

    source = source + (~source.indexOf('?') ? '&' : '?') + 'hlsGet=true';
    if (!this.hls) {
      this.hls = new Hls({
        pLoader: hlsPlaylistLoader,
        fLoader: hlsFragmentLoader,
        maxBufferLength: 20,
        maxMaxBufferLength: 30,
        maxBufferSize: 5,
        capLevelToPlayerSize: true,
      });
    }

    this.hls.loadSource(source);
    this.hls.attachMedia(this.videoEl);
  }

  get posterStyle() {
    return htmlSafe(`background-image: url('${this.poster}');`);
  }

  get poster() {
    const media = this.args.media.photo || this.args.media.video;
    let imgUrl = media?._links?.img?.href;

    if (!imgUrl) {
      return htmlSafe('');
    }

    imgUrl = imgUrl.replace('{imageSize}', this.imageSize).replace('{static}', '1');

    return htmlSafe(EnvironmentUtils.getImgHost(true, this.args.isPublicContent) + imgUrl);
  }
}

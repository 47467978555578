import { action } from '@ember/object';
import Component from '@glimmer/component';

import PostUtils from 'mewe/utils/post-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwPostReferenceLink extends Component {
  constructor() {
    super(...arguments);

    // usecase: shared post can be in different scope than its origin
    // if it will not be passed and needs to be calculated basing on post data
    if (this.args.scope) {
      this.scope = this.args.scope;
    } else {
      const { scope } = PostUtils.getPostScopeAndId(this.args.post);
      this.scope = scope;
    }
  }

  get isClickable() {
    // usecase: post not present when shared post was deleted
    return this.args.post && !this.args.post.isPublicContent;
  }

  @action
  clickHandler() {
    if (!this.isClickable) return;

    dispatcher.dispatch('feed', 'openSinglePost', {
      isSharedPost: true,
      isRefPost: true,
      dontOpenMediaDialog: true,
      scope: this.scope,
      postItemId: this.args.post.postItemId,
      pageId: this.args.post.pageId,
      groupId: this.args.post.groupId,
      isPostFromUrl: true,
    });
  }
}

import Component from '@glimmer/component';
import dispatcher from 'mewe/dispatcher';
import { computed } from '@ember/object';
import { action } from '@ember/object';

import NotificationsStore from 'mewe/stores/notifications-store';

export default class MwPostApproval extends Component {
  @computed('args.post.eventId')
  get postApprovalQuestionMarkText() {
    if (this.args.post.eventId)
      return __(
        'This was posted by a member with Limited Permission, which means that the rest of the event members cannot see it until you approve it. You can change the permissions for this member in the "Permissions" tab in event Settings.'
      );
    else
      return __(
        'This was posted by a member with Limited Permission, which means that the rest of the group members cannot see it until you approve it. You can change the permissions for this member in the "Members" tab.'
      );
  }

  approveOrReject(approve) {
    const post = this.args.post;
    const action = approve ? 'approvePost' : 'rejectPost';

    const callback = () => {
      if (this.args.postDialog) {
        this.args.postDialog.closeDialog();
      }
      NotificationsStore.send('refreshStore', false);
    };

    dispatcher.dispatch('feed', action, post.postItemId, post.eventId, post.group.id, callback);
  }

  @action
  approve() {
    this.approveOrReject(true);
  }

  @action
  reject() {
    this.approveOrReject(false);
  }
}

import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

import Model from 'mewe/utils/store-utils/model/model.js';
import { attr, hasMany, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { Post } from './post-model';
import Comment from './comment-model';
import User from './simple-user-model';
import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import { emojisList } from 'mewe/stores/models/mixins/model-emojis-list';
import { NotificationTypes } from 'mewe/constants';
import CurrentUserStore from 'mewe/stores/current-user-store';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';

import { getActingUserHTML, getActingUsersHTML } from 'mewe/utils/notifications-text-utils';
import { findGifUrl } from 'mewe/utils/gif-utils';
import { escape, compact } from 'lodash';
import { inject as service } from '@ember/service';

let titleHash = {
  one_poll_vote_poll: (p) => __('{userName} responded to your poll: {pollQuestion}', p),
  many_poll_vote_poll: (p) => __('{userNames} responded to your poll: {pollQuestion}', p),

  one_poll_ended_poll: (p) => __('Poll "{pollQuestion}" has ended. Check out the results', p),

  one_newPageFollower_text: (p) => __('{userName} followed your page', p),
  many_newPageFollower_text: (p) => __('{userNames} followed your page', p),

  many_comment_photo: (p) => __('{userNames} commented on your photo: ', p),
  many_comment_gif: (p) => __('{userNames} commented on your GIF: ', p),
  many_comment_link: (p) => __('{userNames} commented on your link: ', p),
  many_comment_document: (p) => __('{userNames} commented on your document: ', p),
  many_comment_video: (p) => __('{userNames} commented on your video: ', p),
  many_comment_event: (p) => __('{userNames} commented on your event: ', p),
  many_comment_photos: (p) => __('{userNames} commented on your photos: ', p),
  many_comment_documents: (p) => __('{userNames} commented on your documents: ', p),
  many_comment_text: (p) => __('{userNames} commented on your post: ', p),
  many_comment_reply: (p) => __('{userNames} replied to your comment: ', p),
  many_comment_poll: (p) => __('{userNames} commented on your poll: {pollQuestion}', p),

  one_comment_photo: (p) => __('{userName} commented on your photo: ', p),
  one_comment_gif: (p) => __('{userName} commented on your GIF: ', p),
  one_comment_link: (p) => __('{userName} commented on your link: ', p),
  one_comment_document: (p) => __('{userName} commented on your document: ', p),
  one_comment_video: (p) => __('{userName} commented on your video: ', p),
  one_comment_event: (p) => __('{userName} commented on your event: ', p),
  one_comment_photos: (p) => __('{userName} commented on your photos: ', p),
  one_comment_documents: (p) => __('{userName} commented on your documents: ', p),
  one_comment_text: (p) => __('{userName} commented on your post: ', p),
  one_comment_reply: (p) => __('{userName} replied to your comment: ', p),
  one_comment_poll: (p) => __('{userName} commented on your poll: {pollQuestion}', p),

  one_emojis_reply: (p) => __('{userName} emojied your reply: ', p),
  one_emojis_comment: (p) => __('{userName} emojied your comment: ', p),
  one_emojis_text: (p) => __('{userName} emojied your post: ', p),
  one_emojis_photo: (p) => __('{userName} emojied your photo: ', p),
  one_emojis_gif: (p) => __('{userName} emojied your GIF: ', p),
  one_emojis_link: (p) => __('{userName} emojied your link: ', p),
  one_emojis_document: (p) => __('{userName} emojied your document: ', p),
  one_emojis_video: (p) => __('{userName} emojied your video: ', p),
  one_emojis_event: (p) => __('{userName} emojied your event {eventName}', p),
  one_emojis_photos: (p) => __('{userName} emojied your photos: ', p),
  one_emojis_documents: (p) => __('{userName} emojied your documents: ', p),
  one_emojis_poll: (p) => __('{userName} emojied your poll: {pollQuestion}', p),

  many_emojis_reply: (p) => __('{userNames} emojied your reply: ', p),
  many_emojis_comment: (p) => __('{userNames} emojied your comment: ', p),
  many_emojis_text: (p) => __('{userNames} emojied your post: ', p),
  many_emojis_photo: (p) => __('{userNames} emojied your photo: ', p),
  many_emojis_gif: (p) => __('{userNames} emojied your GIF: ', p),
  many_emojis_link: (p) => __('{userNames} emojied your link: ', p),
  many_emojis_document: (p) => __('{userNames} emojied your document: ', p),
  many_emojis_video: (p) => __('{userNames} emojied your video: ', p),
  many_emojis_event: (p) => __('{userNames} emojied your event {eventName}', p),
  many_emojis_photos: (p) => __('{userNames} emojied your photos: ', p),
  many_emojis_documents: (p) => __('{userNames} emojied your documents: ', p),
  many_emojis_poll: (p) => __('{userNames} emojied your poll: {pollQuestion}', p),
};

var model = Model.extend(modelEmojisReady, {
  account: service(),

  id: attr('string'),

  notificationType: attr('string'),

  createdAt: attr('string'),

  updatedAt: attr('string'),

  actingUsers: hasMany(User),

  actingUsersCount: attr('number'),

  post: belongsTo(Post),

  comment: belongsTo(Comment),

  seen: attr('boolean'),

  seenAt: attr('string'),

  visited: attr('boolean'),

  pageId: attr('string'),

  avatar: computed('actingUsers.[]', function () {
    let firstUser = this.get('actingUsers')[0];
    return firstUser ? firstUser.get('_links.avatar.href') : null;
  }),

  image: computed('post.medias.[]', function () {
    let firstMedia = this.get('post.medias')?.[0];
    return firstMedia ? firstMedia.photo._links.img.href : null;
  }),

  postedByMe: computed('post', 'account.activeUser', function () {
    return this.post && !this.post.postedByPage && this.account.activeUser.id == this.post.userId;
  }),

  postedByMyPage: computed('pageId', 'post', function () {
    return this.post && this.post.postedByPage && this.pageId == this.post.pageId;
  }),

  oneUser: computed('actingUsers', function () {
    return this.actingUsers.length <= 1;
  }),

  title: computed('post', 'emojisReady', function () {
    let notificationTargetType = {
      emojis: this.comment ? (this.comment.replyTo ? 'reply' : 'comment') : (this.post && this.post.postType) || 'text',
      comment: this.comment
        ? this.comment.replyTo
          ? 'reply'
          : (this.post && this.post.postType) || 'text'
        : (this.post && this.post.postType) || 'text',
      newPageFollower: 'text',
      poll_vote: 'poll',
      poll_ended: 'poll',
    };

    // key :: one|many _ notification type _ notification target type
    let key = [
      this.oneUser ? 'one' : 'many',
      this.notificationType,
      notificationTargetType[this.notificationType],
    ].join('_');

    assert('There is no such title for key ' + key, titleHash[key]);

    assert(
      '"actingUsers" array is empty, when "actingUsersCount" is 1: ',
      this.actingUsersCount == 1 ? this.actingUsers.length != 0 : true
    );

    let params = this.oneUser
      ? { userName: getActingUserHTML(this) }
      : { userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), this) };

    if (this.post && this.post.poll) params.pollQuestion = EmojisParser.toDisplay(escape(this.post.poll.question));

    return titleHash[key] ? titleHash[key](params) : '';
  }),

  snippet: computed('comment', 'post', 'emojisReady', function () {
    return this.comment ? this.comment.textDisplay : this.post ? this.post.textDisplay : null;
  }),

  emojis: computed('post', 'comment', 'emojisReady', function () {
    return this.notificationType == NotificationTypes.emojis
      ? compact([this.comment || this.post])
          .map((target) => target.emojis.emojiCounts)
          .map((counts) => emojisList(counts))
          .shift()
      : null;
  }),

  isGif: computed('comment', function () {
    return !!findGifUrl(this.get('comment.link._links.url.href'));
  }),
});

model.reopenClass({
  resourceName: 'pageNotification',
});

export default model;

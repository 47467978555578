export const storeData = {
  items: [
    {
      itemId: 'donation-monthly',
      productIdBase: 'com.mewe.store.donation',
      tiers: [
        { tierId: 'tier1', value: 5, price: '5' },
        { tierId: 'tier2', value: 10, price: '10' },
        { tierId: 'tier3', value: 25, price: '25' },
        { tierId: 'tier4', value: 50, price: '50' },
        { tierId: 'tier5', value: 100, price: '100' },
        { tierId: 'tier6', value: 250, price: '250' },
        { tierId: 'tier7', value: 500, price: '500' },
        { tierId: 'tier8', value: 1000, price: '1000' },
      ],
      optionalSubState: 'onetime',
      subscriptionPeriods: [{ name: 'month' }],
      category: 'Support MeWe',
      name: 'I Support MeWe!',
      class: 'mewe-donation',
      description: 'Your support will help the growth of MeWe.',
      coverImage: '/assets/store/store-item-donation.png',
    },
    {
      itemId: 'stickers-christmas-merry',
      productIdBase: 'com.mewe.store.stickers.christmasmerry',
      previewStickers: [
        { package: 'stickers-christmas-merry', id: 'Letters' },
        { package: 'stickers-christmas-merry', id: 'GingerbreadHouse' },
        { package: 'stickers-christmas-merry', id: 'Framed' },
        { package: 'stickers-christmas-merry', id: 'DefineNaughty' },
        { package: 'stickers-christmas-merry', id: 'SnowGlobe' },
      ],
      category: 'Stickers',
      name: 'Christmas Merry Making',
      class: 'store-stickers',
      description: 'Set of 40 stickers',
      price: '2.49',
      packLogo: '/assets/stickers/christmas-merry.png',
      packLogoDark: '/assets/stickers/christmas-merry.png',
      packLogoWidth: 130,
      stickers: [
        { id: 'Ballerina', package: 'stickers-christmas-merry' },
        { id: 'Bear1', package: 'stickers-christmas-merry' },
        { id: 'Bear2', package: 'stickers-christmas-merry' },
        { id: 'BeNaughty', package: 'stickers-christmas-merry' },
        { id: 'BeNice', package: 'stickers-christmas-merry' },
        { id: 'Calories', package: 'stickers-christmas-merry' },
        { id: 'CandyCane', package: 'stickers-christmas-merry' },
        { id: 'Car1', package: 'stickers-christmas-merry' },
        { id: 'Car2', package: 'stickers-christmas-merry' },
        { id: 'Cold', package: 'stickers-christmas-merry' },
        { id: 'Cookies', package: 'stickers-christmas-merry' },
        { id: 'Deer1', package: 'stickers-christmas-merry' },
        { id: 'Deer2', package: 'stickers-christmas-merry' },
        { id: 'DefineNaughty', package: 'stickers-christmas-merry' },
        { id: 'FiggyPudding', package: 'stickers-christmas-merry' },
        { id: 'Framed', package: 'stickers-christmas-merry' },
        { id: 'GetLit', package: 'stickers-christmas-merry' },
        { id: 'GingerbreadHouse', package: 'stickers-christmas-merry' },
        { id: 'Ho', package: 'stickers-christmas-merry' },
        { id: 'HotCocoa', package: 'stickers-christmas-merry' },
        { id: 'Jolly', package: 'stickers-christmas-merry' },
        { id: 'Joy', package: 'stickers-christmas-merry' },
        { id: 'Judging', package: 'stickers-christmas-merry' },
        { id: 'Letters', package: 'stickers-christmas-merry' },
        { id: 'LitTree', package: 'stickers-christmas-merry' },
        { id: 'MouseKing', package: 'stickers-christmas-merry' },
        { id: 'Nutcracker', package: 'stickers-christmas-merry' },
        { id: 'OhSnap', package: 'stickers-christmas-merry' },
        { id: 'Presents', package: 'stickers-christmas-merry' },
        { id: 'Sleigh', package: 'stickers-christmas-merry' },
        { id: 'SnowGlobe', package: 'stickers-christmas-merry' },
        { id: 'Snowman', package: 'stickers-christmas-merry' },
        { id: 'Stocking', package: 'stickers-christmas-merry' },
        { id: 'TheCat', package: 'stickers-christmas-merry' },
        { id: 'TheDog', package: 'stickers-christmas-merry' },
        { id: 'Tinsel', package: 'stickers-christmas-merry' },
        { id: 'Truck', package: 'stickers-christmas-merry' },
        { id: 'Twisted', package: 'stickers-christmas-merry' },
        { id: 'UglySweater', package: 'stickers-christmas-merry' },
        { id: 'Wine', package: 'stickers-christmas-merry' },
      ],
      previewStickersTwo: [
        { package: 'stickers-christmas-merry', id: 'Letters' },
        { package: 'stickers-christmas-merry', id: 'GingerbreadHouse' },
      ],
    },
    {
      itemId: 'stickers-naughty-dozen',
      productIdBase: 'com.mewe.store.stickers.naughtydozen',
      previewStickers: [
        { package: 'stickers-naughty-dozen', id: 'SilentNight' },
        { package: 'stickers-naughty-dozen', id: 'Goodish' },
        { package: 'stickers-naughty-dozen', id: 'Judge' },
        { package: 'stickers-naughty-dozen', id: 'Gnome' },
        { package: 'stickers-naughty-dozen', id: 'Adorable' },
      ],
      category: 'Stickers',
      name: 'The Naughty Dozen',
      class: 'store-stickers',
      description: 'Set of 12 stickers',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/naughty-dozen.png',
      packLogoDark: '/assets/stickers/naughty-dozen.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'Adorable', package: 'stickers-naughty-dozen' },
        { id: 'CheckList', package: 'stickers-naughty-dozen' },
        { id: 'GetLit', package: 'stickers-naughty-dozen' },
        { id: 'Gnome', package: 'stickers-naughty-dozen' },
        { id: 'Goodish', package: 'stickers-naughty-dozen' },
        { id: 'HeDidIt', package: 'stickers-naughty-dozen' },
        { id: 'Judge', package: 'stickers-naughty-dozen' },
        { id: 'Nevermind', package: 'stickers-naughty-dozen' },
        { id: 'Phase', package: 'stickers-naughty-dozen' },
        { id: 'Regret', package: 'stickers-naughty-dozen' },
        { id: 'SheDidIt', package: 'stickers-naughty-dozen' },
        { id: 'SilentNight', package: 'stickers-naughty-dozen' },
      ],
      previewStickersTwo: [
        { package: 'stickers-naughty-dozen', id: 'SilentNight' },
        { package: 'stickers-naughty-dozen', id: 'Goodish' },
      ],
    },
    {
      itemId: 'stickers-spring-fling',
      productIdBase: 'com.mewe.store.stickers.spring-fling',
      previewStickers: [
        { package: 'stickers-spring-fling', id: 'play-ball' },
        { package: 'stickers-spring-fling', id: 'cool-chick' },
        { package: 'stickers-spring-fling', id: 'good-luck' },
        { package: 'stickers-spring-fling', id: 'kiss-me-im-irish' },
        { package: 'stickers-spring-fling', id: 'what' },
      ],
      category: 'Stickers',
      name: 'Spring Fling',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/spring-fling-logo.png',
      packLogoDark: '/assets/stickers/logo/spring-fling-logo.png',
      packLogoWidth: 130,
      stickers: [
        { id: 'play-ball', package: 'stickers-spring-fling' },
        { id: 'cool-chick', package: 'stickers-spring-fling' },
        { id: 'good-luck', package: 'stickers-spring-fling' },
        { id: 'kiss-me-im-irish', package: 'stickers-spring-fling' },
        { id: 'what', package: 'stickers-spring-fling' },
        { id: 'fancy-egg-1', package: 'stickers-spring-fling' },
        { id: 'fancy-egg-2', package: 'stickers-spring-fling' },
        { id: 'butt-hurts', package: 'stickers-spring-fling' },
        { id: 'peeps', package: 'stickers-spring-fling' },
        { id: 'happy-mothers-day', package: 'stickers-spring-fling' },
        { id: 'buzz-off', package: 'stickers-spring-fling' },
        { id: 'happy-passover', package: 'stickers-spring-fling' },
        { id: 'viva-mexico', package: 'stickers-spring-fling' },
        { id: 'easter-cross', package: 'stickers-spring-fling' },
        { id: 'flower-pots', package: 'stickers-spring-fling' },
        { id: 'beautiful', package: 'stickers-spring-fling' },
        { id: 'pretty-lady', package: 'stickers-spring-fling' },
        { id: 'nice-day', package: 'stickers-spring-fling' },
        { id: 'unicorn-flowers', package: 'stickers-spring-fling' },
        { id: 'peace-dove', package: 'stickers-spring-fling' },
        { id: 'easter-basket', package: 'stickers-spring-fling' },
        { id: 'happy-st-pats', package: 'stickers-spring-fling' },
        { id: 'happy-easter', package: 'stickers-spring-fling' },
        { id: 'happy-cinco-de-mayo', package: 'stickers-spring-fling' },
        { id: 'spring-fever', package: 'stickers-spring-fling' },
        { id: 'butterfly-1', package: 'stickers-spring-fling' },
        { id: 'butterfly-2', package: 'stickers-spring-fling' },
        { id: 'smiley-with-daisy-eyes', package: 'stickers-spring-fling' },
        { id: 'easter-bunny', package: 'stickers-spring-fling' },
        { id: 'leprechaun', package: 'stickers-spring-fling' },
        { id: 'spring-break', package: 'stickers-spring-fling' },
        { id: 'april-showers', package: 'stickers-spring-fling' },
        { id: 'hello', package: 'stickers-spring-fling' },
        { id: 'love-you', package: 'stickers-spring-fling' },
        { id: 'bluebird', package: 'stickers-spring-fling' },
        { id: 'flower-1', package: 'stickers-spring-fling' },
        { id: 'flower-2', package: 'stickers-spring-fling' },
        { id: 'flower-bunch', package: 'stickers-spring-fling' },
        { id: 'rain-rain-go-away', package: 'stickers-spring-fling' },
        { id: 'lily-pad-frog', package: 'stickers-spring-fling' },
      ],
      previewStickersTwo: [
        { package: 'stickers-spring-fling', id: 'peace-dove' },
        { package: 'stickers-spring-fling', id: 'flower-2' },
      ],
    },
    {
      itemId: 'stickers-winter-joy',
      productIdBase: 'com.mewe.store.stickers.winterjoy',
      previewStickers: [
        { package: 'stickers-winter-joy', id: 'hello' },
        { package: 'stickers-winter-joy', id: 'cocoa' },
        { package: 'stickers-winter-joy', id: 'christmas-tree' },
        { package: 'stickers-winter-joy', id: 'merry-christmas' },
        { package: 'stickers-winter-joy', id: 'no-peeking' },
      ],
      category: 'Stickers',
      name: 'Winter Joy',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/winter-joy-logo.png',
      packLogoDark: '/assets/stickers/logo/winter-joy-logo.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'antlers', package: 'stickers-winter-joy' },
        { id: 'no-peeking', package: 'stickers-winter-joy' },
        { id: 'kiss-me', package: 'stickers-winter-joy' },
        { id: 'cocoa', package: 'stickers-winter-joy' },
        { id: 'bah-humbug', package: 'stickers-winter-joy' },
        { id: 'reindeer', package: 'stickers-winter-joy' },
        { id: 'lit', package: 'stickers-winter-joy' },
        { id: 'ho-ho-ho', package: 'stickers-winter-joy' },
        { id: 'ornaments', package: 'stickers-winter-joy' },
        { id: 'hello', package: 'stickers-winter-joy' },
        { id: 'penguin', package: 'stickers-winter-joy' },
        { id: 'happy-elf', package: 'stickers-winter-joy' },
        { id: 'santamoji', package: 'stickers-winter-joy' },
        { id: 'star', package: 'stickers-winter-joy' },
        { id: 'cool', package: 'stickers-winter-joy' },
        { id: 'cookies', package: 'stickers-winter-joy' },
        { id: 'cold-outside', package: 'stickers-winter-joy' },
        { id: 'pointsettia', package: 'stickers-winter-joy' },
        { id: 'pines', package: 'stickers-winter-joy' },
        { id: 'gingerbread-house', package: 'stickers-winter-joy' },
        { id: 'fml', package: 'stickers-winter-joy' },
        { id: 'santa-hat', package: 'stickers-winter-joy' },
        { id: 'naughty-nice', package: 'stickers-winter-joy' },
        { id: 'snowflake', package: 'stickers-winter-joy' },
        { id: 'snowman', package: 'stickers-winter-joy' },
        { id: 'feliz-navidad', package: 'stickers-winter-joy' },
        { id: 'merry-christmas', package: 'stickers-winter-joy' },
        { id: 'happy-holidays', package: 'stickers-winter-joy' },
        { id: 'slopes', package: 'stickers-winter-joy' },
        { id: 'elf-shoes', package: 'stickers-winter-joy' },
        { id: 'elf-hat', package: 'stickers-winter-joy' },
        { id: 'sweet', package: 'stickers-winter-joy' },
        { id: 'stocking', package: 'stickers-winter-joy' },
        { id: 'presents', package: 'stickers-winter-joy' },
        { id: 'christmas-tree', package: 'stickers-winter-joy' },
        { id: 'menorah', package: 'stickers-winter-joy' },
        { id: 'light-frame', package: 'stickers-winter-joy' },
        { id: 'happy-new-year', package: 'stickers-winter-joy' },
        { id: 'champagne', package: 'stickers-winter-joy' },
        { id: 'santa', package: 'stickers-winter-joy' },
      ],
      previewStickersTwo: [
        { package: 'stickers-winter-joy', id: 'penguin' },
        { package: 'stickers-winter-joy', id: 'snowman' },
      ],
    },
    {
      itemId: 'stickers-donuts-jelly',
      productIdBase: 'com.mewe.store.stickers.donutsjelly',
      previewStickers: [
        { package: 'stickers-donuts-jelly', id: 'DonutHate' },
        { package: 'stickers-donuts-jelly', id: 'Love' },
        { package: 'stickers-donuts-jelly', id: 'Sleepy' },
        { package: 'stickers-donuts-jelly', id: 'Jelly' },
        { package: 'stickers-donuts-jelly', id: 'Monster' },
      ],
      category: 'Stickers',
      name: 'Donut Be Jelly',
      class: 'store-stickers',
      description: 'Set of 20 stickers by Wendy Tapper',
      price: '2.99',
      packLogo: '/assets/stickers/logo/donuts-jelly.png',
      packLogoDark: '/assets/stickers/logo/donuts-jelly.png',
      packLogoWidth: 130,
      stickers: [
        { id: 'DonutHate', package: 'stickers-donuts-jelly' },
        { id: 'Love', package: 'stickers-donuts-jelly' },
        { id: 'ROFL', package: 'stickers-donuts-jelly' },
        { id: 'Sleepy', package: 'stickers-donuts-jelly' },
        { id: 'Smile', package: 'stickers-donuts-jelly' },
        { id: 'Star', package: 'stickers-donuts-jelly' },
        { id: 'Winky', package: 'stickers-donuts-jelly' },
        { id: 'BerrySweet', package: 'stickers-donuts-jelly' },
        { id: 'BiteMe', package: 'stickers-donuts-jelly' },
        { id: 'Celebration', package: 'stickers-donuts-jelly' },
        { id: 'Freeze', package: 'stickers-donuts-jelly' },
        { id: 'GimmeSugar', package: 'stickers-donuts-jelly' },
        { id: 'Jelly', package: 'stickers-donuts-jelly' },
        { id: 'Monster', package: 'stickers-donuts-jelly' },
        { id: 'Nuts', package: 'stickers-donuts-jelly' },
        { id: 'OMG', package: 'stickers-donuts-jelly' },
        { id: 'Pukey', package: 'stickers-donuts-jelly' },
        { id: 'Sad', package: 'stickers-donuts-jelly' },
        { id: 'Unicorn', package: 'stickers-donuts-jelly' },
        { id: 'WTF', package: 'stickers-donuts-jelly' },
      ],
      previewStickersTwo: [
        { id: 'Jelly', package: 'stickers-donuts-jelly' },
        { id: 'Nuts', package: 'stickers-donuts-jelly' },
      ],
    },
    {
      itemId: 'stickers-smiley-guy',
      productIdBase: 'com.mewe.store.stickers.smileyguy',
      previewStickers: [
        { package: 'stickers-smiley-guy', id: 'fml' },
        { package: 'stickers-smiley-guy', id: 'omg' },
        { package: 'stickers-smiley-guy', id: 'winning' },
        { package: 'stickers-smiley-guy', id: 'feed-me' },
        { package: 'stickers-smiley-guy', id: 'whats-up' },
      ],
      category: 'Stickers',
      name: 'Smiley Guy',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/smiley-guy.png',
      packLogoDark: '/assets/stickers/logo/smiley-guy.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'no', package: 'stickers-smiley-guy' },
        { id: 'tgif', package: 'stickers-smiley-guy' },
        { id: 'fomo', package: 'stickers-smiley-guy' },
        { id: 'love-ya', package: 'stickers-smiley-guy' },
        { id: 'fml', package: 'stickers-smiley-guy' },
        { id: 'miss-you', package: 'stickers-smiley-guy' },
        { id: 'whats-up', package: 'stickers-smiley-guy' },
        { id: 'feed-me', package: 'stickers-smiley-guy' },
        { id: 'winning', package: 'stickers-smiley-guy' },
        { id: 'peace-out', package: 'stickers-smiley-guy' },
        { id: 'ttyl', package: 'stickers-smiley-guy' },
        { id: 'please', package: 'stickers-smiley-guy' },
        { id: 'thank-you', package: 'stickers-smiley-guy' },
        { id: 'squad', package: 'stickers-smiley-guy' },
        { id: 'oops', package: 'stickers-smiley-guy' },
        { id: 'rofl', package: 'stickers-smiley-guy' },
        { id: 'just-sayin', package: 'stickers-smiley-guy' },
        { id: 'awesome', package: 'stickers-smiley-guy' },
        { id: 'barf', package: 'stickers-smiley-guy' },
        { id: 'sealed', package: 'stickers-smiley-guy' },
        { id: 'sorry', package: 'stickers-smiley-guy' },
        { id: 'sleeping', package: 'stickers-smiley-guy' },
        { id: 'hello', package: 'stickers-smiley-guy' },
        { id: 'seriously', package: 'stickers-smiley-guy' },
        { id: 'lol', package: 'stickers-smiley-guy' },
        { id: 'omg', package: 'stickers-smiley-guy' },
        { id: 'tmi', package: 'stickers-smiley-guy' },
        { id: 'see-ya', package: 'stickers-smiley-guy' },
        { id: 'smooches', package: 'stickers-smiley-guy' },
        { id: 'what', package: 'stickers-smiley-guy' },
        { id: 'good-morning', package: 'stickers-smiley-guy' },
        { id: 'yolo', package: 'stickers-smiley-guy' },
        { id: 'cutie-pie', package: 'stickers-smiley-guy' },
        { id: 'blessed', package: 'stickers-smiley-guy' },
        { id: 'meh', package: 'stickers-smiley-guy' },
        { id: 'cool-bro', package: 'stickers-smiley-guy' },
        { id: 'sweet', package: 'stickers-smiley-guy' },
        { id: 'you-so-cray', package: 'stickers-smiley-guy' },
        { id: 'brb', package: 'stickers-smiley-guy' },
        { id: 'orly', package: 'stickers-smiley-guy' },
      ],
      previewStickersTwo: [
        { package: 'stickers-smiley-guy', id: 'fml' },
        { package: 'stickers-smiley-guy', id: 'winning' },
      ],
    },
    {
      itemId: 'stickers-happy-poo',
      productIdBase: 'com.mewe.store.stickers.happypoo',
      previewStickers: [
        { package: 'stickers-happy-poo', id: 'make-it-rain' },
        { package: 'stickers-happy-poo', id: 'fml' },
        { package: 'stickers-happy-poo', id: 'bull' },
        { package: 'stickers-happy-poo', id: 'xoxo' },
        { package: 'stickers-happy-poo', id: 'begging' },
      ],
      category: 'Stickers',
      name: 'Happy Poo',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/happy-poo.png',
      packLogoDark: '/assets/stickers/logo/happy-poo-dark.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'later', package: 'stickers-happy-poo' },
        { id: 'begging', package: 'stickers-happy-poo' },
        { id: 'xoxo', package: 'stickers-happy-poo' },
        { id: 'hello', package: 'stickers-happy-poo' },
        { id: 'good-night', package: 'stickers-happy-poo' },
        { id: 'coffee', package: 'stickers-happy-poo' },
        { id: 'mic-drop', package: 'stickers-happy-poo' },
        { id: 'yaaasss', package: 'stickers-happy-poo' },
        { id: 'sunglasses', package: 'stickers-happy-poo' },
        { id: 'tongue', package: 'stickers-happy-poo' },
        { id: 'sad', package: 'stickers-happy-poo' },
        { id: 'thinking', package: 'stickers-happy-poo' },
        { id: 'good-morning', package: 'stickers-happy-poo' },
        { id: 'make-it-rain', package: 'stickers-happy-poo' },
        { id: 'working', package: 'stickers-happy-poo' },
        { id: 'lets-go', package: 'stickers-happy-poo' },
        { id: 'confused', package: 'stickers-happy-poo' },
        { id: 'waiting', package: 'stickers-happy-poo' },
        { id: 'done-done', package: 'stickers-happy-poo' },
        { id: 'fml', package: 'stickers-happy-poo' },
        { id: 'bull', package: 'stickers-happy-poo' },
        { id: 'holy-crap', package: 'stickers-happy-poo' },
        { id: 'it-happens', package: 'stickers-happy-poo' },
        { id: 'bffs', package: 'stickers-happy-poo' },
        { id: 'brb', package: 'stickers-happy-poo' },
        { id: 'i-donut', package: 'stickers-happy-poo' },
        { id: 'party-pooper', package: 'stickers-happy-poo' },
        { id: 'lit', package: 'stickers-happy-poo' },
        { id: 'tmi', package: 'stickers-happy-poo' },
        { id: 'stinks', package: 'stickers-happy-poo' },
        { id: 'stinkin-cute', package: 'stickers-happy-poo' },
        { id: 'oops', package: 'stickers-happy-poo' },
        { id: 'no', package: 'stickers-happy-poo' },
        { id: 'lol', package: 'stickers-happy-poo' },
        { id: 'oh-em-gee', package: 'stickers-happy-poo' },
        { id: 'hot', package: 'stickers-happy-poo' },
        { id: 'poonicorn', package: 'stickers-happy-poo' },
        { id: 'nice-day', package: 'stickers-happy-poo' },
        { id: 'cool', package: 'stickers-happy-poo' },
        { id: 'king', package: 'stickers-happy-poo' },
      ],
      previewStickersTwo: [
        { id: 'hello', package: 'stickers-happy-poo' },
        { id: 'xoxo', package: 'stickers-happy-poo' },
      ],
    },
    {
      itemId: 'stickers-avocado-adventures',
      productIdBase: 'com.mewe.store.stickers.avocadoadventures',
      previewStickers: [
        { package: 'stickers-avocado-adventures', id: 'superfood' },
        { package: 'stickers-avocado-adventures', id: 'avocadno' },
        { package: 'stickers-avocado-adventures', id: 'fresh' },
        { package: 'stickers-avocado-adventures', id: 'less-talk-more-guac' },
        { package: 'stickers-avocado-adventures', id: 'fiesta' },
      ],
      category: 'Stickers',
      name: 'Avocado Adventures',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/avocado-adventures.png',
      packLogoDark: '/assets/stickers/logo/avocado-adventures.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'nacho-problem', package: 'stickers-avocado-adventures' },
        { id: 'guac-my-world', package: 'stickers-avocado-adventures' },
        { id: 'heart-eyes-avocado', package: 'stickers-avocado-adventures' },
        { id: 'good-fat', package: 'stickers-avocado-adventures' },
        { id: 'roflcado', package: 'stickers-avocado-adventures' },
        { id: 'kidding-me', package: 'stickers-avocado-adventures' },
        { id: 'later-hater', package: 'stickers-avocado-adventures' },
        { id: 'fiesta', package: 'stickers-avocado-adventures' },
        { id: 'less-talk-more-guac', package: 'stickers-avocado-adventures' },
        { id: 'ripe', package: 'stickers-avocado-adventures' },
        { id: 'fresh', package: 'stickers-avocado-adventures' },
        { id: 'yaaassss', package: 'stickers-avocado-adventures' },
        { id: 'millennial', package: 'stickers-avocado-adventures' },
        { id: 'avocadno', package: 'stickers-avocado-adventures' },
        { id: 'lets-get-smashed', package: 'stickers-avocado-adventures' },
        { id: 'had-me-at-guac', package: 'stickers-avocado-adventures' },
        { id: 'vegan', package: 'stickers-avocado-adventures' },
        { id: 'empty-inside', package: 'stickers-avocado-adventures' },
        { id: 'superfood', package: 'stickers-avocado-adventures' },
        { id: 'hold-my-avocado', package: 'stickers-avocado-adventures' },
        { id: 'avocardio', package: 'stickers-avocado-adventures' },
        { id: 'dat-hass', package: 'stickers-avocado-adventures' },
        { id: 'guacward', package: 'stickers-avocado-adventures' },
        { id: 'avo-good-day', package: 'stickers-avocado-adventures' },
        { id: 'besties', package: 'stickers-avocado-adventures' },
        { id: 'avocado-toast', package: 'stickers-avocado-adventures' },
        { id: 'heart-you', package: 'stickers-avocado-adventures' },
        { id: 'other-half', package: 'stickers-avocado-adventures' },
        { id: 'kiss-my-hass', package: 'stickers-avocado-adventures' },
        { id: 'pitiful', package: 'stickers-avocado-adventures' },
        { id: 'holy-guacamole', package: 'stickers-avocado-adventures' },
        { id: 'make-guac-not-war', package: 'stickers-avocado-adventures' },
        { id: 'avo-gato', package: 'stickers-avocado-adventures' },
        { id: 'rock-out', package: 'stickers-avocado-adventures' },
        { id: 'avocuddle', package: 'stickers-avocado-adventures' },
        { id: 'extra-like-guac', package: 'stickers-avocado-adventures' },
        { id: 'guac-this-way', package: 'stickers-avocado-adventures' },
        { id: 'taco-bout-it', package: 'stickers-avocado-adventures' },
        { id: 'wtf', package: 'stickers-avocado-adventures' },
        { id: 'eat-me', package: 'stickers-avocado-adventures' },
      ],
      previewStickersTwo: [
        { id: 'heart-eyes-avocado', package: 'stickers-avocado-adventures' },
        { id: 'fiesta', package: 'stickers-avocado-adventures' },
      ],
    },
    {
      itemId: 'stickers-peach-life',
      productIdBase: 'com.mewe.store.stickers.peachlife',
      previewStickers: [
        { package: 'stickers-peach-life', id: 'bye' },
        { package: 'stickers-peach-life', id: 'taco-bout-it' },
        { package: 'stickers-peach-life', id: 'sorry' },
        { package: 'stickers-peach-life', id: 'dunno' },
        { package: 'stickers-peach-life', id: 'dinner' },
      ],
      category: 'Stickers',
      name: 'Peach Life',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/peach-life.png',
      packLogoDark: '/assets/stickers/logo/peach-life.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'dying', package: 'stickers-peach-life' },
        { id: 'dinner', package: 'stickers-peach-life' },
        { id: 'hangry', package: 'stickers-peach-life' },
        { id: 'text-me', package: 'stickers-peach-life' },
        { id: 'bye', package: 'stickers-peach-life' },
        { id: 'nah-bro', package: 'stickers-peach-life' },
        { id: 'taco-bout-it', package: 'stickers-peach-life' },
        { id: 'sorry', package: 'stickers-peach-life' },
        { id: 'dunno', package: 'stickers-peach-life' },
        { id: 'miss-you', package: 'stickers-peach-life' },
        { id: 'hugs', package: 'stickers-peach-life' },
        { id: 'struggle', package: 'stickers-peach-life' },
        { id: 'wtf', package: 'stickers-peach-life' },
        { id: 'omg', package: 'stickers-peach-life' },
        { id: 'my-bad', package: 'stickers-peach-life' },
        { id: 'whatcha-doin', package: 'stickers-peach-life' },
        { id: 'cant-even', package: 'stickers-peach-life' },
        { id: 'lol', package: 'stickers-peach-life' },
        { id: 'brb', package: 'stickers-peach-life' },
        { id: 'tmi', package: 'stickers-peach-life' },
        { id: 'whatevs', package: 'stickers-peach-life' },
        { id: 'romantic-kiss', package: 'stickers-peach-life' },
        { id: 'aggressive-kiss', package: 'stickers-peach-life' },
        { id: 'begging', package: 'stickers-peach-life' },
        { id: 'hey-babe', package: 'stickers-peach-life' },
        { id: 'couch-cuddle', package: 'stickers-peach-life' },
        { id: 'stop-sign-no', package: 'stickers-peach-life' },
        { id: 'i-love-you', package: 'stickers-peach-life' },
        { id: 'where-are-you', package: 'stickers-peach-life' },
        { id: 'sexy-time', package: 'stickers-peach-life' },
        { id: 'heart-eyes', package: 'stickers-peach-life' },
        { id: 'princess', package: 'stickers-peach-life' },
        { id: 'dancing', package: 'stickers-peach-life' },
        { id: 'come-over', package: 'stickers-peach-life' },
        { id: 'fml', package: 'stickers-peach-life' },
        { id: 'just-sayin', package: 'stickers-peach-life' },
        { id: 'thank-you', package: 'stickers-peach-life' },
        { id: 'smh', package: 'stickers-peach-life' },
        { id: 'sweet-dreams', package: 'stickers-peach-life' },
        { id: 'mic-drop', package: 'stickers-peach-life' },
      ],
      previewStickersTwo: [
        { id: 'dying', package: 'stickers-peach-life' },
        { id: 'hugs', package: 'stickers-peach-life' },
      ],
    },
    {
      itemId: 'stickers-unicorn-life',
      productIdBase: 'com.mewe.store.stickers.unicornlife',
      previewStickers: [
        { package: 'stickers-unicorn-life', id: 'lol' },
        { package: 'stickers-unicorn-life', id: 'sweet-dreams' },
        { package: 'stickers-unicorn-life', id: 'like-a-boss' },
        { package: 'stickers-unicorn-life', id: 'crown-me' },
        { package: 'stickers-unicorn-life', id: 'sodown' },
      ],
      category: 'Stickers',
      name: 'Unicorn Life',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/unicorn-life.png',
      packLogoDark: '/assets/stickers/logo/unicorn-life-dark.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'fml', package: 'stickers-unicorn-life' },
        { id: 'lit', package: 'stickers-unicorn-life' },
        { id: 'sweet', package: 'stickers-unicorn-life' },
        { id: 'whatevs', package: 'stickers-unicorn-life' },
        { id: 'xoxo', package: 'stickers-unicorn-life' },
        { id: 'winning', package: 'stickers-unicorn-life' },
        { id: 'mmmmm', package: 'stickers-unicorn-life' },
        { id: 'hump-day', package: 'stickers-unicorn-life' },
        { id: 'cant-watch', package: 'stickers-unicorn-life' },
        { id: 'meh', package: 'stickers-unicorn-life' },
        { id: 'forgive-me', package: 'stickers-unicorn-life' },
        { id: 'what-you-up-to', package: 'stickers-unicorn-life' },
        { id: 'miss-you', package: 'stickers-unicorn-life' },
        { id: 'so-cool', package: 'stickers-unicorn-life' },
        { id: 'hell-nah', package: 'stickers-unicorn-life' },
        { id: 'squad', package: 'stickers-unicorn-life' },
        { id: 'love-you', package: 'stickers-unicorn-life' },
        { id: 'stuffed-unicorn', package: 'stickers-unicorn-life' },
        { id: 'lol', package: 'stickers-unicorn-life' },
        { id: 'ttyl', package: 'stickers-unicorn-life' },
        { id: 'unicorneatspopcorn', package: 'stickers-unicorn-life' },
        { id: 'wtf', package: 'stickers-unicorn-life' },
        { id: 'dead', package: 'stickers-unicorn-life' },
        { id: 'yolo', package: 'stickers-unicorn-life' },
        { id: 'omw', package: 'stickers-unicorn-life' },
        { id: 'need-coffee', package: 'stickers-unicorn-life' },
        { id: 'looking-fabolous', package: 'stickers-unicorn-life' },
        { id: 'unicorndaydreaming', package: 'stickers-unicorn-life' },
        { id: 'brb', package: 'stickers-unicorn-life' },
        { id: 'ohhey', package: 'stickers-unicorn-life' },
        { id: 'crown-me', package: 'stickers-unicorn-life' },
        { id: 'sodown', package: 'stickers-unicorn-life' },
        { id: 'callme', package: 'stickers-unicorn-life' },
        { id: 'hangry', package: 'stickers-unicorn-life' },
        { id: 'tgif', package: 'stickers-unicorn-life' },
        { id: 'like-a-boss', package: 'stickers-unicorn-life' },
        { id: 'sweet-dreams', package: 'stickers-unicorn-life' },
        { id: 'done-done', package: 'stickers-unicorn-life' },
        { id: 'good-morning', package: 'stickers-unicorn-life' },
        { id: 'party', package: 'stickers-unicorn-life' },
      ],
      previewStickersTwo: [
        { id: 'fml', package: 'stickers-unicorn-life' },
        { id: 'party', package: 'stickers-unicorn-life' },
      ],
    },
    {
      itemId: 'stickers-woman-power',
      productIdBase: 'com.mewe.store.stickers.womanpower',
      previewStickers: [
        { package: 'stickers-woman-power', id: 'sorry-not-sorry' },
        { package: 'stickers-woman-power', id: 'be-strong-girl' },
        { package: 'stickers-woman-power', id: 'pow' },
        { package: 'stickers-woman-power', id: 'girls-rule' },
        { package: 'stickers-woman-power', id: 'frida-kahlo' },
      ],
      category: 'Stickers',
      name: 'Woman Power',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/woman-power.png',
      packLogoDark: '/assets/stickers/logo/woman-power-dark.png',
      packLogoWidth: 180,
      stickers: [
        { id: 'future-female', package: 'stickers-woman-power' },
        { id: 'sisterhood', package: 'stickers-woman-power' },
        { id: 'fries-before-guys', package: 'stickers-woman-power' },
        { id: 'sorry-not-sorry', package: 'stickers-woman-power' },
        { id: 'bra-burning', package: 'stickers-woman-power' },
        { id: 'silhouette', package: 'stickers-woman-power' },
        { id: 'be-brave', package: 'stickers-woman-power' },
        { id: 'stem-grl', package: 'stickers-woman-power' },
        { id: 'frida-kahlo', package: 'stickers-woman-power' },
        { id: 'sushi-rolls', package: 'stickers-woman-power' },
        { id: 'who-runs-the-world', package: 'stickers-woman-power' },
        { id: 'fierce', package: 'stickers-woman-power' },
        { id: 'nope', package: 'stickers-woman-power' },
        { id: 'girls-unite', package: 'stickers-woman-power' },
        { id: 'girls-rock', package: 'stickers-woman-power' },
        { id: 'vote', package: 'stickers-woman-power' },
        { id: 'women-heart-mosaic', package: 'stickers-woman-power' },
        { id: 'my-hero', package: 'stickers-woman-power' },
        { id: 'phenomenal-woman', package: 'stickers-woman-power' },
        { id: 'glass-ceiling', package: 'stickers-woman-power' },
        { id: 'take-that', package: 'stickers-woman-power' },
        { id: 'girl-gang', package: 'stickers-woman-power' },
        { id: 'girl-power', package: 'stickers-woman-power' },
        { id: 'girls-can-do-anything', package: 'stickers-woman-power' },
        { id: 'nevertheless', package: 'stickers-woman-power' },
        { id: 'like-a-boss', package: 'stickers-woman-power' },
        { id: 'hear-me-roar', package: 'stickers-woman-power' },
        { id: 'cant-stop-wont-stop', package: 'stickers-woman-power' },
        { id: 'pow', package: 'stickers-woman-power' },
        { id: 'sassy-classy-badassy', package: 'stickers-woman-power' },
        { id: 'we-can-do-it', package: 'stickers-woman-power' },
        { id: 'joan-of-arc', package: 'stickers-woman-power' },
        { id: 'be-strong-girl', package: 'stickers-woman-power' },
        { id: 'fight-like-a-girl', package: 'stickers-woman-power' },
        { id: 'girls-rule', package: 'stickers-woman-power' },
        { id: 'equal-af', package: 'stickers-woman-power' },
        { id: 'own-it', package: 'stickers-woman-power' },
        { id: 'go-girl', package: 'stickers-woman-power' },
        { id: 'well-behaved-women', package: 'stickers-woman-power' },
        { id: 'wtf', package: 'stickers-woman-power' },
      ],
      previewStickersTwo: [
        { id: 'fries-before-guys', package: 'stickers-woman-power' },
        { id: 'who-runs-the-world', package: 'stickers-woman-power' },
      ],
    },
    {
      itemId: 'stickers-eggplant-life',
      productIdBase: 'com.mewe.store.stickers.eggplantlife',
      previewStickers: [
        { package: 'stickers-eggplant-life', id: 'aw-yeah' },
        { package: 'stickers-eggplant-life', id: 'o-rly' },
        { package: 'stickers-eggplant-life', id: 'poke' },
        { package: 'stickers-eggplant-life', id: 'censored' },
        { package: 'stickers-eggplant-life', id: 'looking-good' },
      ],
      category: 'Stickers',
      name: 'Eggplant life',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/eggplant-life.png',
      packLogoDark: '/assets/stickers/logo/eggplant-life-dark.png',
      packLogoWidth: 190,
      stickers: [
        { id: 'o-rly', package: 'stickers-eggplant-life' },
        { id: 'censored', package: 'stickers-eggplant-life' },
        { id: 'poke', package: 'stickers-eggplant-life' },
        { id: 'thinking-of-you', package: 'stickers-eggplant-life' },
        { id: 'aw-yeah', package: 'stickers-eggplant-life' },
        { id: 'regrets', package: 'stickers-eggplant-life' },
        { id: 'pics', package: 'stickers-eggplant-life' },
        { id: 'looking-good', package: 'stickers-eggplant-life' },
        { id: 'hurry-up', package: 'stickers-eggplant-life' },
        { id: 'thank-you', package: 'stickers-eggplant-life' },
        { id: 'twinning', package: 'stickers-eggplant-life' },
        { id: 'xoxo', package: 'stickers-eggplant-life' },
        { id: 'ride-or-die', package: 'stickers-eggplant-life' },
        { id: 'oops', package: 'stickers-eggplant-life' },
        { id: 'meh', package: 'stickers-eggplant-life' },
        { id: 'drinks', package: 'stickers-eggplant-life' },
        { id: 'done-and-done', package: 'stickers-eggplant-life' },
        { id: 'how-you-doin', package: 'stickers-eggplant-life' },
        { id: 'relax', package: 'stickers-eggplant-life' },
        { id: 'u-right', package: 'stickers-eggplant-life' },
        { id: 'call-me', package: 'stickers-eggplant-life' },
        { id: 'lol', package: 'stickers-eggplant-life' },
        { id: 'wtf', package: 'stickers-eggplant-life' },
        { id: 'fml', package: 'stickers-eggplant-life' },
        { id: 'yolo', package: 'stickers-eggplant-life' },
        { id: 'couch-cuddle', package: 'stickers-eggplant-life' },
        { id: 'romantic-kiss', package: 'stickers-eggplant-life' },
        { id: 'dancing', package: 'stickers-eggplant-life' },
        { id: 'please', package: 'stickers-eggplant-life' },
        { id: 'bromance', package: 'stickers-eggplant-life' },
        { id: 'brb', package: 'stickers-eggplant-life' },
        { id: 'later', package: 'stickers-eggplant-life' },
        { id: 'sup', package: 'stickers-eggplant-life' },
        { id: 'lit', package: 'stickers-eggplant-life' },
        { id: 'heyyy', package: 'stickers-eggplant-life' },
        { id: 'sweet-dreams', package: 'stickers-eggplant-life' },
        { id: 'sorry', package: 'stickers-eggplant-life' },
        { id: 'lets-eat', package: 'stickers-eggplant-life' },
        { id: 'hell-nah', package: 'stickers-eggplant-life' },
        { id: 'on-my-way', package: 'stickers-eggplant-life' },
      ],
      previewStickersTwo: [
        { id: 'heyyy', package: 'stickers-eggplant-life' },
        { id: 'aw-yeah', package: 'stickers-eggplant-life' },
      ],
    },
    {
      itemId: 'stickers-mermaid-life',
      productIdBase: 'com.mewe.store.stickers.mermaidlife',
      previewStickers: [
        { package: 'stickers-mermaid-life', id: 'salty' },
        { package: 'stickers-mermaid-life', id: 'good-morning' },
        { package: 'stickers-mermaid-life', id: 'miss-you' },
        { package: 'stickers-mermaid-life', id: 'sweet-dreams' },
        { package: 'stickers-mermaid-life', id: 'seas-the-day' },
      ],
      category: 'Stickers',
      name: 'Mermaid Life',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/mermaid-life.png',
      packLogoDark: '/assets/stickers/logo/mermaid-life-dark.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'seas-the-day', package: 'stickers-mermaid-life' },
        { id: 'mermazing', package: 'stickers-mermaid-life' },
        { id: 'salty', package: 'stickers-mermaid-life' },
        { id: 'kissing', package: 'stickers-mermaid-life' },
        { id: 'hello', package: 'stickers-mermaid-life' },
        { id: 'miss-you', package: 'stickers-mermaid-life' },
        { id: 'sweet-dreams', package: 'stickers-mermaid-life' },
        { id: 'good-morning', package: 'stickers-mermaid-life' },
        { id: 'oh-whale', package: 'stickers-mermaid-life' },
        { id: 'squiding-me', package: 'stickers-mermaid-life' },
        { id: 'shelfie', package: 'stickers-mermaid-life' },
        { id: 'brb', package: 'stickers-mermaid-life' },
        { id: 'looking-good', package: 'stickers-mermaid-life' },
        { id: 'on-my-way', package: 'stickers-mermaid-life' },
        { id: 'just-keep-swimming', package: 'stickers-mermaid-life' },
        { id: 'mermaid-aesthetic', package: 'stickers-mermaid-life' },
        { id: 'basic-fishes', package: 'stickers-mermaid-life' },
        { id: 'extra', package: 'stickers-mermaid-life' },
        { id: 'facepalm', package: 'stickers-mermaid-life' },
        { id: 'love-you', package: 'stickers-mermaid-life' },
        { id: 'lmto', package: 'stickers-mermaid-life' },
        { id: 'mermaid-hair', package: 'stickers-mermaid-life' },
        { id: 'sea-ya', package: 'stickers-mermaid-life' },
        { id: 'be-a-mermaid', package: 'stickers-mermaid-life' },
        { id: 'mermaid-kisses', package: 'stickers-mermaid-life' },
        { id: 'beach-please', package: 'stickers-mermaid-life' },
        { id: 'feeling-crabby', package: 'stickers-mermaid-life' },
        { id: 'tired-mermaid', package: 'stickers-mermaid-life' },
        { id: 'xoxo', package: 'stickers-mermaid-life' },
        { id: 'boy-bye', package: 'stickers-mermaid-life' },
        { id: 'shellphone', package: 'stickers-mermaid-life' },
        { id: 'meh', package: 'stickers-mermaid-life' },
        { id: 'relax', package: 'stickers-mermaid-life' },
        { id: 'mermaid-gang', package: 'stickers-mermaid-life' },
        { id: 'really-a-mermaid', package: 'stickers-mermaid-life' },
        { id: 'omg', package: 'stickers-mermaid-life' },
        { id: 'yolo', package: 'stickers-mermaid-life' },
        { id: 'fml', package: 'stickers-mermaid-life' },
        { id: 'so-cute', package: 'stickers-mermaid-life' },
        { id: 'thinking-of-you', package: 'stickers-mermaid-life' },
      ],
      previewStickersTwo: [
        { id: 'thinking-of-you', package: 'stickers-mermaid-life' },
        { id: 'seas-the-day', package: 'stickers-mermaid-life' },
      ],
    },
    {
      itemId: 'stickers-sweet-sassy',
      productIdBase: 'com.mewe.store.stickers.sweetsassy',
      previewStickers: [
        { package: 'stickers-sweet-sassy', id: 'whatever-woman' },
        { package: 'stickers-sweet-sassy', id: 'bffs' },
        { package: 'stickers-sweet-sassy', id: 'lol' },
        { package: 'stickers-sweet-sassy', id: 'smiley-butterfly' },
        { package: 'stickers-sweet-sassy', id: 'headphone-smiley' },
      ],
      category: 'Stickers',
      name: 'Sweet and Sassy',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/sweet-sassy.png',
      packLogoDark: '/assets/stickers/logo/sweet-sassy.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'angel-devil', package: 'stickers-sweet-sassy' },
        { id: 'sparkle-100', package: 'stickers-sweet-sassy' },
        { id: 'sweet-heart-mosaic', package: 'stickers-sweet-sassy' },
        { id: 'dog-smiley', package: 'stickers-sweet-sassy' },
        { id: 'brace-face', package: 'stickers-sweet-sassy' },
        { id: 'queen', package: 'stickers-sweet-sassy' },
        { id: 'alien-flower-crown', package: 'stickers-sweet-sassy' },
        { id: 'sisters', package: 'stickers-sweet-sassy' },
        { id: 'dolphin', package: 'stickers-sweet-sassy' },
        { id: 'narwhal', package: 'stickers-sweet-sassy' },
        { id: 'victory-hand', package: 'stickers-sweet-sassy' },
        { id: 'wow', package: 'stickers-sweet-sassy' },
        { id: 'angel-smiley', package: 'stickers-sweet-sassy' },
        { id: 'sweet-dreams', package: 'stickers-sweet-sassy' },
        { id: 'faves-peace-sign', package: 'stickers-sweet-sassy' },
        { id: 'headphone-smiley', package: 'stickers-sweet-sassy' },
        { id: 'whatever-woman', package: 'stickers-sweet-sassy' },
        { id: 'smileycorn', package: 'stickers-sweet-sassy' },
        { id: 'rainbow-smiley', package: 'stickers-sweet-sassy' },
        { id: 'spa-mask-smiley', package: 'stickers-sweet-sassy' },
        { id: 'frappe', package: 'stickers-sweet-sassy' },
        { id: 'pink-nerd', package: 'stickers-sweet-sassy' },
        { id: 'flower-crown-smiley', package: 'stickers-sweet-sassy' },
        { id: 'cupcakes', package: 'stickers-sweet-sassy' },
        { id: 'pink-sparkle-poo', package: 'stickers-sweet-sassy' },
        { id: 'purple-sparkle-poo', package: 'stickers-sweet-sassy' },
        { id: 'wink-flower-smiley', package: 'stickers-sweet-sassy' },
        { id: 'blowing-kiss-with-lips', package: 'stickers-sweet-sassy' },
        { id: 'wink-tongue-peace', package: 'stickers-sweet-sassy' },
        { id: 'rainbow-puke', package: 'stickers-sweet-sassy' },
        { id: 'bffs', package: 'stickers-sweet-sassy' },
        { id: 'heart-sunglass-smiley', package: 'stickers-sweet-sassy' },
        { id: 'rainbow-princess-poo', package: 'stickers-sweet-sassy' },
        { id: 'no-evil-monkeys', package: 'stickers-sweet-sassy' },
        { id: 'gumball-machine', package: 'stickers-sweet-sassy' },
        { id: 'omg', package: 'stickers-sweet-sassy' },
        { id: 'lol', package: 'stickers-sweet-sassy' },
        { id: 'rainbow', package: 'stickers-sweet-sassy' },
        { id: 'sweet-cake', package: 'stickers-sweet-sassy' },
        { id: 'smiley-butterfly', package: 'stickers-sweet-sassy' },
      ],
      previewStickersTwo: [
        { id: 'angel-devil', package: 'stickers-sweet-sassy' },
        { id: 'sparkle-100', package: 'stickers-sweet-sassy' },
      ],
    },
    {
      itemId: 'stickers-halloween-party',
      productIdBase: 'com.mewe.store.stickers.halloweenparty',
      previewStickers: [
        { package: 'stickers-halloween-party', id: 'happy-halloween' },
        { package: 'stickers-halloween-party', id: 'frankenstein' },
        { package: 'stickers-halloween-party', id: 'pumpkin' },
        { package: 'stickers-halloween-party', id: 'skeletons' },
        { package: 'stickers-halloween-party', id: 'reaper' },
      ],
      category: 'Stickers',
      name: 'Halloween Party',
      class: 'store-stickers',
      description: 'Set of 40 stickers by JoyPixels',
      price: '1.99',
      grantedBy: ['premium'],
      packLogo: '/assets/stickers/logo/halloween-party-logo.png',
      packLogoDark: '/assets/stickers/logo/halloween-party-logo.png',
      packLogoWidth: 170,
      stickers: [
        { id: 'monster', package: 'stickers-halloween-party' },
        { id: 'frankenstein', package: 'stickers-halloween-party' },
        { id: 'scarecrow', package: 'stickers-halloween-party' },
        { id: 'skeletons', package: 'stickers-halloween-party' },
        { id: 'squad', package: 'stickers-halloween-party' },
        { id: 'eyes', package: 'stickers-halloween-party' },
        { id: 'ghost', package: 'stickers-halloween-party' },
        { id: 'crossbones', package: 'stickers-halloween-party' },
        { id: 'spooky', package: 'stickers-halloween-party' },
        { id: 'party', package: 'stickers-halloween-party' },
        { id: 'mummy', package: 'stickers-halloween-party' },
        { id: 'owl', package: 'stickers-halloween-party' },
        { id: 'reaper', package: 'stickers-halloween-party' },
        { id: 'zombie', package: 'stickers-halloween-party' },
        { id: 'be-scary', package: 'stickers-halloween-party' },
        { id: 'candy-corn', package: 'stickers-halloween-party' },
        { id: 'wizard-hat', package: 'stickers-halloween-party' },
        { id: 'bug-1', package: 'stickers-halloween-party' },
        { id: 'bug-2', package: 'stickers-halloween-party' },
        { id: 'good-night', package: 'stickers-halloween-party' },
        { id: 'wolf', package: 'stickers-halloween-party' },
        { id: 'gimme-candy', package: 'stickers-halloween-party' },
        { id: 'sugar-skulls', package: 'stickers-halloween-party' },
        { id: 'spider', package: 'stickers-halloween-party' },
        { id: 'wicked', package: 'stickers-halloween-party' },
        { id: 'trick-or-treat', package: 'stickers-halloween-party' },
        { id: 'lit', package: 'stickers-halloween-party' },
        { id: 'rip', package: 'stickers-halloween-party' },
        { id: 'devil', package: 'stickers-halloween-party' },
        { id: 'pumpkin', package: 'stickers-halloween-party' },
        { id: 'bite-me', package: 'stickers-halloween-party' },
        { id: 'poison', package: 'stickers-halloween-party' },
        { id: 'witch-hat', package: 'stickers-halloween-party' },
        { id: 'crown', package: 'stickers-halloween-party' },
        { id: 'masquerade', package: 'stickers-halloween-party' },
        { id: 'pirate-hat', package: 'stickers-halloween-party' },
        { id: 'pumpkin-frame', package: 'stickers-halloween-party' },
        { id: 'leaf-frame', package: 'stickers-halloween-party' },
        { id: 'black-cat', package: 'stickers-halloween-party' },
        { id: 'happy-halloween', package: 'stickers-halloween-party' },
      ],
      previewStickersTwo: [
        { package: 'stickers-halloween-party', id: 'pumpkin' },
        { package: 'stickers-halloween-party', id: 'happy-halloween' },
      ],
    },
    {
      itemId: 'premium',
      productIdBase: 'com.mewe.store.premium',
      subscriptionPeriods: [{ name: 'month', price: '4.99' }],
      category: 'Subscriptions',
      name: 'MeWe Premium',
      class: 'premium-subscription',
      description: 'All the best premium features bundled together for one low price.',
      coverImage: '/assets/store/store-item-premium.png',
      hiddenForWorld: true,
      hiddenForMewe: true,
    },
    {
      itemId: 'premium-promotion',
      productIdBase: 'com.mewe.store.premiumpromotion',
      subscriptionPeriods: [
        { name: 'year', price: '54.99' },
        { name: 'month', price: '4.99' },
      ],
      category: 'Subscriptions',
      name: 'MeWe Premium',
      class: 'premium-subscription',
      description: 'All the best premium features bundled together for one low price. FREE for 30 days!',
      coverImage: '/assets/store/store-item-premium.png',
    },
    {
      itemId: 'page',
      productIdBase: 'com.mewe.store.page',
      subscriptionPeriods: [{ name: 'month', price: '1.99' }],
      category: 'Subscriptions',
      name: 'Page Subscription',
      class: 'page-subscription',
      description: 'Create a Page to support your business',
      coverImage: '/assets/store/store-item-page.png',
    },
    {
      itemId: 'calls-voicevideo',
      productIdBase: 'com.mewe.store.calls.voicevideo',
      subscriptionPeriods: [{ name: 'month', price: '1.99' }],
      category: 'Subscriptions',
      name: 'Voice & Video',
      class: 'voice-subscription',
      grantedBy: ['premium'],
      description: 'Unlimited voice and video calling for $1.99 per month',
      coverImage: '/assets/store/store-item-vvc.png',
    },
    {
      itemId: 'storage',
      productIdBase: 'com.mewe.store.storage',
      subscriptionPeriods: [{ name: 'month' }],
      tiers: [
        { tierId: 'tier1', tierItemId: 'storage-monthly-t1', value: 50, price: '3.99' },
        { tierId: 'tier2', tierItemId: 'storage-monthly-t2', value: 100, price: '5.99' },
        { tierId: 'tier3', tierItemId: 'storage-monthly-t3', value: 500, price: '14.99' },
      ],
      category: 'Subscriptions',
      name: 'Cloud Storage',
      class: 'cloud-storage',
      description: 'Upgrade your storage from free 8 GB to 50 GB, 100 GB, or 500 GB',
      coverImage: '/assets/store/store-item-storage.png',
    },
    // journals need to be set here because item details might be needed for settings/purchses
    // when item was bought on android and it's displayed on subscriptions list on Web SG-32764
    // for that reason there are flags set on this item: "hiddenForWorld" and "hiddenForMewe"
    {
      itemId: 'journals-monthly',
      productIdBase: 'com.mewe.store.journals',
      subscriptionPeriods: [{ name: 'month' }],
      category: 'Subscriptions',
      name: 'Journals',
      class: 'journals-subscription',
      description: 'Bundle your stories together, stay organized, and share.',
      hiddenForWorld: true,
      hiddenForMewe: true,
    },
    {
      itemId: 'theme-dark',
      productIdBase: 'com.mewe.store.theme.dark',
      category: 'Themes',
      name: 'Dark Theme',
      class: 'store-theme',
      description: 'Experience MeWe in Night Mode',
      price: '1.99',
      grantedBy: ['premium'],
      previewUrl: '/assets/store/theme-preview-dark.jpg',
      coverImage: '/assets/store/store-item-dark-theme.png',
    },
    {
      itemId: 'emoji-mewe-hearts',
      productIdBase: 'com.mewe.store.emoji.mewe-hearts',
      category: 'Emojis',
      name: 'MeWe Hearts Pack',
      description: 'Set of 20 emojis by Marlena Zielonko',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':hearts_pixel1:',
      previewEmojisFour: [':hearts_cactus:', ':hearts_book:', ':hearts_clover:', ':hearts_doodle:'],
      previewEmojisFive: [':hearts_cactus:', ':hearts_book:', ':hearts_clover:', ':hearts_doodle:', ':hearts_dragon:'],
      emojisList: [
        ':hearts_cactus:',
        ':hearts_book:',
        ':hearts_clover:',
        ':hearts_doodle:',
        ':hearts_dragon:',
        ':hearts_flowers:',
        ':hearts_game:',
        ':hearts_graphic1:',
        ':hearts_graphic2:',
        ':hearts_icecream:',
        ':hearts_leaf:',
        ':hearts_monstera:',
        ':hearts_pastel:',
        ':hearts_pillow:',
        ':hearts_pixel1:',
        ':hearts_pixel2:',
        ':hearts_pixel3:',
        ':hearts_pixel4:',
        ':hearts_royal:',
        ':hearts_waffle:',
      ],
    },
    {
      itemId: 'emoji-sausage',
      productIdBase: 'com.mewe.store.emoji.sausage',
      category: 'Emojis',
      name: 'Crazy Sausages Pack',
      description: 'Set of 25 emojis by Marlena Zielonko',
      class: 'store-emoji',
      price: '4.99',
      iconEmoji: ':sausage__inLove:',
      previewEmojisFour: [':sausage__angel:', ':sausage__weird:', ':sausage__cool:', ':sausage__excited:'],
      previewEmojisFive: [
        ':sausage__sick:',
        ':sausage__devil:',
        ':sausage__indifferent:',
        ':sausage__nerd:',
        ':sausage__kissing:',
      ],
      emojisList: [
        ':sausage__angel:',
        ':sausage__angry:',
        ':sausage__annoyed:',
        ':sausage__confused:',
        ':sausage__cool:',
        ':sausage__devil:',
        ':sausage__dodger:',
        ':sausage__excited:',
        ':sausage__happy:',
        ':sausage__hungry:',
        ':sausage__indifferent:',
        ':sausage__inLove:',
        ':sausage__kissing:',
        ':sausage__nerd:',
        ':sausage__sad:',
        ':sausage__sick:',
        ':sausage__silly:',
        ':sausage__sleepy:',
        ':sausage__surprised:',
        ':sausage__unclear:',
        ':sausage__veryHappy:',
        ':sausage__verySad:',
        ':sausage__verySick:',
        ':sausage__veryverySad:',
        ':sausage__weird:',
      ],
    },
    {
      itemId: 'emoji-heart',
      productIdBase: 'com.mewe.store.emoji.heart',
      category: 'Emojis',
      name: 'Hearts Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      previewEmojisFour: [':heart_with-crown:', ':heart_lucky:', ':heart_devil:', ':heart_flaming:'],
      previewEmojisFive: [
        ':heart_lock-and-key:',
        ':heart_gold:',
        ':heart_lucky:',
        ':heart_wrapped-in-barbed-wire:',
        ':heart_frozen:',
      ],
      emojisList: [
        ':heart_rose:',
        ':heart_gold:',
        ':heart_glass:',
        ':heart_beating:',
        ':heart_puzzle:',
        ':heart_leopard-print:',
        ':heart_zebra-print:',
        ':heart_tiger-print:',
        ':heart_with-wings:',
        ':heart_pieces:',
        ':heart_dagger-through-it:',
        ':heart_hand:',
        ':heart_outline:',
        ':heart_ruby:',
        ':heart_devil:',
        ':heart_yin-yang:',
        ':heart_swirl:',
        ':heart_swirl-2:',
        ':heart_lock-and-key:',
        ':heart_with-crown:',
        ':heart_purple:',
        ':heart_wrapped-in-barbed-wire:',
        ':heart_fire-and-ice:',
        ':heart_lucky:',
        ':heart_w-padlock-and-chain:',
        ':heart_stone:',
        ':heart_flaming:',
        ':heart_rainbow:',
        ':heart_frozen:',
        ':heart_bleeding:',
        ':heart_broken-stitched-back-together:',
        ':heart_blue:',
        ':heart_green:',
        ':heart_red:',
        ':heart_pink:',
        ':heart_broken:',
        ':heart_with-hearts-inside:',
        ':heart_glitter:',
        ':heart_two-hearts-together:',
        ':heart_w-love-written-on-it:',
      ],
      packLogo: '/assets/store/logo/heart-pack-logo.png',
      packLogoDark: '/assets/store/logo/heart-pack-logo.png',
      packLogoWidth: 190,
    },
    {
      itemId: 'emoji-poop',
      productIdBase: 'com.mewe.store.emoji.poop',
      category: 'Emojis',
      name: 'Pile of Poo Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':poop_unicorn:',
      previewEmojisFour: [':poop_smiling-face-with-horns:', ':poop_sleeping-face:', ':poop_superhero:', ':poop_holy:'],
      previewEmojisFive: [
        ':poop_face-with-stuck-out-tongue-and-winking-eye:',
        ':poop_princess:',
        ':poop_face-with-symbols-over-mouth:',
        ':poop_shushing-face:',
        ':poop_smiling-face-with-horns:',
      ],
      emojisList: [
        ':poop_nauseated-face:',
        ':poop_star-struck:',
        ':poop_shushing-face:',
        ':poop_face-with-raised-eyebrow:',
        ':poop_face-with-hand-over-mouth:',
        ':poop_crazy-face:',
        ':poop_face-vomiting:',
        ':poop_face-with-monocle:',
        ':poop_face-with-symbols-over-mouth:',
        ':poop_slightly-frowning-face:',
        ':poop_winking-face-poop:',
        ':poop_smiling-face-with-open-mouth-and-closed-eyes:',
        ':poop_grimacing-face:',
        ':poop_smiling-face-with-smiling-eyes:',
        ':poop_cowboy-hat:',
        ':poop_flaming:',
        ':poop_king:',
        ':poop_bull:',
        ':poop_purple-sparkle:',
        ':poop_pink-sparkle:',
        ':poop_face-with-stuck-out-tongue-and-winking-eye:',
        ':poop_rainbow:',
        ':poop_unicorn:',
        ':poop_princess:',
        ':poop_holy:',
        ':poop_superhero:',
        ':poop_heart-eyes:',
        ':poop_crying:',
        ':poop_angry:',
        ':poop_sad:',
        ':poop_laughing:',
        ':poop_face-blowing-a-kiss:',
        ':poop_thinking-face:',
        ':poop_smiling-face-with-horns:',
        ':poop_face-screaming-in-fear:',
        ':poop_sleeping-face:',
        ':poop_face-with-rolling-eyes:',
        ':poop_tired-face:',
        ':poop_nerd-face:',
        ':poop_smiling-face-with-sunglasses:',
      ],
      packLogo: '/assets/store/logo/pile-of-poo-logo.png',
      packLogoDark: '/assets/store/logo/pile-of-poo-logo.png',
      packLogoWidth: 180,
    },
    {
      itemId: 'emoji-monkey',
      productIdBase: 'com.mewe.store.emoji.monkey',
      category: 'Emojis',
      name: 'Monkey Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':monkey_ninja:',
      previewEmojisFour: [
        ':monkey_stuck-out-tongue-and-winking-eye:',
        ':monkey_hot:',
        ':monkey_wry-smile:',
        ':monkey_star-struck:',
      ],
      previewEmojisFive: [
        ':monkey_screaming-in-fear:',
        ':monkey_wry-smile:',
        ':monkey_sleeping:',
        ':monkey_party-hat:',
        ':monkey_pouting:',
      ],
      emojisList: [
        ':monkey_crying:',
        ':monkey_ninja:',
        ':monkey_hot:',
        ':monkey_vomiting:',
        ':monkey_head-bandage:',
        ':monkey_crazy:',
        ':monkey_grinning:',
        ':monkey_tears-of-joy:',
        ':monkey_wry-smile:',
        ':monkey_weary:',
        ':monkey_peace-sign:',
        ':monkey_pouting:',
        ':monkey_pirate:',
        ':monkey_floating-hearts-around-headface:',
        ':monkey_astronaut:',
        ':monkey_monocle:',
        ':monkey_hugging:',
        ':monkey_thinking:',
        ':monkey_screaming-in-fear:',
        ':monkey_spy:',
        ':monkey_santa-hat:',
        ':monkey_nerd:',
        ':monkey_frowning:',
        ':monkey_confounded:',
        ':monkey_sleeping:',
        ':monkey_smiling-open-mouth-closed-eyes:',
        ':monkey_star-struck:',
        ':monkey_heart-eyes:',
        ':monkey_with-sunglasses:',
        ':monkey_stuck-out-tongue-and-winking-eye:',
        ':monkey_grinning-smiling-eyes:',
        ':monkey_kissing-closed-eyes:',
        ':monkey_one-eye-open-blowing-heart:',
        ':monkey_blushing:',
        ':monkey_eyerolling:',
        ':monkey_loudly-crying:',
        ':monkey_nauseated:',
        ':monkey_party-hat:',
        ':monkey_halo:',
        ':monkey_shushing:',
      ],
      packLogo: '/assets/store/logo/monkey-pack-logo.png',
      packLogoDark: '/assets/store/logo/monkey-pack-logo.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-alien',
      productIdBase: 'com.mewe.store.emoji.alien',
      category: 'Emojis',
      name: 'Alien Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':alien_pirate-face:',
      previewEmojisFour: [
        ':alien_face-with-floating-hearts-around-headface:',
        ':alien_face-w-monocle:',
        ':alien_ninja-face:',
        ':alien_peace-sign-face:',
      ],
      previewEmojisFive: [
        ':alien_crazy-face:',
        ':alien_face-w-head-bandage:',
        ':alien_face-w-one-eye-open-blowing-heart:',
        ':alien_santa-hat-face:',
        ':alien_ninja-face:',
      ],
      emojisList: [
        ':alien_shushing-face:',
        ':alien_face-w-one-eye-open-blowing-heart:',
        ':alien_face-w-sunglasses:',
        ':alien_face-w-wry-smile:',
        ':alien_face-with-floating-hearts-around-headface:',
        ':alien_party-hat-face:',
        ':alien_peace-sign-face:',
        ':alien_pirate-face:',
        ':alien_pouting-face:',
        ':alien_santa-hat-face:',
        ':alien_face-w-monocle:',
        ':alien_sleeping-face:',
        ':alien_smiling-face-w-open-mouth-closed-eyes:',
        ':alien_spy-face:',
        ':alien_star-struck-face:',
        ':alien_stuck-out-tongue-and-winking-eye-face:',
        ':alien_tears-of-joy-face:',
        ':alien_thinking-face:',
        ':alien_vomiting-face:',
        ':alien_weary-face:',
        ':alien_heart-eyes-face:',
        ':alien_blushing-face:',
        ':alien_confounded-face:',
        ':alien_crazy-face:',
        ':alien_crying-face:',
        ':alien_eyerolling-face:',
        ':alien_frowning-face:',
        ':alien_grinning-face:',
        ':alien_grinning-face-w-smiling-eyes:',
        ':alien_halo-face:',
        ':alien_astronaut-face:',
        ':alien_hot-face:',
        ':alien_hugging-face:',
        ':alien_kissing-face-w-closed-eyes:',
        ':alien_loudly-crying-face:',
        ':alien_nauseated-face:',
        ':alien_nerd-face:',
        ':alien_ninja-face:',
        ':alien_face-screaming-in-fear:',
        ':alien_face-w-head-bandage:',
      ],
      packLogo: '/assets/store/logo/alien-pack-logo.png',
      packLogoDark: '/assets/store/logo/alien-pack-logo-dark.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-dog',
      productIdBase: 'com.mewe.store.emoji.dog',
      category: 'Emojis',
      name: 'Dog Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':dog_peace-sign-face:',
      previewEmojisFour: [
        ':dog_face-w-one-eye-open-blowing-heart:',
        ':dog_spy-face:',
        ':dog_party-hat-face:',
        ':dog_ninja-face:',
      ],
      previewEmojisFive: [
        ':dog_thinking-face:',
        ':dog_heart-eyes-face:',
        ':dog_hot-face:',
        ':dog_face-with-floating-hearts-around-headface:',
        ':dog_grinning-face:',
      ],
      emojisList: [
        ':dog_star-struck-face:',
        ':dog_weary-face:',
        ':dog_crying-face:',
        ':dog_nauseated-face:',
        ':dog_loudly-crying-face:',
        ':dog_eyerolling-face:',
        ':dog_blushing-face:',
        ':dog_face-w-one-eye-open-blowing-heart:',
        ':dog_face-w-sunglasses:',
        ':dog_stuck-out-tongue-and-winking-eye-face:',
        ':dog_kissing-face-w-closed-eyes:',
        ':dog_heart-eyes-face:',
        ':dog_thinking-face:',
        ':dog_sleeping-face:',
        ':dog_nerd-face:',
        ':dog_grinning-face:',
        ':dog_spy-face:',
        ':dog_face-screaming-in-fear:',
        ':dog_hot-face:',
        ':dog_hugging-face:',
        ':dog_shushing-face:',
        ':dog_face-with-floating-hearts-around-headface:',
        ':dog_pouting-face:',
        ':dog_santa-hat-face:',
        ':dog_crazy-face:',
        ':dog_ninja-face:',
        ':dog_face-w-head-bandage:',
        ':dog_astronaut-face:',
        ':dog_pirate-face:',
        ':dog_peace-sign-face:',
        ':dog_vomiting-face:',
        ':dog_halo-face:',
        ':dog_party-hat-face:',
        ':dog_frowning-face:',
        ':dog_confounded-face:',
        ':dog_face-w-monocle:',
        ':dog_smiling-face-w-open-mouth-closed-eyes:',
        ':dog_grinning-face-w-smiling-eyes:',
        ':dog_tears-of-joy-face:',
        ':dog_face-w-wry-smile:',
      ],
      packLogo: '/assets/store/logo/dog-pack-logo.png',
      packLogoDark: '/assets/store/logo/dog-pack-logo.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-cat',
      productIdBase: 'com.mewe.store.emoji.cat',
      category: 'Emojis',
      name: 'Cat Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':cat_grinning-face:',
      previewEmojisFour: [':cat_heart-eyes-face:', ':cat_nerd-face:', ':cat_grinning-face:', ':cat_hugging-face:'],
      previewEmojisFive: [
        ':cat_nauseated-face:',
        ':cat_smiling-face-w-open-mouth-closed-eyes:',
        ':cat_kissing-face-w-closed-eyes:',
        ':cat_star-struck-face:',
        ':cat_vomiting-face:',
      ],
      emojisList: [
        ':cat_crying-face:',
        ':cat_stuck-out-tongue-and-winking-eye-face:',
        ':cat_loudly-crying-face:',
        ':cat_eyerolling-face:',
        ':cat_party-hat-face:',
        ':cat_peace-sign-face:',
        ':cat_confounded-face:',
        ':cat_kissing-face-w-closed-eyes:',
        ':cat_grinning-face:',
        ':cat_face-w-wry-smile:',
        ':cat_smiling-face-w-open-mouth-closed-eyes:',
        ':cat_spy-face:',
        ':cat_ninja-face:',
        ':cat_astronaut-face:',
        ':cat_face-w-head-bandage:',
        ':cat_face-with-floating-hearts-around-headface:',
        ':cat_crazy-face:',
        ':cat_grinning-face-w-smiling-eyes:',
        ':cat_face-screaming-in-fear:',
        ':cat_hot-face:',
        ':cat_face-with-santa-hat:',
        ':cat_sleeping-face:',
        ':cat_shushing-face:',
        ':cat_nerd-face:',
        ':cat_vomiting-face:',
        ':cat_star-struck-face:',
        ':cat_face-with-sunglasses:',
        ':cat_nauseated-face:',
        ':cat_halo-face:',
        ':cat_face-w-one-eye-open-blowing-heart:',
        ':cat_heart-eyes-face:',
        ':cat_blushing-face:',
        ':cat_hugging-face:',
        ':cat_pouting-face:',
        ':cat_face-with-monocle:',
        ':cat_face-with-pirate-hat:',
        ':cat_frowning-face:',
        ':cat_tears-of-joy-face:',
        ':cat_weary-face:',
        ':cat_thinking-face:',
      ],
      packLogo: '/assets/store/logo/cat-pack-logo.png',
      packLogoDark: '/assets/store/logo/cat-pack-logo-dark.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-panda',
      productIdBase: 'com.mewe.store.emoji.panda',
      category: 'Emojis',
      name: 'Panda Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':panda_blushing:',
      previewEmojisFour: [':panda_ninja:', ':panda_nauseated:', ':panda_pirate:', ':panda_spy:'],
      previewEmojisFive: [
        ':panda_halo:',
        ':panda_party-hat:',
        ':panda_confounded:',
        ':panda_santa-hat:',
        ':panda_nauseated:',
      ],
      emojisList: [
        ':panda_frowning:',
        ':panda_smiling-w-open-mouth-closed-eyes:',
        ':panda_sleeping:',
        ':panda_nerd:',
        ':panda_loudly-crying:',
        ':panda_halo:',
        ':panda_kissing-w-closed-eyes:',
        ':panda_heart-eyes:',
        ':panda_grinning-w-smiling-eyes:',
        ':panda_grinning:',
        ':panda_stuck-out-tongue-and-winking-eye:',
        ':panda_floating-hearts-around-headface:',
        ':panda_sunglasses:',
        ':panda_one-eye-open-blowing-heart:',
        ':panda_crying:',
        ':panda_confounded:',
        ':panda_blushing:',
        ':panda_pouting:',
        ':panda_star-struck:',
        ':panda_peace-sign:',
        ':panda_ninja:',
        ':panda_screaming-in-fear:',
        ':panda_hugging:',
        ':panda_astronaut:',
        ':panda_crazy:',
        ':panda_head-bandage:',
        ':panda_monocle:',
        ':panda_wry-smile:',
        ':panda_eyerolling:',
        ':panda_nauseated:',
        ':panda_hot:',
        ':panda_party-hat:',
        ':panda_pirate:',
        ':panda_santa-hat:',
        ':panda_spy:',
        ':panda_shushing:',
        ':panda_thinking:',
        ':panda_vomiting:',
        ':panda_weary:',
        ':panda_tears-of-joy:',
      ],
      packLogo: '/assets/store/logo/panda-pack-logo.png',
      packLogoDark: '/assets/store/logo/panda-pack-logo.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-peace-sign',
      productIdBase: 'com.mewe.store.emoji.peace-sign',
      category: 'Emojis',
      name: 'Peace Sign Pack',
      description: 'Set of 40 emojis by JoyPixels',
      class: 'store-emoji',
      price: '1.99',
      grantedBy: ['premium'],
      iconEmoji: ':peace-sign_red:',
      previewEmojisFour: [
        ':peace-sign_black:',
        ':peace-sign_rainbow-colors-in-the-middle:',
        ':peace-sign_blue:',
        ':peace-sign_american-flag:',
      ],
      previewEmojisFive: [
        ':peace-sign_french-flag:',
        ':peace-sign_swiss-flag:',
        ':peace-sign_made-of-colorful-hearts:',
        ':peace-sign_emoji:',
        ':peace-sign_world-flag:',
      ],
      emojisList: [
        ':peace-sign_south-korean-flag:',
        ':peace-sign_french-flag:',
        ':peace-sign_irish-flag:',
        ':peace-sign_chinese-flag:',
        ':peace-sign_russian-flag:',
        ':peace-sign_spanish-flag:',
        ':peace-sign_mexican-flag:',
        ':peace-sign_italian-flag:',
        ':peace-sign_denmark-flag:',
        ':peace-sign_indian-flag:',
        ':peace-sign_japanese-flag:',
        ':peace-sign_greek-flag:',
        ':peace-sign_finnish-flag:',
        ':peace-sign_polish-flag:',
        ':peace-sign_norwegian-flag:',
        ':peace-sign_filipino-flag:',
        ':peace-sign_israeli-flag:',
        ':peace-sign_swiss-flag:',
        ':peace-sign_kenyan-flag:',
        ':peace-sign_cuban-flag:',
        ':peace-sign_world-flag:',
        ':peace-sign_red:',
        ':peace-sign_pink:',
        ':peace-sign_orange:',
        ':peace-sign_yellow:',
        ':peace-sign_green:',
        ':peace-sign_blue:',
        ':peace-sign_purple:',
        ':peace-sign_black:',
        ':peace-sign_made-of-colorful-hearts:',
        ':peace-sign_rainbow:',
        ':peace-sign_flower:',
        ':peace-sign_rainbow-colors-in-the-middle:',
        ':peace-sign_made-of-the-word-peace:',
        ':peace-sign_emoji:',
        ':peace-sign_american-flag:',
        ':peace-sign_canadian-flag:',
        ':peace-sign_swedish-flag:',
        ':peace-sign_union-jack-flag:',
        ':peace-sign_australian-flag:',
      ],
      packLogo: '/assets/store/logo/peace-sign-logo.png',
      packLogoDark: '/assets/store/logo/peace-sign-logo-dark.png',
      packLogoWidth: 170,
    },
    {
      itemId: 'emoji-breads',
      productIdBase: 'com.mewe.store.emoji.breads',
      category: 'Emojis',
      name: 'Bread’mojis',
      description: 'Set of 27 emojis by JoyPixels',
      class: 'store-emoji',
      price: '0.99',
      grantedBy: ['premium'],
      iconEmoji: ':breads_happy:',
      previewEmojisFour: [':breads_in_love:', ':breads_sleepy:', ':breads_angel:', ':breads_excited:'],
      previewEmojisFive: [
        ':breads_very_sad:',
        ':breads_confused:',
        ':breads_surprised:',
        ':breads_wow:',
        ':breads_angel:',
      ],
      emojisList: [
        ':breads_angel:',
        ':breads_angry:',
        ':breads_annoyed:',
        ':breads_confused:',
        ':breads_cool:',
        ':breads_devil:',
        ':breads_dizzy:',
        ':breads_excited:',
        ':breads_happy:',
        ':breads_hopeful:',
        ':breads_hungry:',
        ':breads_in_love:',
        ':breads_indifferent:',
        ':breads_nerd:',
        ':breads_rude:',
        ':breads_sad:',
        ':breads_sick:',
        ':breads_silly:',
        ':breads_sleepy:',
        ':breads_surprised:',
        ':breads_thinking:',
        ':breads_unclear:',
        ':breads_very_happy:',
        ':breads_very_sad:',
        ':breads_very_very_sad:',
        ':breads_weird:',
        ':breads_wow:',
      ],
    },
    {
      itemId: 'emoji-planets',
      productIdBase: 'com.mewe.store.emoji.planets',
      category: 'Emojis',
      name: 'Playful Planets',
      description: 'Set of 23 emojis by JoyPixels',
      class: 'store-emoji',
      price: '0.99',
      grantedBy: ['premium'],
      iconEmoji: ':planets_cool:',
      previewEmojisFour: [':planets_cool:', ':planets_angry:', ':planets_in_love:', ':planets_smile:'],
      previewEmojisFive: [
        ':planets_angry:',
        ':planets_dizzy:',
        ':planets_in_love:',
        ':planets_kiss:',
        ':planets_sick:',
      ],
      emojisList: [
        ':planets_angry:',
        ':planets_annoyed:',
        ':planets_confused:',
        ':planets_cool:',
        ':planets_cute:',
        ':planets_disappointed:',
        ':planets_dizzy:',
        ':planets_doubt:',
        ':planets_in_love:',
        ':planets_kiss:',
        ':planets_nerd:',
        ':planets_pain:',
        ':planets_sad:',
        ':planets_sick:',
        ':planets_silly:',
        ':planets_sleep:',
        ':planets_smile:',
        ':planets_smirking:',
        ':planets_surprised:',
        ':planets_tongue_out:',
        ':planets_unamused:',
        ':planets_very_happy:',
        ':planets_winking:',
      ],
    },
    {
      itemId: 'emoji-tacos',
      productIdBase: 'com.mewe.store.emoji.tacos',
      category: 'Emojis',
      name: 'Team Tacos',
      description: 'Set of 23 emojis by JoyPixels',
      class: 'store-emoji',
      price: '0.99',
      grantedBy: ['premium'],
      iconEmoji: ':tacos_tongue_out:',
      previewEmojisFour: [':tacos_inlove:', ':tacos_angel:', ':tacos_thinking:', ':tacos_tongue_out:'],
      previewEmojisFive: [':tacos_surprised:', ':tacos_cool:', ':tacos_inlove:', ':tacos_angel:', ':tacos_devil:'],
      emojisList: [
        ':tacos_angel:',
        ':tacos_angry:',
        ':tacos_confused:',
        ':tacos_cool:',
        ':tacos_crazy:',
        ':tacos_devil:',
        ':tacos_dizzy:',
        ':tacos_happy:',
        ':tacos_indifferent:',
        ':tacos_inlove:',
        ':tacos_kissy:',
        ':tacos_laughing_hard:',
        ':tacos_pain:',
        ':tacos_sad:',
        ':tacos_shock:',
        ':tacos_sick:',
        ':tacos_silly:',
        ':tacos_surprised:',
        ':tacos_thinking:',
        ':tacos_tongue_out:',
        ':tacos_very_happy:',
        ':tacos_very_sad:',
        ':tacos_very_sick:',
      ],
    },
    {
      itemId: 'emoji-bees',
      productIdBase: 'com.mewe.store.emoji.bees',
      category: 'Emojis',
      name: 'Bees',
      description: 'Set of 26 emojis by JoyPixels',
      class: 'store-emoji',
      price: '0.99',
      grantedBy: ['premium'],
      iconEmoji: ':bees_in_love:',
      previewEmojisFour: [':bees_in_love:', ':bees_devil:', ':bees_happy:', ':bees_surprised:'],
      previewEmojisFive: [':bees_angel:', ':bees_dizzy:', ':bees_excited:', ':bees_hungry:', ':bees_nerd:'],
      emojisList: [
        ':bees_angel:',
        ':bees_angry:',
        ':bees_annoyed:',
        ':bees_confused:',
        ':bees_cool:',
        ':bees_devil:',
        ':bees_dizzy:',
        ':bees_excited:',
        ':bees_happy:',
        ':bees_hopeful:',
        ':bees_hungry:',
        ':bees_in_love:',
        ':bees_indifferent:',
        ':bees_nerd:',
        ':bees_rude:',
        ':bees_sad:',
        ':bees_sick:',
        ':bees_silly:',
        ':bees_sleepy:',
        ':bees_surprised:',
        ':bees_thinking:',
        ':bees_unclear:',
        ':bees_very_happy:',
        ':bees_very_sad:',
        ':bees_weird:',
        ':bees_wow:',
      ],
    },
    {
      itemId: 'emoji-blobs',
      productIdBase: 'com.mewe.store.emoji.blobs',
      category: 'Emojis',
      description: 'Set of 15 emojis by JoyPixels',
      name: 'Blobs',
      class: 'store-emoji',
      price: '0.99',
      grantedBy: ['premium'],
      iconEmoji: ':blobs_very_happy:',
      previewEmojisFive: [':blobs_bunny:', ':blobs_cool:', ':blobs_dead:', ':blobs_devil:', ':blobs_happy:'],
      previewEmojisFour: [':blobs_pretty:', ':blobs_very_happy:', ':blobs_whatever:', ':blobs_kissy:'],
      emojisList: [
        ':blobs_bunny:',
        ':blobs_cool:',
        ':blobs_crying:',
        ':blobs_dead:',
        ':blobs_devil:',
        ':blobs_dizzy:',
        ':blobs_excited:',
        ':blobs_grimace:',
        ':blobs_happy:',
        ':blobs_kissy:',
        ':blobs_pretty:',
        ':blobs_shady:',
        ':blobs_very_happy:',
        ':blobs_whatever:',
        ':blobs_wink:',
      ],
    },
  ],
};

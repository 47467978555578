import Component from '@glimmer/component';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';

import PS from 'mewe/utils/pubsub';
import { toTeller, fetchEntriesByTeller } from 'mewe/fetchers/fetch-journals';
import { TellerType } from 'mewe/constants';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';

export default class SidebarTellers extends Component {
  @service dynamicDialogs;
  @service account;
  @service tasks;

  @reads('args.myEntries') myEntries;
  @reads('args.tellers') tellers;

  options = {
    mandatory: ['tellers'],
    optional: ['filter', 'myEntries'],
  };

  constructor() {
    super(...arguments);

    this.wsNewStoryBind = this.wsNewStory.bind(this);
    PS.Sub('new.story', this.wsNewStoryBind);
  }

  willDestroy() {
    PS.Unsub('new.story', this.wsNewStoryBind);
  }

  @computed('myEntries.{items.length,hasNewStories,isFetching}')
  get currentUserHaveNewEntries() {
    if (this.myEntries.isFetching) return false;
    return this.myEntries?.hasNewStories || this.myEntries?.items?.filterBy('isNew', true).length > 0;
  }

  @computed('tellers.items.length', 'args.filter')
  get tellersFiltered() {
    return this.getTellers();
  }

  getTellers(withCurrentUser) {
    let tellers = this.tellers.items;

    if (withCurrentUser) {
      tellers.unshift(this.account.activeUser);
    }

    if (this.args.filter) {
      return tellers.filterBy('tellerType', this.args.filter);
    } else {
      return tellers;
    }
  }

  wsNewStory(data) {
    if (data.storytellerId === this.account.activeUser.id) {
      fetchEntriesByTeller({ id: this.account.activeUser.id });
      set(this, 'myEntries.hasNewStories', true);
      return;
    }

    const tellers = ds.tellers.items;
    const existingTeller = tellers.find((t) => t.id === data.storytellerId);

    if (existingTeller) {
      existingTeller.setProperties({
        hasNewStories: true,
        stories: A(),
      });
    } else {
      data.hasNewStories = true;
      tellers.unshiftObject(toTeller(data));
    }
  }

  @action
  updateMyEntries(entries) {
    this.myEntries.items = entries;
  }

  @action
  openCurrentUser() {
    const currentUser = this.account.activeUser;

    const params = {
      tellerId: currentUser.id,
      tellerName: currentUser.name,
      tellerType: TellerType.USER, // for now only User type exist
      avatarHref: currentUser._links.avatar.href,
      tellers: this.getTellers(true),
      preloadedEntries: this.myEntries.items,
      onDeleteEntry: this.updateMyEntries,
      allEntriesSeen: () => {
        set(this, 'myEntries.hasNewStories', false);
      },
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  open(teller) {
    const params = {
      tellerId: teller.id,
      tellerName: teller.page ? teller.page.name : teller.details.name,
      tellerType: teller.page ? TellerType.PAGE : TellerType.USER,
      avatarHref: teller.page
        ? teller.page.avatar
        : teller.details._links.avatar.href,
      tellers: this.getTellers(),
      pageUrlId: teller.page ? teller.page.publicLinkId : null,
      preloadedEntries: teller.stories,
      context: this.filter === 'Page' ? 'pages' : 'home',
      allEntriesSeen: (id) => {
        let found = this.tellers.items.find((el) => el.id === id);
        if (found) found.set('hasNewStories', false);
      },
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  selectPhoto() {
    const choosePhotoArgs = {
      url: '/api/v2/photo/st',
      task: this.tasks.getTask('photo.story'),
      useNewCropper: true,
    };
    this.dynamicDialogs.openDialog('choose-photo', choosePhotoArgs);
  }
}

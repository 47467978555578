/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

import { factory as popupFactory } from 'mewe/utils/popup-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import GroupStore from 'mewe/stores/group-store';
import { Theme, FeedTypes } from 'mewe/constants';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import dispatcher from 'mewe/dispatcher';

export default class MwAuthor extends Component {
  @service router;
  @service dynamicDialogs;
  @service dynamicPopups;
  @service account;

  @reads('args.post') post;
  @reads('args.owner') owner;
  @reads('args.group') group;
  @reads('args.event') event;
  @reads('args.isChatPost') isChatPost;
  @reads('args.isComment') isComment;
  @reads('args.feedTheme') feedTheme;
  @reads('args.inMediaDialog') inMediaDialog;
  @reads('args.page') page;
  @reads('args.postedByPage') postedByPage;
  @reads('args.scope') scope;
  @reads('args.postInPostbox') postInPostbox;
  @reads('args.isSharedPost') isSharedPost;

  imageSize = '150x150';

  constructor() {
    super(...arguments);
    this.globals = getOwner(this).lookup('globals:main');
  }

  @computed('group.id')
  get fullGroup() {
    return GroupStore.getState().groups.find((group) => group.id === this.group?.id);
  }

  @computed('owner.id', 'fullGroup.{ownerId,adminIds}', 'post.{event2,eventInfo}')
  get ownerRole() {
    if (this.args.skipRoles) return;

    if (this.post.event2 && !this.post.eventInfo) {
      if (this.post.event2.ownerId === this.owner?.id) return __('Owner');
    } else {
      if (this.group?.id === 'contacts' || this.group?.id === 'mycloud' || this.group?.id === 'privacymail') {
        return;
      }

      if (!this.fullGroup) return;

      if (this.fullGroup.ownerId === this.owner?.id) {
        return __('Owner');
      } else if (this.fullGroup.adminIds && this.fullGroup.adminIds.includes(this.owner?.id)) {
        return __('Admin');
      }
    }
  }

  @computed('isComment', 'post.threadId')
  get isPrivatePost() {
    return !this.isComment && this.post.threadId;
  }

  @computed('isPrivatePost', 'isChatPost')
  get showPostReceivers() {
    return this.isPrivatePost && !this.isChatPost;
  }

  @computed('postedByPage', 'post.{page.isFollower,discoverScope}', '{isComment,isSharedPost,inMediaDialog}')
  get showFollowButton() {
    if (this.isComment) return false;
    if (this.isSharedPost) return false;
    if (this.inMediaDialog) return false;

    return this.postedByPage && this.post.discoverScope && !this.post.page?.isFollower;
  }

  @computed('post.event2.name', 'group.name', 'post.group.name', 'feedTheme')
  get groupName() {
    // don't show event name in groups feed but group name
    if (this.post?.event2.name && this.feedTheme !== Theme.GROUPS) return this.post.event2.name;
    else if (this.group.name) return this.group.name;
    else if (this.post?.group.name) return this.post.group.name;
    else if (this.post.group.id === 'contacts') return __('Home');
  }

  @computed('owner.name', 'postedByPage', 'post.page.name')
  get ownerName() {
    if (this.postedByPage) return this.post.page?.name;
    return this.owner?.name;
  }

  @computed('post.{featured,groupId,isPublicContent}', 'isComment', 'feedTheme')
  get showFeaturedText() {
    if (this.post.isPublicContent) return false;

    const isAllGroupsOrPages = this.feedTheme === Theme.GROUPS || this.feedTheme === Theme.PAGES;
    const isAllFeed = this.feedTheme === FeedTypes.ALL;
    const isHomeFeed = this.feedTheme === Theme.CONTACTS;
    const isGroupPostOutsideGroupFeed =
      this.feedTheme !== Theme.GROUP && this.post.groupId && this.feedTheme !== Theme.EVENT; // group event is fine

    return (
      this.post.featured &&
      !this.post.discoverScope &&
      !this.isComment &&
      !isAllGroupsOrPages &&
      !isHomeFeed &&
      !isAllFeed &&
      !isGroupPostOutsideGroupFeed
    );
  }

  @computed('group.id')
  get isGroupAuthor() {
    const groupId = this.group.id;
    return groupId && groupId !== Theme.CONTACTS;
  }

  @computed('isComment', 'post.refPost', 'post.refRemoved')
  get showReferenceLink() {
    return !this.isComment && (this.post.refPost || this.post.refRemoved);
  }

  @computed('owner', 'group', 'imageSize')
  get avatar() {
    var avatar = this.owner._links.avatar.href;

    if (avatar) {
      avatar = avatar.replace('{imageSize}', this.imageSize);
    } else {
      if (!this.owner.id) {
        return '';
      }

      avatar = `/api/v2/photo/profile/${this.imageSize}/${this.owner.id}?`;

      if (this.group.id) avatar += `group=${this.group.id}&`;

      avatar += `f=${this.owner.fprint || ''}`;
    }

    // I want to have all my avatars set to one fprint (MW)
    if (this.owner.id == this.account.activeUser.id) {
      avatar = avatar.substr(0, avatar.indexOf('f=') + 2) + this.account.activeUser.fprint;
    }

    // avatar link can have different group or no group
    avatar = avatar.replace(new RegExp('\\b(group=).*?(&|$)'), '$1' + (this.group.id || '') + '$2');

    if (avatar[0] === '/') avatar = EnvironmentUtils.getImgHost(true) + avatar;

    return avatar;
  }

  @action
  showProfilePopup(target) {
    if (this.owner?.id === this.account.activeUser.id) return;
    if (this.postedByPage) return;
    if (!this.account?.activeUser.id) return;

    if (!this.isDestroyed && !this.isDestroying && !this.args?.post?.isPublicContent) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: target,
        owner: this.owner,
        group: this.group,
        event: this.post.event2,
        avatar: this.avatar,
      });
    }
  }

  @action
  followPage() {
    dispatcher.dispatch('pages', 'toggleFollow', this.post.page, true, true);
  }

  @action
  openProfile() {
    const ownerId = this.owner?.id || this.owner?.userId;
    const isGroup = !!this.post?.groupId;
    const isEvent = !!this.post?.eventId;

    if (ownerId) {
      if (isEvent) {
        return this.router.transitionTo('app.event.attendees.profile', this.post.eventId, ownerId);
      } else if (isGroup) {
        if (!this.post.group?.isMember) {
          return showGroupPreviewDialog(this.dynamicDialogs, this.post.group, { isGroupPreview: true });
        }

        return this.router.transitionTo('app.group.members.profile', this.post?.groupId, ownerId);
      }
    }

    return false;
  }
}
